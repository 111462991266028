export class ProviderInfoSummary{
    public Selected: boolean = false;
    public ProviderId: string;
    public ProviderName: string;
    public FirstName: string;
    public LastName: string;
    public License: string;
    public PrimarySpecialty: string;
    public SecondarySpecialty: string;
    public PrimaryCertification: string;
    public SecondaryCertification: string;
    public Address1: string;
    public Address2: string;
    public City: string;
    public State: string;
    public ZipCode: string;
    public Phone: string;
    public Fax: string;
    public Email: string;
    public TaxId: string;
    public DistanceInMiles: number;
    public IsPCP_FQHC: boolean;
}