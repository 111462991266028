import { Injectable, Inject } from '@angular/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';

import { Configuration } from '../../../app.constants';
import { OAuthService } from 'angular-oauth2-oidc';

import { MemberProfileSitecoreTemplate } from '../member-profile-sitecore-template';
import { MemberProfile } from '../member-profile';
import { GeneralInquirySitecoreTemplate } from '../general-inquiry-sitecore-template';
import { GeneralInquiry } from '../general-inquiry';
import { CustomerServiceResponse } from '../customer-service-response';
import { HttpResponseHelper } from '../../../core/shared/helpers/http-response-helper';
import {publishReplay, refCount,catchError} from 'rxjs/operators'
import { LanguageService } from '../../../core/shared/services/language.service';

@Injectable()
export class GeneralInquiryService {
    private generalInquiryServiceUrl;

    constructor(private http: HttpClient, private configuration: Configuration, private languageService: LanguageService) {
        this.generalInquiryServiceUrl = configuration.GeneralInquiryServiceUrl;
    }

    submitGeneralInquiry(generalInquiry: GeneralInquiry): Observable<CustomerServiceResponse> {
        return this.http.post<CustomerServiceResponse>(this.generalInquiryServiceUrl, generalInquiry)
            .pipe(catchError(error => HttpResponseHelper.handleError(this.configuration, error)));
    }

    getGeneralInquirySitecoreContent(): Observable<GeneralInquirySitecoreTemplate> {
        return this.http.get<GeneralInquirySitecoreTemplate>(this.languageService.getContentServiceUrlWithParameter(this.configuration.SitecoreServiceGeneralInquiryParameter))
            .pipe(publishReplay(1)
            ,refCount()
            ,catchError(error => HttpResponseHelper.handleError(this.configuration, error)));
    }
}



