/*
** Member Portal module exposing all member portal views, services, and utilities
*/

import { NgModule } from '@angular/core';

import { MemberPortalSharedModule } from './shared/member-portal-shared.module';
import { routedComponents, MemberPortalRoutingModule } from './member-portal-routing';
import { CoreModule } from '../core/core.module';
import { InputSwitchModule } from 'primeng/inputswitch';
import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';
import { DataViewModule} from 'primeng/dataview';

import { ProviderInfoFilterPipe } from './shared/pipes/provider-info-filter.pipe';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';

@NgModule({
    imports: [
        MemberPortalRoutingModule,
        MemberPortalSharedModule,
        CoreModule,
        InputSwitchModule,
        TableModule,
        PaginatorModule,
        DataViewModule,
        DialogModule,
        TableModule,
        ConfirmDialogModule
    ],

    declarations: [routedComponents, ProviderInfoFilterPipe]
})

export class MemberPortalModule { }
