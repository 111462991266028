import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import { DatePipe } from '@angular/common';


// CORE
import { PCPInfoComponent } from '../../core/shared/components/pcp-info/pcp-info.component';
import { PagerService } from '../../core/shared/services/pager.service';
import { ProviderLinkService } from '../../core/shared/services/provider-link.service';
import { MemberLobProviderLinksModel } from '../../core/shared/member-lob-provider-sitecore-template'
import { PhonePipe } from '../../core/shared/pipes/phone.pipe';
import { PCPInfoViewProperties } from '../../core/shared/pcp-info-view-properties';
import { CommonService } from '../../core/shared/services/common.service';

// Member Portal
import { Configuration } from '../../app.constants';
import { MemberProfile } from '../shared/member-profile';
import { MemberProfileService } from '../shared/services/member-profile.service';
import { MemberProfileSitecoreTemplate } from '../shared/member-profile-sitecore-template';
import { SearchForProviderComponent } from '../search-for-provider/search-for-provider.component';
import { ProviderSearchService } from '../shared/services/provider-search.service';
import { ProviderInfoSummary } from '../shared/provider-info-summary';
import { ProviderSearchParameter } from '../shared/provider-search-parameter';
import { ChangePCPService } from '../shared/services/change-pcp.service';
import { ChangePCPSitecoreTemplate } from '../shared/change-pcp-sitecore-template';
import { ProviderSearchResultsComponent } from '../provider-search-results/provider-search-results.component';
import shajs from 'sha.js';
import { HealthNetworkPlan } from '../shared/health-network-plan';
import { LanguageService, INotifyChange } from '../../core/shared/services/language.service';
import { ChangePCPChangeHealthNetworkSitecoreTemplate } from '../shared/change-pcp-change-health-network-sitecore-template';

@Component({
    selector: 'app-change-pcp',
    templateUrl: './change-pcp.component.html',
    providers: [ProviderSearchService, Configuration, ChangePCPService, ProviderLinkService, DatePipe, PagerService, CommonService],
})

export class ChangePCPComponent implements OnInit, OnChanges, OnDestroy {

    @Output() changePCPOptionChanged = new EventEmitter();
    @Output() searchForAProvider: EventEmitter<any> = new EventEmitter();
    @Output() providerSearchResults = new EventEmitter();
    @Output() cancel: EventEmitter<any> = new EventEmitter();
    @Output() selectedProvider = new EventEmitter();

    @Input() isKaiser: boolean;
    @Input() showSearch: boolean = false;
    @Input() showSearchResults: boolean = false;
    @Input() isCurrentPCPInNetwork: boolean = true;
    @Input() canChangePCP: boolean = false;
    @Input() collapseSearchPanel: boolean = true;
    @Input() showCollapsibleButton: boolean;
    @Input() showDropDown: boolean = false;
    @Input() isAllowedToChangePCPWithoutHN: boolean = false;
    @Input() loading: boolean = false;

    @Input() currentHealthNetwork = new HealthNetworkPlan();
    @Input() providerUrl: MemberLobProviderLinksModel = new MemberLobProviderLinksModel();
    @Input() providerFilter = new ProviderSearchParameter();
    @Input() providerList: ProviderInfoSummary[];
    @Input() memberProfile = new MemberProfile();

    @Input() healthNework: string;
    @Input() selectedOption: string;
    @Input() isReadOnly: boolean = false;
    @Input() showEffectiveDate: Date;
    @Input() selectedPCPOption: string;
    @Input() isKaiserSelected: boolean;
    @Input() changePCPChangeHealthNetworkSitecoreTemplate: ChangePCPChangeHealthNetworkSitecoreTemplate;

    memberProfileContent = new MemberProfileSitecoreTemplate();
    changePCPSitecoreTemplate = new ChangePCPSitecoreTemplate();

    searchForProviderComponent: SearchForProviderComponent;
    providerSearchResultsComponent: ProviderSearchResultsComponent;

    public pcpInfo: PCPInfoComponent = new PCPInfoComponent(this.configuration, this.commonService);

    public provider: ProviderInfoSummary = new ProviderInfoSummary();

    public message: string;
    public fontAwesomeArrow: string;
    public providerLink: string;
    public show: boolean = false;
    public requiredToSelectPCP: boolean = false;

    private hashedDate: string;
    private today = new Date();
    private stringToHash = this.datepipe.transform(this.today, this.configuration.DateHashFormat);
    private subscription: Subscription;

    constructor(
        public commonService: CommonService,
        private memberProfileService: MemberProfileService,
        public configuration: Configuration,
        private providerSearchService: ProviderSearchService,
        private changePCPService: ChangePCPService,
        private providerLinkService: ProviderLinkService,
        private datepipe: DatePipe,
        private languageService: LanguageService
    ) { }

    ngOnChanges(changes: SimpleChanges) {
    }
    ngOnInit() {
        try {
          this.subscription = this.languageService.notifyObservable$.subscribe((change: INotifyChange) => {
            if(change.propName === 'languageCode'){
              this.onLoad();
            }
          });
        }
        catch (ex) {
          throw ex;
        }
      }
    
      ngOnDestroy() {
        this.subscription.unsubscribe();
      }
    
      onLoad() {
        try {
            this.getChangePCPSitecoreContent();
            this.getMemberProfileContent();
            this.isCurrentPCPInNetwork = false;
            this.currentHealthNetwork = this.memberProfile.HealthNetworkPlan;
            this.searchForProviderComponent
                = new SearchForProviderComponent(this.providerSearchService, this.memberProfileService, this.configuration, this.languageService)
            this.fontAwesomeArrow = this.configuration.FontAwesomeDoubleDownAngle;
        } catch (ex) {
            this.message = ex;
            throw ex;
        }
    }

    setSelectedOption() {
        this.isCurrentPCPInNetwork = true;
        if (this.memberProfile.LOB.LOBCode === this.configuration.MediCalLOBCode &&
            !this.isAllowedToChangePCPWithoutHN) {
            this.selectedOption = this.configuration.KeepCurrentPCPOption;
        }
        else {
            this.selectedOption = this.configuration.ChangePCPOption;
        }
    }

    private getMemberProfileContent() {
        this.memberProfileService.getMemberProfileContent()
            .subscribe(
            response => {
                this.memberProfileContent = response;
                this.setPCPInfoView();
                this.setPCPInfoData();
            });
    }

    private getChangePCPSitecoreContent() {
        this.changePCPService.getChangePcpSitecoreContent()
            .subscribe(response => {
                this.changePCPSitecoreTemplate = response;
            },
            error => {
                this.message = <any>error;
            });
    }

    public changeView(newValue) {
        this.expandSearchPanel();
        this.changePCPOptionChanged.emit(newValue);
    }

    setPCPInfoData() {
        if (!this.memberProfile || !this.memberProfile.PrimaryCareProviderInfo) return;
        
        const phonePipe = new PhonePipe();
        const phoneValue = phonePipe.transform(this.memberProfile.PrimaryCareProviderInfo.PhoneNumber, "");
        this.pcpInfo.provider.LOBCode = this.memberProfile.LOB.LOBCode;

        this.pcpInfo.provider.ProviderId = this.memberProfile.PrimaryCareProviderInfo.ProviderId;
        this.pcpInfo.provider.ProviderName = this.memberProfile.PrimaryCareProviderInfo.ProviderName;
        this.pcpInfo.provider.PhoneNumber = phoneValue;
        if (this.memberProfile.PrimaryCareProviderInfo.Address != null) {
            this.pcpInfo.provider.AddressInfo.Address1 = this.memberProfile.PrimaryCareProviderInfo.Address.Address1;
            this.pcpInfo.provider.AddressInfo.Address2 = this.memberProfile.PrimaryCareProviderInfo.Address.Address2;
            this.pcpInfo.provider.AddressInfo.City = this.memberProfile.PrimaryCareProviderInfo.Address.City;
            this.pcpInfo.provider.AddressInfo.State = this.memberProfile.PrimaryCareProviderInfo.Address.State;
            this.pcpInfo.provider.AddressInfo.ZipCode = this.memberProfile.PrimaryCareProviderInfo.Address.ZipCode;
            this.pcpInfo.properties.ShowHorizontalLine = false;
        }
    }

    setPCPInfoView() {
        this.pcpInfo.properties = new PCPInfoViewProperties();
        this.pcpInfo.properties.AddressLine1LabelText = this.memberProfileContent.PCPAddress1Label;
        this.pcpInfo.properties.AddressLine2LabelText = this.memberProfileContent.PCPAddress2Label;
        this.pcpInfo.properties.CityLabelText = this.memberProfileContent.PCPAddressCityLabel;
        this.pcpInfo.properties.StateLabelText = this.memberProfileContent.PCPAddressStateLabel;
        this.pcpInfo.properties.ZipCodeLabelText = this.memberProfileContent.PCPAddressZipCodeLabel;
        this.pcpInfo.properties.PhoneNumber = this.memberProfileContent.PCPPhoneLabel;
        this.pcpInfo.properties.PCPNameLabelText = this.memberProfileContent.PCPNameLabel;
        this.pcpInfo.properties.HeaderText = '';
        this.pcpInfo.properties.ShowProviderId = true;
        this.pcpInfo.properties.PCPProviderIdLabelText = this.memberProfileContent.PCPProviderIdLabel;
    }

    onCancel() {
        this.selectedOption = '-1';
        this.cancel.emit(null);
    }

    searchForAProviderEvent(providerFilter) {
        this.providerFilter = providerFilter;
        this.searchForAProvider.emit(this.providerFilter);
    }

    expandSearchPanel() {
        if (this.collapseSearchPanel) {
            this.fontAwesomeArrow = this.configuration.FontAwesomeDoubleDownAngle;
        } else {
            this.fontAwesomeArrow = this.configuration.FontAwesomeDoubleRightAngle;
        }
    }

    openProviderDetails(event:any) {
        this.hashedDate = shajs(this.configuration.ShaVersion).update(this.stringToHash.toString()).digest(this.configuration.DigestString);
        this.providerLinkService.getMemberLobProviderLinks(this.memberProfile.LOB.LOBCode)
            .subscribe(result => {
                this.providerUrl = result;
                this.providerLink = this.providerLinkService.getProviderDetailsUrl(
                    this.providerUrl.ProviderDetailSitecoreLink,
                    this.provider.ProviderId,
                    this.hashedDate);
                window.open(this.providerLink, '_blank');

            });
    }

    onSelectedProviderChange(provider) {
        this.provider = provider;
        this.selectedProvider.emit(provider);
    }
}


