import { Component, OnInit, OnDestroy } from '@angular/core';
import { InterpretiveServiceSitecoreTemplate } from '../shared/interpretive-service-sitecore-template';
import { combineLatest, Subscription } from 'rxjs';
import { LanguageService, INotifyChange } from '../../core/shared/services/language.service';
import { MemberLanguages } from '../shared/member-languages';
import { MemberProfileService } from '../shared/services/member-profile.service';
import { MemberProfile } from '../shared/member-profile';
import { Appointment } from '../shared/appointment';
import { InterpretiveServiceService } from '../shared/services/interpretive-service.service';
import { InterpretiveService } from '../shared/interpretive-service';
import { CustomerServiceResponse } from '../shared/customer-service-response';
import { Configuration } from '../../app.constants';
import { PhonePipe } from '../../core/shared/pipes/phone.pipe';
import { DatePickerProperties } from '../../core/shared/date-picker-properties';
import { ConfirmationService } from 'primeng/api';
import { SubSink } from 'subsink';


@Component({
  selector: 'app-interpretive-service',
  providers: [InterpretiveServiceService, ConfirmationService],
  templateUrl: './interpretive-service.component.html',
  styleUrls: ['./interpretive-service.component.scss']
})
export class InterpretiveServiceComponent implements OnInit, OnDestroy {
  successMessage: string;
  errorMessage: string;
  initialPhoneNumber: string;
  sitecoreTemplate = new InterpretiveServiceSitecoreTemplate();
  memberProfile: MemberProfile = new MemberProfile();
  contactPhoneNumber: string = "";
  in_LTC_Hospital: boolean = false;
  genderRequested: string;
  preferredSpokenLanguage = new MemberLanguages();
  memberLanguagesList: Array<MemberLanguages>;
  alternateMemberLanguagesList: Array<MemberLanguages>;
  appointments: Array<Appointment> = new Array<Appointment>();
  hours: Array<string> = new Array<string>();
  phonePipe = new PhonePipe();

  private subscriptions = new SubSink();
  isValidating:boolean = false;
  private customerServiceResponse: CustomerServiceResponse = new CustomerServiceResponse();
  datePickerProperties = new DatePickerProperties();
  constructor(
    private confirmationService: ConfirmationService, 
    private memberProfileService: MemberProfileService,
    public config: Configuration,
    private interpretiveServiceService: InterpretiveServiceService,
    private languageService: LanguageService) {  }

  ngOnInit() {
    try {

      this.populateCollections();
      this.getMemberLanguages();
      
      this.subscriptions.sink = combineLatest([
        this.languageService.notifyObservable$,
        this.memberProfileService.notifyObservable$
      ]).subscribe(result => {
          this.memberProfile = this.memberProfileService.memberProfile;
          if (this.memberProfile) this.onLoad();
        });

    } catch (ex) {
      throw ex;
    }
  }

  populateCollections(){   

    // Hours collection
    for(let i = 1; i<=12; i++){
      this.hours.push(i.toString());
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onLoad() {
    this.onReset(null);
    this.loadSitecoreContent();
    window.scrollTo(0, 0);
  }
  private loadSitecoreContent() {
    this.interpretiveServiceService.getInterpretiveServiceSitecoreContent().subscribe(
      response => {
        this.sitecoreTemplate = response;
        this.datePickerProperties = {
          DateLabel : response.AppointmentDateLabel,
          InvalidDateError : response.AppointmentDateError,
          DateRangeError: response.AppointmentDateRangeError
        };
      },
      error => {
        this.errorMessage = <any>error;
      }
    );
  }
  
  public onMemberPhoneChange($event): void {
    this.contactPhoneNumber = $event.phoneValue;
  }
  compareLanguage(l1: MemberLanguages, l2: MemberLanguages) {
    return l1.Code === l2.Code;
  }

  getMemberLanguages() {
    this.memberProfileService.getMemberLanguages()
        .subscribe(
        response => {
            this.memberLanguagesList = response.filter(l => l.IsAlternateLanguage === false);

            this.alternateMemberLanguagesList = response.filter(l => l.IsAlternateLanguage === true);
        },
        error => {
            this.showErrorMessage(error.message);
        });
  }

  showErrorMessage(errorMessage: string) {
    this.errorMessage = errorMessage;
  }
  validadteAppointment(i: number)
  {
    return i < 1;
  }
  btnAddAppointment_Click(form){
    this.isValidating = true;
     if (form.invalid) {
        return;
     }
     this.isValidating = false;
     this.addAppointment();
  }
  addAppointment()
  {
    let apt = new Appointment();
    apt.Id = this.randomName();
    apt.Type = "";
    let aptDate = new Date();
    aptDate.setDate( aptDate.getDate() + 10 );
    
    apt.ScheduledDate = aptDate;

    apt.Hour = "12";
    apt.Minute = "00";
    apt.AMPM = "PM";

    apt.Duration_hh = "01";
    apt.Duration_mm = "00";
    this.appointments.push(apt);
  }
  confirmAppointmentCancel(index:number) {
    this.confirmationService.confirm({
        message: this.sitecoreTemplate.ConfirmAppointmentCancelMessage || 'are you sure that you want to proceed?',
        header: this.sitecoreTemplate.ConfirmAppointmentCancelHeader || 'confirmation',
        // icon: 'pi pi-exclamation-triangle',
        // acceptLabel: 'yes',
        // rejectLabel: 'no',
        accept: () => {
          this.appointments.splice(index,1);
        },
        reject: () => { }
    });
  }

  randomName() {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  
    for (var i = 0; i < 10; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
  
    return text;
  }
  getNotes()
  {
    let notes = "";
    notes += `Member's contact phone:  ${this.contactPhoneNumber}\n`;
    notes += `Interpreter needed for this language:  ${this.preferredSpokenLanguage.Description}\n`;
    notes += `Is the member in a LTC or hospital?  ${this.in_LTC_Hospital?'Yes':'No'}\n`;
    notes += `Was a specific gender requested?  ${this.genderRequested}\n`;
    
    for (let i = 0; i < this.appointments.length; i++)
    {
      notes += `\n**** Appointment #${i+1}\n`;
      notes += `- Type:  ${this.appointments[i].Type}\n`;
      notes += `- Date (mm/dd/yyyy): ${this.appointments[i].ScheduledDate.toLocaleDateString()}\n`;
      notes += `- Time:  ${this.appointments[i].Hour}:${this.appointments[i].Minute} ${this.appointments[i].AMPM}\n`;
      notes += `- Duration (hh:mm):  ${this.appointments[i].Duration_hh}:${this.appointments[i].Duration_mm}\n`;

      notes += `\n- Provider Name:  ${this.appointments[i].ProviderName}\n`;
      notes += `- Address of event:  ${this.appointments[i].EventAddress}\n`;
      notes += `- Event City, State:  ${this.appointments[i].EventCity}, CA\n`;
      notes += `- Event Zip Code:  ${this.appointments[i].EventZipCode}\n`;
      notes += `- Office or event phone number:  ${this.appointments[i].EventPhoneNumber}\n`;
      notes += `- Contact person at office or event:  ${this.appointments[i].ContactPerson}\n`;
    }
    
    return notes;
  }

  getIsUrgentPriority()
  {
    let urgentDate = new Date();
    urgentDate.setHours(0,0,0,0);
    urgentDate.setDate( urgentDate.getDate() + 9 );

    for (let i = 0; i < this.appointments.length; i++)
    {

      if (this.appointments[i].ScheduledDate <= urgentDate) return true;
    }
    return false;
  }

  onSubmit(form){
    this.isValidating = true;
     if (form.invalid) {
        return;
     }
     this.isValidating = false;

    let request = new InterpretiveService();
    request.IsUrgentPriority = this.getIsUrgentPriority();
    request.LanguageRequested = this.preferredSpokenLanguage;
    request.Notes = this.getNotes();

    // Call data service to submit inquiry
    this.interpretiveServiceService
    .submitInterpretiveService(request)
    .subscribe(
      response => {
        this.customerServiceResponse = response;
        let successMessage = "your schedule request has been received and is being reviewed.";
        if(this.sitecoreTemplate.SuccessMessage)
        {        
          successMessage = this.sitecoreTemplate.SuccessMessage.replace(
            this.config.FXIRequestIDToken, this.customerServiceResponse.RequestId);
        }
        this.successMessage = successMessage;
        this.onReset(form);
      },
      error => {
        this.errorMessage = "Error";
      }
    );

    window.scrollTo(0, 0);

  }

  onReset(form) {
    this.isValidating = false;

    this.initialPhoneNumber = this.phonePipe.transform(
      this.memberProfile.HomePhoneNumber,
      ''
    );
    this.contactPhoneNumber = this.initialPhoneNumber;

    if(!this.memberProfile && !this.memberProfile.PreferredFormatLanguage && !this.memberProfile.PreferredSpokenLanguage.Code)
    {
      this.preferredSpokenLanguage = this.memberProfile.PreferredSpokenLanguage;
    }
    else{
      this.preferredSpokenLanguage = {
        Code:"EN",
        Description:"English",
        IsAlternateLanguage: false
      };
    }

    this.genderRequested = "No Preference";
    this.in_LTC_Hospital = false;
    this.appointments= new Array<Appointment>();
    this.addAppointment();

    if(form) form.form.markAsPristine();
  }

  confirmFormReset(form){
    this.confirmationService.confirm({
      message: this.sitecoreTemplate.ConfirmFormResetMessage || 'are you sure that you want to proceed?',
      header: this.sitecoreTemplate.ConfirmFormResetHeader || 'confirmation',
      // icon: 'pi pi-exclamation-triangle',
      // acceptLabel: 'yes',
      // rejectLabel: 'no',
      accept: () => {
          this.onReset(form);
      },
      reject: () => { }
  });
  }

}
