import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

// CORE
import { HttpResponseHelper } from '../../../core/shared/helpers/http-response-helper';

// Member Portal
import { Configuration } from '../../../app.constants';
import { ChangePcpRequestSitecoreTemplate } from '../../shared/change-pcp-request-sitecore-template';
import { ChangePcpConfirmationSitecoreTemplate } from '../../shared/change-pcp-confirmation-sitecore-template';
import { ChangePCPSitecoreTemplate } from '../../shared/change-pcp-sitecore-template';
import {publishReplay, refCount, catchError} from 'rxjs/operators';
import { LanguageService } from '../../../core/shared/services/language.service';

@Injectable()
export class ChangePCPService {

  constructor(private http: HttpClient, private configuration: Configuration, private languageService: LanguageService) {
  }

  getChangePcpRequestContent(): Observable<ChangePcpRequestSitecoreTemplate> {
    try {
      return this.http
        .get<ChangePcpRequestSitecoreTemplate>(
          this.languageService.getContentServiceUrlWithParameter(this.configuration.ChangePcpRequestSitecoreServiceParameter)
      ).pipe(
            publishReplay(1)
          , refCount()
          , catchError(error =>
          HttpResponseHelper.handleError(this.configuration, error)
        ));
    } catch (ex) {
      // log error
      return null;
    }
  }

  getChangePcpConfirmationContent(): Observable<
    ChangePcpConfirmationSitecoreTemplate
  > {
    return this.http
      .get<ChangePcpConfirmationSitecoreTemplate>(
        this.languageService.getContentServiceUrlWithParameter(this.configuration.ChangePcpConfirmationSitecoreServiceParameter)
      ).pipe(
      publishReplay(1)
      ,refCount()
      ,catchError(error =>
        HttpResponseHelper.handleError(this.configuration, error)
      ));
  }

  getChangePcpSitecoreContent(): Observable<ChangePCPSitecoreTemplate> {
    return this.http
        .get<ChangePCPSitecoreTemplate>(this.languageService.getContentServiceUrlWithParameter(this.configuration.SitecoreServiceChangePCPParameter))
        .pipe(
      publishReplay(1)
      ,refCount()
      ,catchError(error =>
        HttpResponseHelper.handleError(this.configuration, error)
      ));
  }
}
