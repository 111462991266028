
export class RequestIDCard {

    public IfTemporaryAddress: boolean
    public MemberAddressLine1: string;
    public MemberAddressLine2: string;
    public MemberCity: string;
    public MemberState: string;
    public MemberZipCode: string;
    public PrintAlternateFormat: boolean
    public AlternateFormat: string;
    public MemberCareOf: string;
}