import { Component, OnInit, OnDestroy } from '@angular/core';

import { ProviderSearchSitecoreTemplate } from '../shared/provider-search-sitecore-template';
import { ProviderSearchService } from '../shared/services/provider-search.service';
import {finalize} from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { INotifyChange, LanguageService } from '../../core/shared/services/language.service';


@Component({
    selector: 'app-provider-search',
    templateUrl: './provider-search.component.html',
    styleUrls: ['./provider-search.component.scss'],
    providers: [ProviderSearchService]
})
export class ProviderSearchComponent implements OnInit, OnDestroy {
    url: string;
    providerSearch: ProviderSearchSitecoreTemplate;
    isBusy: boolean = true;
    private subscription: Subscription;

    constructor(private providerSearchService: ProviderSearchService, private languageService: LanguageService) { }

    ngOnInit() {
        try {
            this.subscription = this.languageService.notifyObservable$.subscribe((change: INotifyChange) => {
            if(change.propName === 'languageCode'){
              this.onLoad();
            }
          });
        }
        catch (ex) {
          throw ex;
        }
      }
    
      ngOnDestroy() {
        this.subscription.unsubscribe();
      }
    
      onLoad() {
        try {
            this.providerSearchService.getSitecoreContent()
                .pipe(
                finalize(() => this.isBusy = false))
                .subscribe(
                 response => {
                    this.providerSearch = response;
                    this.url = this.providerSearch.ProviderSearchUrl;
                },
                error => console.log(error));
        }
        catch (ex) {
            // log error
            return null;
        }

    }

}
