import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Configuration } from '../../../app.constants';
import { CustomerServiceResponse } from '../customer-service-response';
import { HttpResponseHelper } from '../../../core/shared/helpers/http-response-helper';
import {publishReplay, refCount,catchError} from 'rxjs/operators'
import { LanguageService } from '../../../core/shared/services/language.service';
import { InterpretiveServiceSitecoreTemplate } from '../interpretive-service-sitecore-template';
import { InterpretiveService } from '../interpretive-service';

@Injectable()
export class InterpretiveServiceService {
    private InterpretiveServiceUrl;

    constructor(private http: HttpClient, private configuration: Configuration, private languageService: LanguageService) {
        this.InterpretiveServiceUrl = configuration.InterpretiveServiceUrl;
    }

    submitInterpretiveService(request: InterpretiveService): Observable<CustomerServiceResponse> {
        return this.http.post<CustomerServiceResponse>(this.InterpretiveServiceUrl, request)
            .pipe(catchError(error => HttpResponseHelper.handleError(this.configuration, error)));
    }

    getInterpretiveServiceSitecoreContent(): Observable<InterpretiveServiceSitecoreTemplate> {
        return this.http.get<InterpretiveServiceSitecoreTemplate>(this.languageService.getContentServiceUrlWithParameter(this.configuration.SitecoreServiceInterpretiveServiceParameter))
            .pipe(publishReplay(1)
            ,refCount()
            ,catchError(error => HttpResponseHelper.handleError(this.configuration, error)));
    }
}



