export class AddressValidationMessages {

    public AddressLine1Required: string;
    public AddressLine1ExceededMaxCharacter: string;
    public AddressLine2Required: string;
    public AddressLine2ExceededMaxCharacter: string;
    public CityRequired: string;
    public CityInvalid: string;
    public CityExceededMaxCharacter: string;
    public StateRequired: string;
    public ZipCodeRequired: string;
    public ZipCodeInvalid: string;
}
