import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  HttpClient,
  HttpParams,
  HttpHeaders,
  HttpResponse
} from '@angular/common/http';



import { OAuthService } from 'angular-oauth2-oidc';
import { Provider } from '../provider';
import { ChangeHnPcpRequest } from '../change-pcp-request';
import { ChangePcpResponse } from '../change-pcp-response';
import { Configuration } from '../../../app.constants';
import { SearchForProviderService } from '../../../core/shared/services/search-for-provider.service';
import { SearchForProviderSitecoreTemplate } from '../../../core/shared/search-for-provider-sitecore-template';
import { QueryStringParam } from '../../../core/shared/query-string-param';
import { RoutingHelper } from '../../../core/shared/helpers/routing-helper';
import { HttpResponseHelper } from '../../../core/shared/helpers/http-response-helper';
import {catchError} from 'rxjs/operators';

@Injectable()
export class ProviderService {
  private getProviderWithHealthNeworkServiceUrl: string;
  private changePcpServiceUrl: string;
  private searchForProviderContent: SearchForProviderSitecoreTemplate = new SearchForProviderSitecoreTemplate();

  constructor(
    private searchForProviderService: SearchForProviderService,
    private http: HttpClient,
    private configuration: Configuration,
    private oauthService: OAuthService,
    private routingHelper: RoutingHelper
  ) {
    this.getProviderWithHealthNeworkServiceUrl =
      configuration.GetProviderDetailsServiceUrl;
    this.changePcpServiceUrl = configuration.ChangePcpServiceUrl;
  }

  getProviderWithMemberInfoFilter(
    id: string,
    memberGroupId: number,
    memberSubGroupId: number,
    lob: string = this.configuration.EmptyString,
    age: number = 0,
    memberHealthNetworkPlanCode: string
  ): Observable<Provider> {
    try {
      let params: HttpParams = new HttpParams();
      params.set(this.configuration.IdQueryParamName, String(id));
      params.set(
        this.configuration.MemberGroupIdParamName,
        String(memberGroupId)
      );
      params.set(
        this.configuration.MemberSubGroupIdParamName,
        String(memberSubGroupId)
      );
      params.set(this.configuration.LobQueryParamName, String(lob));
      params.set(this.configuration.AgeQueryParamName, String(age));
      params.set(
        this.configuration.HealthNetworkPlanCodeQueryParamName,
        String(memberHealthNetworkPlanCode)
      );

      return this.http
        .get<Provider>(this.getProviderWithHealthNeworkServiceUrl, {
          params: params
        })
        .pipe(catchError(error =>
            HttpResponseHelper.handleError(this.configuration, error)
            )
        );
    } catch (ex) {
      return null;
    }
  }

  submitChangePcp(changePcpRequest: ChangeHnPcpRequest): Observable<ChangePcpResponse> {
    try {
      return this.http
        .post<ChangePcpResponse>(this.changePcpServiceUrl, changePcpRequest)
        .pipe(catchError(error =>
          HttpResponseHelper.handleError(this.configuration, error))
        );
    } catch (ex) {
      return null;
    }
  }
}
