import { Injectable, Inject } from '@angular/core';
import {
    HttpClient,
    HttpParams,
    HttpHeaders,
    HttpResponse
} from '@angular/common/http';

import { Observable } from 'rxjs';

import { OAuthService } from 'angular-oauth2-oidc';
import { TermsOfUseTemplate } from '../terms-of-use-sitecore-template';
import { Configuration } from '../../../app.constants';
import { HttpResponseHelper } from '../../../core/shared/helpers/http-response-helper';
import {publishReplay, refCount, catchError} from 'rxjs/operators';
@Injectable()
export class TermsOfUseService {
    private termsOfUseContentServiceUrl: string;

    constructor(private http: HttpClient, private configuration: Configuration) {
        this.termsOfUseContentServiceUrl =
            configuration.SitecoreContentServiceUrl +
            configuration.SitecoreServiceTermsOfUseParameter;
    }

    getTermsOfUseContent(): Observable<TermsOfUseTemplate> {
        return this.http
            .get<TermsOfUseTemplate>(this.termsOfUseContentServiceUrl)
            .pipe(publishReplay(1),
            refCount(),
            catchError(error =>
                HttpResponseHelper.handleError(this.configuration, error)
            ));
    }
}
