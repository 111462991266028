export class MemberProfileSitecoreTemplate {
    public MemberIDLabel: string = "";
    public DateOfBirthLabel: string = "";
    public FullNameLabel: string = "";
    public ContactInfoHeader: string = "";
    public PrimaryPhoneNumberLabel: string = "";
    public MobilePhoneNumberLabel: string = "";
    public EmailLabel: string = "";

    public PlanNameLabel: string = "";
    public ShareOfCostLabel: string = "";

    public CurrentHealthNetworkPlanHeader: string = "";
    public CurrentHealthNetworkPlanLabel: string = "";
    public CurrentHealthNetworkPhoneNumberLabel: string = "";
    public PCPInfoHeader: string = "";

    public PCPNameLabel: string = "";
    public PCPAddressLabel: string = "";
    public PCPPhoneLabel: string = "";
    
    public PreferredWrittenLanguageLabel: string = "";

    public PreferredSpokenLanguageLabel: string = "";

    public PreferredLanguagesHeaderLabel: string = "";
    public ViewPersonalInformationHeaderLabel: string = "";

    public ChangePersonInformationContent: string = "";
    public ChangePersonInformationEditContent: string = "";
    public ChangePersonInformationLinkContent: string = "";

    public ChangePersonalInformationBottomLink: string = "";
    public PersonInformationUpdateContent: string = "";

    public ManagerPortalLinkContent: string = "";

    public LoginInfoLinkContentContent: string = "";
    public PrimaryAddressLabel: string = "";
    public MailingAddressLabel: string = "";

    public PrimaryAddressHeaderText: string = "";
    public MailingAddressHeaderText: string = "";
    public EmailAddressHeaderText: string = "";
    public PrimaryPhoneHeaderText: string = "";
    public MobilePhoneHeaderText: string = "";
    public PersonalInformationHeaderText: string = "";
    public MemberPortalAccountHeaderText: string = "";
    public PersonalInformationButtonText: string = "";
    public MemberPortalAccountButtonText: string = "";
    public YourInfoHeaderText: string = "";
    public PrimaryAddressStateLabel: string = "";
    public PrimaryAddressCityLabel: string = "";
    public PrimaryAddressZipCodeLabel: string = "";
    public MailingAddressCityLabel: string = "";
    public MailingAddressStateLabel: string = "";
    public MailingAddressZipCodeLabel: string = "";
    public PCPAddress1Label: string = "";
    public PCPAddress2Label: string = "";
    public PCPAddressCityLabel: string = "";
    public PCPAddressStateLabel: string = "";
    public PCPAddressZipCodeLabel: string = "";
    public PrimaryAddress1Label: string = "";
    public MailingAddress1Label: string = "";
    public PCPProviderIdLabel: string = '';

    public TCPAHeader: string = "";
    public TCPAMessage: string = "";
    public TCPAOptInSelection: string = "";
    public TCPAOptOutSelection: string = "";
    public TCPANoPreference: string = "";
    public TCPANote: string = "";


}