<p-dialog [(visible)]="showModalMessage"  [modal]="true" [baseZIndex]="10000" styleClass="modal-dialog-md"
        [draggable]="false" [resizable]="false" [focusOnShow]="true" [closable]="false">
        <p-header>
            {{providerSearchResults?.FQHCDialogBoxTitle || "filter results:"}}
        </p-header>
        <div style="text-align: left;  margin-left: 15px;margin-right: 15px;">
           <p> {{providerSearchResults?.FQHCDialogBoxMessage || "test FQHC"}}</p>
        </div>
        <p-footer>
            <button ID="HealthNetworkBtn" (click)="showModalMessage=false"class="btn btn-primary" 
            style="padding-left:20px;padding-right:20px;height:40px;width:120px;">
            {{providerSearchResults?.FQHCDialogBoxOkLabel || "ok"}}
            </button>
        </p-footer>
    </p-dialog>

<h4>{{providerSearchResults?.ProviderSearchResultsLabel || "provider search results"}}</h4>
<br>

<div *ngIf="providerInfo">
    <div *ngIf="providerInfo.length > 0" class="row">
        <div class="col-md-8">
            <div class="form-group">
                <label>{{providerSearchResults?.FilterResultsLabel || "filter results:"}}</label>
                <input type="search" [(ngModel)]="searchText" class="form-control" id="provider-finder-search-box" autocapitalize="off" autocomplete="off" autocorrect="off" 
                placeholder="{{providerSearchResults?.FilterResultsPlaceholder||'filter results by medical specialty, name, city or zip code'}}" role="textbox" spellcheck="false" value="" (ngModelChange)="onFilterChange($event)">
            </div>
        </div>

        <div class="col-md-4">
            <div class="form-group">
                <label>{{providerSearchResults?.SortByLabel || "sort by:"}}</label>
                <select type="search" 
                    class="form-control" 
                    [(ngModel)]="sortBy" 
                    
                    (ngModelChange)="sort($event);">
                    <option value="-1">---Select---</option>
                    <option value="LastName">{{providerSearchResults?.ProviderNameLabel || "provider name"}}</option>
                    <option value="PrimarySpecialty">{{providerSearchResults?.ProviderSpecialtyLabel || "provider specialty"}}</option>
                    <option value="City">{{providerSearchResults?.CityLabel || "city"}}</option>
                    <option value="ZipCode">{{providerSearchResults?.ZipLabel || "zip"}}</option>
                    <option *ngIf="filteredProviderInfo[0] && filteredProviderInfo[0].DistanceInMiles" value="DistanceInMiles" selected>{{providerSearchResults?.DistanceLabel || "distance" }}</option>
                </select>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="list-group mb-4" *ngFor="let provider of filteredProviderInfo|providerInfoFilter:searchText|slice:this.pager.startIndex:this.pager.endIndex + 1; let idx = index">
                <div class="list-group-item" [ngClass]="provider.Selected ? 'active' : ''" (click)="onChange(provider, idx);">
                    <div class="row">
                        <div class="col-md-8">
                            <h3> {{provider.ProviderName}} </h3>
                        </div>
                        <div class="col-md-4">
                            <input #r id="selectedProvider" name="selectedProvider" [checked]="provider.Selected == true" type="radio"/>
                            <label class="control-label">&nbsp; {{providerSearchResults?.MakeThisMyPCPLabel || "make this my PCP"}} </label>
                            <div *ngIf="provider.Selected">{{providerSearchResults?.ClickOnSubmitMessageText ||"(please click on the submit to process your changes)"}}</div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label>{{searchForAProviderSitecoreTemplate?.MedicalGroupAffiliationsLabel||'medical group affiliations'}}:</label> {{provider.MedicalGroupAffiliation?.length>0 ? provider.MedicalGroupAffiliation: providerSearchResults?.NoneLabel}}<br />
                            <label>{{providerSearchResults?.ProviderIDLabel || "provider id: "}}</label> {{provider.ProviderId}}<br />
                            <label>{{providerSearchResults?.SpecialtyLabel || "specialty: "}}</label> {{provider.PrimarySpecialty}}<br />
                            <label>{{providerSearchResults?.AddressLabel || "address: "}} </label> {{provider.Address1}}
                            {{provider.Address2?", "+ provider.Address2 +',':', '}}
                            {{provider.City?provider.City +',':''}}
                            {{provider.State?provider.State +',':''}}
                            {{provider.ZipCode? provider.ZipCode:''}}<br />
                            <label>{{providerSearchResults?.DayPhoneLabel || "day phone: "}} </label>{{' '+provider.Phone|phone}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="alert alert-info" *ngIf="providerInfo.length==0">
    <i class="fa-duotone fa-info-circle fa-lg" aria-hidden="true"></i> &nbsp;
    {{providerSearchResults?.NoProvidersLabel|| "there are no providers that match your search criteria. Please try again."}}
</div>
<div class="text-center">
    <!-- items being paged -->
    <div *ngFor="let item of pagedItems">{{item.name}}</div>
    <!-- pager -->
    <ul *ngIf="pager.pages && pager.pages.length" class="pagination justify-content-center">
        <li class="page-item">
            <a class="page-link" (click)="setPage(1);">{{providerSearchResults?.PagingFirstLabel || "first"}}</a>
        </li>
        <li [ngClass]="{'page-item disabled' : pager.currentPage === 1}">                 
            <a class="page-link" (click)="setPage(pager.currentPage - 1);">{{providerSearchResults?.PagingPreviousLabel || "previous"}}</a>
        </li>
        <li *ngFor="let page of pager.pages" [ngClass]="{'page-item active':pager.currentPage === page}">
            <a class="page-link"  (click)="setPage(page);">{{page}}</a>
        </li>
        <li [ngClass]="{'page-item disabled' : pager.currentPage === pager.totalPages}">
            <a class="page-link"  (click)="setPage(pager.currentPage + 1);">{{providerSearchResults?.PagingNextLabel || "next"}}</a>
        </li>
        <li [ngClass]="{'page-item disabled' : pager.currentPage === pager.totalPages}">
            <a class="page-link"  (click)="setPage(pager.totalPages);">{{providerSearchResults?.PagingLastLabel || "last"}}</a>
        </li>
    </ul>
</div>
