<div class="container">
  <form (ngSubmit)="onSubmit(inquiryForm);" #inquiryForm="ngForm">
    <div class="centered-content panel">
      <h1 id="GeneralInquiryTitleLabel">{{generalInquiryTemplate?.TitleLabel || "General Inquiry"}}</h1>
      <h4>{{generalInquiryTemplate?.SubTitleLabel || "contact customer service"}}</h4>
      <p>{{generalInquiryTemplate?.TitleMessage || "to contact us online, click the link below to submit inquiries to our customer service department. in case of an emergency, call 911 or go to the nearest emergency room, then call your pcp or personal care coordinator as soon as possible." }}</p>
      <div *ngIf="message" class="alert alert-success">
        <i class="fa-duotone fa-check fa-lg" aria-hidden="true"></i> &nbsp;
          {{message}}
      </div>
      <div *ngIf="requirePreferredContactMessage" class="alert alert-error">
        <i class="fa-duotone fa-ban fa-lg" aria-hidden="true"></i> &nbsp;
          {{requirePreferredContactMessage}}
      </div>
      <error-summary></error-summary>
      <div class="">
        <div class="form-group col-xs-12">
          <label for="inquiry">{{generalInquiryTemplate?.QuestionLabel || "how can we help you?"}}</label>
          <textarea [(ngModel)]="inquiry" style="height: 100px;"
            class="form-control" 
            rows="3" 
            id="inquiryText" 
            name="inquiryText"
            [placeholder]="generalInquiryTemplate?.QuestionPlaceholderLabel" 
            (ngModelChange)="valuechange($event)"
            minlength={{minLength}} 
            maxlength={{maxLength}}
            required 
            #inquiryText="ngModel">
          </textarea>

          <div *ngIf="((inquiryText.touched && inquiryText.dirty) || isSubmit) && inquiry.length <= 0"
            class="alert alert-danger">
            {{generalInquiryTemplate?.CommentRequiredMessage || "comment is required"}}
          </div>
          <div *ngIf="(inquiry.length < minLength && inquiry.length !== 0) && inquiryText.touched && inquiryText.dirty"
            class="alert alert-danger">
            {{generalInquiryTemplate?.CommentMinLengthMessage + config.SpaceString + minLength  + config.SpaceString + generalInquiryTemplate?.CommentLengthCharactersMessage || "comment must be at least 4 characters long."}}
          </div>
          <div *ngIf="inquiry.length >= maxLength && inquiryText.touched && inquiryText.dirty"
            class="alert alert-danger">
            {{generalInquiryTemplate?.CommentMaxLengthMessage + config.SpaceString + maxLength + config.SpaceString + generalInquiryTemplate?.CommentLengthCharactersMessage || "comment cannot be more than 1000 characters long."}}
          </div>

          <div class="float-end" style="margin-bottom:0px; padding-bottom: 0px">
            <p>{{remainingCharacters | commaSeparated}} {{generalInquiryTemplate?.RemainingCharactersLabel || "Characters Remaining"}}</p>
          </div>
          <div class="pt-4">
            <div class="form-check" style="padding:10px">
              <label class="form-check-label">
                  <input 
                  name="preferredContactEmailChk"
                  id="preferredContactEmailChk"
                  class="form-check-input" 
                  type="checkbox"               
                  [(ngModel)]="IsEmailPreferredContact"
                  checked>
                  {{generalInquiryTemplate?.PreferredContactEmailLabel || "email"}}
              </label>
              <div [ngClass]="{'is-invalid': (email?.value != null) &&  email.invalid && email.touched && email.dirty }">
                <label 
                  for="email" 
                  class="col-form-label">
                  {{generalInquiryTemplate?.ContactEmailLabel || "at what email address may we contact you?"}}
                </label>
                <input 
                  id="general-inquiry-email" 
                  name="email" 
                  placeholder="" 
                  [(ngModel)]="contactEmail"
                  class="form-control" 
                  [ngClass]="{'is-invalid': (email.invalid || (IsEmailPreferredContact && contactEmail.length === 0)) && email.touched && email.dirty}"
                  #email="ngModel"
                  pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$" email 
                />
                <label class="invalid-feedback" for="email" 
                      *ngIf="email.invalid && email.touched && email.dirty">
                  {{this.generalInquiryTemplate?.InvalidEmailFormatMessage || 'email is invalid.'}}
                </label>
                <label class="invalid-feedback" for="email" 
                      *ngIf="IsEmailPreferredContact && contactEmail.length === 0">
                      {{generalInquiryTemplate?.EmailRequiredErrorMessage || "email is required"}}
                </label>
              </div>
            </div>
            <div class="form-check" style="padding:10px">
              <label class="form-check-label">
                <input
                  id="preferredContactPhoneChk"
                  name="preferredContactPhoneChk"
                  class="form-check-input" 
                  type="checkbox"                 
                  [(ngModel)]="IsPhonePreferredContact"
                  checked>
                  {{generalInquiryTemplate?.PreferredContactPhoneLabel || "phone"}}
              </label>
              <div [ngClass]="{'is-invalid': (phone?.value != null) &&  phone.invalid && phone.touched && phone.dirty }">
                <label 
                  for="phone" 
                  class="col-form-label">
                  {{generalInquiryTemplate?.ContactPhoneNumberLabel || "contact number"}}
                </label>
                <input 
                  id="general-inquiry-phone" 
                  name="phone" 
                  placeholder="___ - ___ - ____" 
                  [(ngModel)]="contactPhoneNumber"
                  class="form-control" 
                  maxlength="12" 
                  #phone="ngModel"
                  pattern="^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$" 
                  [ngClass]="{'is-invalid': (phone.invalid || (IsPhonePreferredContact && contactPhoneNumber.length === 0)) && phone.touched && phone.dirty}"
                  phone />
                  <label *ngIf="phone.invalid && phone.touched && phone.dirty" class="invalid-feedback" for="phone">
                    {{this.generalInquiryTemplate?.InvalidPhoneNumberErrorMessage || 'phone number is invalid.'}}
                  </label>
                  <label class="invalid-feedback" for="phone" 
                      *ngIf="IsPhonePreferredContact && contactPhoneNumber.length === 0">
                      {{generalInquiryTemplate?.PhoneNumberRequiredErrorMessage || "phone number is required"}}
                  </label>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group text-right col-md-12">
          <button type="button" class="btn btn-link" (click)="onReset(inquiryForm); ">
            {{generalInquiryTemplate?.ResetButtonLabel || "cancel"}}
          </button>
          <button 
            type="submit" 
            class="btn btn-primary"  
            name="submit" 
            id="submit">
            {{generalInquiryTemplate?.SubmitButtonLabel || "submit"}}
          </button>
        </div>
        <div class="form-group col-xs-12">
          <div class="">
            {{grievanceBottomMessage || "Note: if you wish to submit a grievance or an appeal, please use the "}}
            <a target="_blank" [href]="grievanceLink">{{grievanceLinkLabel || "online grievance/appeals form"}}</a>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
