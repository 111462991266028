import { Injectable, Inject } from '@angular/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    HttpClient,
    HttpParams,
    HttpHeaders,
    HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';


import { Configuration } from '../../../app.constants';
import { OAuthService } from 'angular-oauth2-oidc';
import { ProviderSearchSitecoreTemplate } from '../../shared/provider-search-sitecore-template';
import { ProviderSearchResultsSitecoreTemplate } from '../../shared/provider-search-results-sitecore-template';
import { HttpResponseHelper } from '../../../core/shared/helpers/http-response-helper';
import { ProviderSpecialty } from '../../shared/provider-specialty';
import { GenericLookup } from '../../shared/generic-lookup';
import { ProviderSearchParameter } from '../../shared/provider-search-parameter';
import { ProviderInfoSummary } from '../../shared/provider-info-summary';
import { SearchForAProviderSitecoreTemplate } from '../../shared/search-for-a-provider-sitecore-template';
import {publishReplay, refCount, catchError} from 'rxjs/operators';
import { LanguageService } from '../../../core/shared/services/language.service';

@Injectable()
export class ProviderSearchService {
    private providerSpecialtyService;
    private languagesService;
    private searchForAProviderService;
    private hospitalsService;

    constructor(private http: HttpClient, private configuration: Configuration, private languageService: LanguageService) {

        this.providerSpecialtyService =
            configuration.BaseMemberServiceUrl +
            configuration.ProviderSearchSpecialtiesURL;
        this.languagesService =
            configuration.BaseMemberServiceUrl +
            configuration.ProviderSearchLanguagesURL;
        this.searchForAProviderService =
            configuration.BaseMemberServiceUrl + configuration.ProviderSearchURL;
        this.hospitalsService =
            configuration.BaseMemberServiceUrl +
            configuration.ProviderSearchHospitalsURL;
    }

    getSitecoreContent(): Observable<ProviderSearchSitecoreTemplate> {
        try {
            return this.http
                .get<ProviderSearchSitecoreTemplate>(this.languageService.getContentServiceUrlWithParameter(this.configuration.SitecoreServiceProviderSearchParameter))
                .pipe(
                publishReplay(1)
                , refCount()
                , catchError(error =>
                    HttpResponseHelper.handleError(this.configuration, error)
                ));
        } catch (ex) {
            return null;
        }
    }

    getProviderSpecialties(): Observable<GenericLookup[]> {
        return this.http
            .get<GenericLookup[]>(this.providerSpecialtyService)
            .pipe(
            publishReplay(1)
            , refCount()
            , catchError(error =>
                HttpResponseHelper.handleError(this.configuration, error)
            ));
    }

    getProviderLanguages(): Observable<GenericLookup[]> {
        return this.http
            .get<GenericLookup[]>(this.languagesService)
            .pipe(
            publishReplay(1)
            , refCount()
            , catchError(error =>
                HttpResponseHelper.handleError(this.configuration, error)
            ));
    }

    getHospitalAffiliations(): Observable<GenericLookup[]> {
        return this.http
            .get<GenericLookup[]>(this.hospitalsService)
            .pipe(
            publishReplay(1)
            , refCount()
            , catchError(error =>
                HttpResponseHelper.handleError(this.configuration, error)
            ));
    }

    searchForProviders(params: ProviderSearchParameter): Observable<ProviderInfoSummary[]> {
        return this.http
            .post<ProviderInfoSummary[]>(this.searchForAProviderService, params)
            .pipe(
            publishReplay(1)
            , refCount()
            , catchError(error =>
                HttpResponseHelper.handleError(this.configuration, error)
            ));
    }

    getSearchForAProviderSitecoreTemplate(): Observable<SearchForAProviderSitecoreTemplate> {
        return this.http
            .get<SearchForAProviderSitecoreTemplate>(
            this.languageService.getContentServiceUrlWithParameter(this.configuration.SitecoreServiceChangeSearchForAProviderParameter)
            )
            .pipe(
            publishReplay(1)
            , refCount()
            , catchError(error =>
                HttpResponseHelper.handleError(this.configuration, error)
            ));
    }

    getProviderSearchResultsSitecoreTemplate(): Observable<ProviderSearchResultsSitecoreTemplate> {
        return this.http
            .get<ProviderSearchResultsSitecoreTemplate>(
            this.languageService.getContentServiceUrlWithParameter(this.configuration.SitecoreServiceProviderSearchResultsParameter)
            )
            .pipe(
            publishReplay(1)
            , refCount()
            , catchError(error =>
                HttpResponseHelper.handleError(this.configuration, error)
            ));
    }
}
