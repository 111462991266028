import { Injectable, Inject } from '@angular/core';
import {
  HttpClient,
  HttpParams,
  HttpHeaders,
  HttpResponse
} from '@angular/common/http';

import { Observable } from 'rxjs';

import { OAuthService } from 'angular-oauth2-oidc';
import { CustomerServiceResponse } from '../customer-service-response';
import { RequestIDCard } from '../request-id-card';
import { RequestIDCardSitecoreTemplate } from '../request-id-card-sitecore-template';
import { Configuration } from '../../../app.constants';
import { HttpResponseHelper } from '../../../core/shared/helpers/http-response-helper';
import {publishReplay,refCount,catchError} from 'rxjs/operators'
import { LanguageService } from '../../../core/shared/services/language.service';

@Injectable()
export class RequestIDCardService {
  private requestIDCardServiceUrl;

  constructor(
      private http: HttpClient,
      private configuration: Configuration,
      private languageService: LanguageService) {
      this.requestIDCardServiceUrl = configuration.RequestIDCardServiceUrl;
  }

  getRequestIDCardContent(): Observable<RequestIDCardSitecoreTemplate> {
    return this.http
        .get<RequestIDCardSitecoreTemplate>(this.languageService.getContentServiceUrlWithParameter(this.configuration.SitecoreServiceRequestIDCardParameter))
        .pipe(publishReplay(1),
        refCount(),
      catchError(error =>
        HttpResponseHelper.handleError(this.configuration, error)
      ));
  }
  getSitecorePrintFormats(): Observable<any> {
    return this.http
        .get<any>(this.languageService.getContentServiceUrlWithParameter(this.configuration.SitecoreServicePrintFormatsLookupParameter))
        .pipe(publishReplay(1),
        refCount(),
      catchError(error =>
        HttpResponseHelper.handleError(this.configuration, error)
      ));
  }
  submitRequestIDCard(requestIdCard: RequestIDCard): Observable<CustomerServiceResponse> {
    return this.http
        .post<CustomerServiceResponse>(this.requestIDCardServiceUrl, requestIdCard)
        .pipe(catchError(error =>
        HttpResponseHelper.handleError(this.configuration, error)
      ));
  }
}
