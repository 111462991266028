import { Injectable, Inject } from '@angular/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  HttpClient,
  HttpParams,
  HttpHeaders,
  HttpResponse
} from '@angular/common/http';

import { Observable } from 'rxjs';

import { Configuration } from '../../../app.constants';
import { OAuthService } from 'angular-oauth2-oidc';
import { MemberProfile } from '../member-profile';
import { ImportantDocumentsSiteCoreTemplate } from '../important-documents-sitecore-template';
import { ImportantLinkSitecoreTemplate } from '../important-link-sitecore-template';
import { HttpResponseHelper } from '../../../core/shared/helpers/http-response-helper';
import {catchError} from 'rxjs/operators';
import { LanguageService } from '../../../core/shared/services/language.service';

@Injectable()
export class ImportantDocumentsService {

  constructor(private http: HttpClient, private configuration: Configuration, private languageService: LanguageService) {
  }

  getGeneralImportantDocumentsContent(): Observable<
    ImportantDocumentsSiteCoreTemplate
  > {
    try {
      return this.http
        .get<ImportantDocumentsSiteCoreTemplate>(
          this.languageService.getContentServiceUrlWithParameter(this.configuration.SiteCoreServiceImportantDocumentsParameter)
      ).pipe(
          catchError(error =>
          HttpResponseHelper.handleError(this.configuration, error))
        );
    } catch (ex) {
      return null;
    }
  }
}
