/*
** Routing module defines routing for the member-portal module and all portal views
*/

import { NgModule } from '@angular/core';
import { RouterModule, provideRoutes, Routes } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthorizationsSummaryComponent } from './authorizations-summary/authorizations-summary.component';
import { ClaimsSummaryComponent } from './claims-summary/claims-summary.component';
import { MemberProfileComponent } from './member-profile/member-profile.component';
import { RequestIdCardComponent } from './request-id-card/request-id-card.component';
import { GeneralInquiryComponent } from './general-inquiry/general-inquiry.component';
import { InterpretiveServiceComponent } from './interpretive-service/interpretive-service.component';
import { PrintMemberIdCardComponent } from './print-member-id-card/print-member-id-card.component';
import { MedicalIdCardComponent } from './print-member-id-card/medical-id-card/medical-id-card.component';
import { OneCareIdCardComponent } from './print-member-id-card/onecare-id-card/onecare-id-card.component';
import { OneCareConnectIdCardComponent } from './print-member-id-card/onecareconnect-id-card/onecareconnect-id-card.component';
import { UpdateMemberProfileComponent } from './update-member-profile/update-member-profile.component';
import { AuthGuard } from '../core/shared/auth.guard';
import { MemberPlanAccessGuard } from '../core/shared/member-plan-access.guard';
import { PaceAccessGuard } from '../core/shared/pace-access.guard';
import { EligibilityStatusAccessGuard } from '../core/shared/eligibility-status-access.guard';
import { VIPRestrictedAccessGuard } from '../core/shared/vip-restricted-access.guard';
import { TosGuard } from '../core/shared/tos.guard';
import { DateFormatPipe } from '../core/shared/pipes/date-format.pipe';
import { ZipCodePipe } from '../core/shared/pipes/zipcode.pipe';
import { FormatAddressPipe } from '../core/shared/pipes/format-address.pipe';
import { SearchForProviderComponent } from './search-for-provider/search-for-provider.component';
import { EffectiveDateComponent } from './effective-date/effective-date.component';
import { ProviderSearchComponent } from './provider-search/provider-search.component';
import { ImportantDocumentsComponent } from './important-documents/important-documents.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { ChangePCPChangeHealthNetworkComponent } from './change-pcp-change-health-network/change-pcp-change-health-network.component';
import { ChangePCPComponent } from './change-pcp/change-pcp.component';
import { ChangeHealthNetworkComponent } from './change-health-network/change-health-network.component';
import { ProviderSearchResultsComponent } from './provider-search-results/provider-search-results.component';
import { AuthorizationsComponent } from './authorizations/authorizations.component';
import { ClaimsComponent } from './claims/claims.component';
import { AuthorizationDetailComponent } from './authorization-detail/authorization-detail.component';
import { HealthCheckComponent } from './health-check/health-check.component';
import { PersonalRepresentativeGuard } from '../core/shared/personal-representative.guard';
import { InterpretiveServiceGuard } from '../core/shared/interpretive-service.guard';
import { TCPAGuard } from '../core/shared/tcpa.guard';
import { MemberMessageComponent } from './member-message/member-message.component';
import { VirtualVisitsComponent } from './virtual-visits/virtual-visits.component';
import { VirtualVisitsRedirectComponent } from './virtual-visits-redirect/virtual-visits-redirect.component';

// Constant array of routes.
const routes: Routes = [
    {
        path: 'healthcheck',
        component: HealthCheckComponent
    },
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard, TosGuard]
    },
    {
        path: 'profile',
        component: MemberProfileComponent,
        canActivate: [AuthGuard, TosGuard, PersonalRepresentativeGuard, TCPAGuard]
    },
    {
        path: 'requestidcard',
        component: RequestIdCardComponent,
        canActivate: [AuthGuard, TosGuard, EligibilityStatusAccessGuard, PersonalRepresentativeGuard, TCPAGuard]
    },
    {
        path: 'generalinquiry',
        component: GeneralInquiryComponent,
        canActivate: [AuthGuard, TosGuard, EligibilityStatusAccessGuard, PersonalRepresentativeGuard, TCPAGuard]
    },
    {
        path: 'interpretiveservice',
        component: InterpretiveServiceComponent,
        canActivate: [
            AuthGuard,
            TosGuard,
            EligibilityStatusAccessGuard,
            PaceAccessGuard,
            PersonalRepresentativeGuard,
            InterpretiveServiceGuard,
            TCPAGuard]
    },
    {
        path: 'printidcard',
        component: PrintMemberIdCardComponent,
        canActivate: [AuthGuard, TosGuard, EligibilityStatusAccessGuard, PaceAccessGuard, PersonalRepresentativeGuard, TCPAGuard]
    },
    {
        path: 'updatememberprofile',
        component: UpdateMemberProfileComponent,
        canActivate: [AuthGuard, TosGuard, EligibilityStatusAccessGuard, PaceAccessGuard, PersonalRepresentativeGuard, TCPAGuard]
    },
    {
        path: 'searchforprovider',
        component: SearchForProviderComponent,
        canActivate: [AuthGuard, TosGuard, PersonalRepresentativeGuard, TCPAGuard]
    },
    {
        path: 'importantDocumentsLink',
        component: ImportantDocumentsComponent,
        canActivate: [AuthGuard, TosGuard, PersonalRepresentativeGuard, TCPAGuard]
    },
    {
        path: 'providersearch',
        component: ProviderSearchComponent,
        canActivate: [AuthGuard, TosGuard, PersonalRepresentativeGuard, TCPAGuard]
    },
    {
        path: 'termsofuse',
        component: TermsOfUseComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'privacyPolicy',
        component: PrivacyPolicyComponent,
        canActivate: [AuthGuard, TosGuard, TCPAGuard]
    },
    {
        path: 'changepcpchangehealthnetwork',
        component: ChangePCPChangeHealthNetworkComponent,
        canActivate: [
          AuthGuard,
          TosGuard,
          PaceAccessGuard,
          MemberPlanAccessGuard,
          EligibilityStatusAccessGuard,
          VIPRestrictedAccessGuard,
          PersonalRepresentativeGuard,
          TCPAGuard
        ]
    },

    {
        path: 'searchresults',
        component: ProviderSearchResultsComponent,
        canActivate: [AuthGuard, TosGuard, PersonalRepresentativeGuard, TCPAGuard]
    },
    {
        path: 'claims',
        component: ClaimsSummaryComponent,
        canActivate: [AuthGuard, TosGuard, PersonalRepresentativeGuard, TCPAGuard]
    },
    {
        path: 'authorizations',
        component: AuthorizationsSummaryComponent,
        canActivate: [AuthGuard, TosGuard, PersonalRepresentativeGuard, TCPAGuard]
    },
     {
        path: 'authorizationdetail',
        component: AuthorizationDetailComponent,
        canActivate: [AuthGuard, TosGuard, PersonalRepresentativeGuard, TCPAGuard]
    },
    {
        path: 'bhvirtualvisits',
        component: VirtualVisitsComponent,
        canActivate: [AuthGuard, TosGuard, PersonalRepresentativeGuard, TCPAGuard, PaceAccessGuard]
    },
    {
        path: 'bhvirtualvisitsredirect',
        component: VirtualVisitsRedirectComponent,
        canActivate: [AuthGuard, TosGuard, PersonalRepresentativeGuard, TCPAGuard, PaceAccessGuard]
    }
];

// Exported components used by main core module as declarations array
export const routedComponents = [
    DashboardComponent,
    AuthorizationsComponent,
    ClaimsComponent,
    MemberProfileComponent,
    RequestIdCardComponent,
    GeneralInquiryComponent,
    InterpretiveServiceComponent,
    PrintMemberIdCardComponent,
    MedicalIdCardComponent,
    OneCareIdCardComponent,
    UpdateMemberProfileComponent,
    OneCareConnectIdCardComponent,
    FormatAddressPipe,
    DateFormatPipe,
    SearchForProviderComponent,
    ChangeHealthNetworkComponent,
    EffectiveDateComponent,
    ProviderSearchComponent,
    ImportantDocumentsComponent,
    PrivacyPolicyComponent,
    TermsOfUseComponent,
    ChangePCPChangeHealthNetworkComponent,
    ChangePCPComponent,
    ProviderSearchResultsComponent,
    ClaimsSummaryComponent,
    AuthorizationsSummaryComponent,
    AuthorizationDetailComponent,
    HealthCheckComponent,
    MemberMessageComponent,
    VirtualVisitsComponent
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MemberPortalRoutingModule {}
