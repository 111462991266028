import { Injectable, Inject } from '@angular/core';
import {
  HttpClient,
  HttpParams,
  HttpHeaders,
  HttpResponse
} from '@angular/common/http';

import { Observable } from 'rxjs';

import { OAuthService } from 'angular-oauth2-oidc';
import { ChangePCPChangeHealthNetworkSitecoreTemplate } from '../change-pcp-change-health-network-sitecore-template';
import { MemberPlanOperation } from '../member-plan-operation';
import { Configuration } from '../../../app.constants';
import { HttpResponseHelper } from '../../../core/shared/helpers/http-response-helper';
import { ChangePCPReasonCodes } from '../../shared/change-pcp-reason-codes';
import {catchError, publishReplay, refCount} from 'rxjs/operators';
import { LanguageService } from '../../../core/shared/services/language.service';
import { KeyValue } from '@angular/common';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class ChangePCPChangeHealthNetworkService {
  private changePcpReasonCodesUrl;

  constructor(
    private http: HttpClient,
    private configuration: Configuration,
    private oauthService: OAuthService,
    private languageService: LanguageService) {
    this.changePcpReasonCodesUrl = configuration.GetChangePcpReasonCodesUrl;
  }

  getChangePCPChangeHealthNetworkSitecore(): Observable<
    ChangePCPChangeHealthNetworkSitecoreTemplate
  > {
    return this.http
      .get<ChangePCPChangeHealthNetworkSitecoreTemplate>(
        this.languageService.getContentServiceUrlWithParameter(this.configuration.SitecoreServiceChangePCPChangeHealthNetworkParameter)
    ).pipe(
        publishReplay(1)
        ,refCount()
       ,catchError(error => HttpResponseHelper.handleError(this.configuration, error)));
  }
  getChangePcpReasonCodesSitecore(): Observable<any> {
    return this.http
        .get<any>(this.languageService.getContentServiceUrlWithParameter(this.configuration.ChangePcpReasonCodesSitecoreUrl)
        ).pipe(
        publishReplay(1)
       ,refCount()
       ,catchError(error => HttpResponseHelper.handleError(this.configuration, error)));
  }
  getChangePcpReasonCodes(): Observable<Array<ChangePCPReasonCodes>> {
    return this.http
        .get<Array<ChangePCPReasonCodes>>(this.changePcpReasonCodesUrl)
        .pipe(
        publishReplay(1)
       ,refCount()
       ,catchError(error => HttpResponseHelper.handleError(this.configuration, error)));
  }
}
