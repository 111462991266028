import { Component, OnInit } from '@angular/core';
import { VirtualVisitsService } from '../shared/services/virtual-visits.service';
import { LanguageService, INotifyChange } from '../../core/shared/services/language.service';
import { BHVirtualVisitSitecoreTemplate } from '../shared/bhvirtual-visits-sitecore';
import { Subscription } from 'rxjs';

@Component({
  templateUrl: './virtual-visits.component.html',
  styleUrl: './virtual-visits.component.scss',
  providers: [VirtualVisitsService]
})
export class VirtualVisitsComponent implements OnInit {
  public errorMessage: string;
  public content: BHVirtualVisitSitecoreTemplate;
  public isActiveUrl: boolean = true;
  private subscription: Subscription;
  


  constructor(private virtualVisitsService: VirtualVisitsService, private languageService: LanguageService) {}

  ngOnInit() {
    try {
      this.subscription = this.languageService.notifyObservable$.subscribe((change: INotifyChange) => {
        if (change.propName === 'languageCode') {
          this.loadView();
        }
      });
    } catch (ex) {
      throw ex;
    }
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  
  private loadView() {
    this.virtualVisitsService.checkIsActiveUrl()
      .subscribe({
        next: (response) => {
          this.isActiveUrl = response;
        }
      });
    this.virtualVisitsService.getBHVirtualVisitsSitecoreContent()
      .subscribe({
        next: (response: BHVirtualVisitSitecoreTemplate) => this.content = response,
        error: (e) => (this.errorMessage = <any>e)
      });
  }

  public getRedirect(): void {
    window.open("/#/bhvirtualvisitsredirect", '_blank');
  }
}
