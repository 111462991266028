<div class="container">
<h1 id="headingChangeHNpcp">{{changePCPChangeHealthNetworkSitecoreTemplate.TitleLabel|| 'change health network / pcp'}}</h1>
<p>
    {{changePCPChangeHealthNetworkSitecoreTemplate.InstrusctionsLabel || 'you can change your current Health Network and Primary Care Provider (if applicable) by completing the request form below.'}}
    {{changePCPChangeHealthNetworkSitecoreTemplate.ChangePCPRetroactiveDateMessageText || "if you require a retroactive PCP change, please contact customer service directly."}}
</p>

<div class="changepcp-messages-section-top-bottom">
    <div *ngIf="message" class="alert alert-success" id="msgSucessSubmit" name="msgSucessSubmit">
        <i class="fa-duotone fa-check fa-lg" aria-hidden="true"></i>
        &nbsp;{{message || "your request has been submitted successfully"}} {{showEffectiveDate|date: 'longDate'}}{{secondaryMessage || ""}}
    </div>

    <error-summary ></error-summary>

    <div *ngIf="isReadOnly===true && isSubmit !== true">
        <div class="alert alert-info" id="msgErrorPreviousSubmission">
            <i class="fa-duotone fa-info-circle fa-lg" aria-hidden="true"></i>
            <ng-container *ngIf="!referenceIDPreviouslySubmitted; else showGenericMessageWithRefID">
                    {{changePCPChangeHealthNetworkSitecoreTemplate.ChangePCPChangeHealthnetworkFuturePRSegmentMessage1 ||'our records indicate that a change to your Health Network and/or PCP is already scheduled for '}} {{memberProfile.ChangePCPEffectiveDate|date: 'longDate'}}
                    {{changePCPChangeHealthNetworkSitecoreTemplate.ChangePCPChangeHealthnetworkFuturePRSegmentMessage2 ||'. please contact customer service if additional changes are required'}}
            </ng-container>
            <ng-template #showGenericMessageWithRefID>
                    {{this.changePCPChangeHealthNetworkSitecoreTemplate.KaiserSuccessMessage.replace(this.configuration.FXIRequestIDToken, referenceIDPreviouslySubmitted) ||'our records indicate you have submitted before.. already submitted before '}}
             </ng-template>
        </div>
    </div>
</div>

<div *ngIf="!isReadOnly" class="hdrow-md-12">
    <div *ngIf="isMedcalMemberOutsideCCN" class="alert alert-info">
        <i class="fa-duotone fa-info-circle fa-lg" aria-hidden="true"></i>
        {{changePCPChangeHealthNetworkSitecoreTemplate.ChangePCPChangeHealthnetworkMedicalCCNMembersMessageText || 'if you wish to stay within your current health network and change your PCP, please contact your health network directly.'}}
    </div>
    <h2>{{changePCPChangeHealthNetworkSitecoreTemplate.ChangePCPChangeHealthnetworkReasonForChangeLabel ||'reason for change'}}</h2>
  
    <div class="row" *ngIf="listOfReasonCodesSitecore">
        <div class="col-md-6 form-group pcp-info-box"[ngClass]="{'has-error has-feedback': reasonCodes.hasError('required') && reasonCodes.touched && reasonCodes.dirty}">
        
            <select  class="form-select" id="dropReasonCodes" name="dropReasonCodes" #reasonCodes="ngModel" [(ngModel)]="selectedReasonForChange" required>
                <option [selected] value="-1">{{listOfReasonCodesSitecore["Select"]}}</option>
                <option value="other">{{listOfReasonCodesSitecore["Other"]}}</option>
                <option *ngFor="let code of listOfReasonCodes"
                        [ngValue]="code">
                        {{listOfReasonCodesSitecore[code.Code]}}
                </option>
            </select>
        </div>
        <label *ngIf="reasonCodes.hasError('required') && reasonCodes.touched && reasonCodes.dirty && reasonCodes.value==-1" class="control-message control-label">
            {{changePCPChangeHealthNetworkSitecoreTemplate.ChangePCPChangeHealthnetworkReasonForChangeErrorMessage ||'the reason for change is required'}}
        </label>
    </div>
    
    <div class="row">
        <div class="col-md-6 pcp-info-box">
            <div class="form-group" *ngIf="selectedReasonForChange=='other'" [ngClass]="{'has-error has-feedback': otherReason.hasError('required') && otherReason.touched && otherReason.dirty}">
                <label class="control-label" for="otherReasonForChange">
                    {{changePCPChangeHealthNetworkSitecoreTemplate.ChangePCPChangeHealthnetworkOtherReasonForChangeLabel||'enter your other reason for change'}}
                </label>
                <input type="text" placeholder="Please enter you reason for change" class="form-control" [(ngModel)]="otherReasonForChange" required id="otherReason" name="otherReason" #otherReason="ngModel" />

                <label *ngIf="otherReason.hasError('required') && otherReason.touched && otherReason.dirty" class="control-message control-label">
                    {{changePCPChangeHealthNetworkSitecoreTemplate.ChangePCPChangeHealthnetworkOtherReasonForChangeErrorMessage || 'the reason for change is required'}}
                </label>
            </div>
        </div>
    </div>
</div>

<div *ngIf="memberProfile">
    <app-change-health-network [selectedHealthNetwork]="healthnetwork"
                               [memberProfile]="memberProfile"
                               [memberPlanOperation]="memberPlanOperation"
                               [isReadOnly]="isReadOnly"
                               (healthNetwork)="healthNetworkChanged($event)"
                               (kaiserEvent)="kaiserEvent($event)">
    </app-change-health-network>

    <app-change-pcp [loading]="loading"
                    [canChangePCP]="memberPlanOperation.IsProviderChangeAllowed"
                    [memberProfile]="memberProfile"
                    [isCurrentPCPInNetwork]="isCurrentPCPInNetwork"
                    [selectedOption]="selectedPCPOption"
                    [isKaiser]="isKaiserSelected"
                    [isAllowedToChangePCPWithoutHN]="isAllowedToChangePCPWithoutHN"
                    [currentHealthNetwork]="healthnetwork"
                    [showSearch]="showSearch"
                    [providerList]="providerInfo"
                    [collapseSearchPanel]="collapseSearchPanel"
                    [showCollapsibleButton]="showCollapsibleButton"
                    [showDropDown]="showPCPOptionsDropDown"
                    [showSearchResults]="showSearchResults"
                    [isReadOnly]="isReadOnly"
                    [showEffectiveDate]="showEffectiveDate"
                    [isKaiserSelected]="isKaiserSelected"
                    [selectedPCPOption]="selectedPCPOption"
                    [changePCPChangeHealthNetworkSitecoreTemplate]="changePCPChangeHealthNetworkSitecoreTemplate"
                    (changePCPOptionChanged)="changePCPOptionChanged($event)"
                    (searchForAProvider)="onSearchForAProvider($event)"
                    (selectedProvider)="onSelectedProviderChange($event)"
                    (cancel)="onCancel()">
    </app-change-pcp>
</div>
<div *ngIf="!isReadOnly">
    <div *ngIf="memberPlanOperation.IsProviderChangeAllowed ">
        <div class="info-personal-box" *ngIf="showButtons">
            
            <div class="row col-md-12 text-center" id="buttons">
                <span>
                    <button class="btn btn-link" id="btnStartOver" name="btnStartOver" (click)="onCancel();">
                        {{changePCPChangeHealthNetworkSitecoreTemplate.CancelButtonText|| 'start over'}}
                    </button>
            
                    <button *ngIf="selectedOption !='changePCP'"
                            class="btn btn-primary"
                            id="btnSubmitChanges" name="btnSubmitChanges"
                            type="submit"
                            [disabled]="disableSubmitButton
                                || (selectedReasonForChange === '-1'
                                || (selectedReasonForChange == 'other'
                                && (!otherReasonForChange || otherReasonForChange.trim().length === 0)))"
                            (click)="onClick();">
                            {{changePCPChangeHealthNetworkSitecoreTemplate.SubmitButtonText|| 'submit Changes'}}
                    </button>
                </span>
            </div>
        </div>
    </div>
</div>
</div>
