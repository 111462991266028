import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { CommonModule} from '@angular/common';
import { ProviderSearchSitecoreTemplate } from '../shared/provider-search-sitecore-template';
import { ProviderInfoSummary } from '../shared/provider-info-summary';
import { ProviderSearchResultsSitecoreTemplate } from '../shared/provider-search-results-sitecore-template';
import { ProviderInfo } from '../shared/provider-info';
import { ProviderSearchService } from '../shared/services/provider-search.service';
import { PagerService } from '../../core/shared/services/pager.service';
import { MemberProfile } from '../shared/member-profile';
import { Configuration } from '../../app.constants';
import {PhonePipe} from '../../core/shared/pipes/phone.pipe';
import {ProviderInfoFilterPipe} from '../shared/pipes/provider-info-filter.pipe';
import {finalize} from 'rxjs/operators';

import _, { map } from 'underscore';
//import { _ } from 'underscore';
import { INotifyChange, LanguageService } from '../../core/shared/services/language.service';
import { Subscription } from 'rxjs';
import { SearchForAProviderSitecoreTemplate } from '../shared/search-for-a-provider-sitecore-template';


@Component({
    selector: 'app-provider-search-results',
    templateUrl: './provider-search-results.component.html',
    providers: [ProviderSearchService]
})
export class ProviderSearchResultsComponent implements OnInit, OnChanges, OnDestroy {
    private allItems: any[];

    @Input() providerInfo: ProviderInfoSummary[];
    @Input() memberProfile = new MemberProfile();
    @Output() expandSearchPanel = new EventEmitter();
    @Output() selectedProvider = new EventEmitter();
    @Output() providerDetails = new EventEmitter();

    public showModalMessage: boolean = false;
    url: string;
    searchForAProviderSitecoreTemplate: SearchForAProviderSitecoreTemplate = new SearchForAProviderSitecoreTemplate();
    providerSearchResults: ProviderSearchResultsSitecoreTemplate;
    chosenProvider = new ProviderInfoSummary();

    isBusy: boolean = true;
    searchText: string = '';
    show = 5;
    sortBy: string;
    message: string;
    previousProvider: number;
    // pager object
    pager: any = {};

    // paged items
    filteredProviderInfo;
    pagedItems: any[];
    NoSearchResults: boolean = false;
    providerInfoFilter = new ProviderInfoFilterPipe();
    sortField = '-1';
    private subscription: Subscription;

    constructor(private providerSearchService: ProviderSearchService, private pagerService: PagerService,
        public config: Configuration, private languageService: LanguageService) { }

    ngOnChanges(changes: SimpleChanges) {
        // Default sort is proximity else if distanceinmiles is undefined
        // then use last name
        this.filteredProviderInfo = this.providerInfo;
        if (this.filteredProviderInfo && this.filteredProviderInfo[0] && this.filteredProviderInfo[0].DistanceInMiles > 0) {
            this.sortBy = this.config.SortByDistance;
            this.sort(this.config.SortByDistance);
        }
        else {
            this.sortBy = this.config.SortByProviderName;
            this.sort(this.config.SortByProviderName);
        }

        this.setPage(1);
    }

    ngOnInit() {
        try {
          this.subscription = this.languageService.notifyObservable$.subscribe((change: INotifyChange) => {
            if(change.propName === 'languageCode'){
              this.onLoad();
            }
          });
        }
        catch (ex) {
          throw ex;
        }
      }
    
    ngOnDestroy() {
    this.subscription.unsubscribe();
    }

    onLoad() {
        try {
            this.getProviderSearchResultsSitecoreTemplate();
            this.getSearchForAProviderSitecoreTemplate();
        } catch (ex) {
            throw ex;
        }
    }

    getProviderSearchResultsSitecoreTemplate(){
            this.providerSearchService.getProviderSearchResultsSitecoreTemplate().pipe(
                finalize(() => this.isBusy = false))
                .subscribe(
                response => {

                    this.providerSearchResults = response;
                    this.setPage(1);
                },
                error => (this.message = <any>error));

    }

    getSearchForAProviderSitecoreTemplate() {
        
            this.providerSearchService.getSearchForAProviderSitecoreTemplate().pipe(
                finalize(() => this.isBusy = false))
                .subscribe(
                response => {

                    this.searchForAProviderSitecoreTemplate = response;
                },
                error => (this.message = <any>error));

    }

    setPage(page: number) {
         if (page < 1 || page > this.pager.totalPages) {
            return;
         }
        // get pager object from service
        this.pager = this.pagerService.getPager(this.filteredProviderInfo.length, page);

        // get current page of items
        this.pagedItems = this.filteredProviderInfo.slice(this.pager.startIndex, this.pager.endIndex + 1);
    }

    onFilterChange(event) {
        if (this.previousProvider !== undefined) {
            this.filteredProviderInfo[this.previousProvider].Selected = false;
        }
        
        this.selectedProvider.emit(false);

        this.filteredProviderInfo = this.providerInfoFilter.transform(this.providerInfo, event);
        
        this.setPage(1);
        if (this.sortField !== '-1') {
            this.sort(this.sortField);
        }
    }

    sort(field) {
        const config = this.config;
        this.sortField = field;
        const asc = _.sortBy(this.filteredProviderInfo, function (currentObject) {
            switch (field) {
                case config.SortByProviderName:
                    if (currentObject.LastName === '') {
                        return 'z';
                    } else {
                        return currentObject.LastName;
                    }
                case config.SortByCity:
                    return currentObject.City;
                case config.SortByZipCode:
                    return currentObject.ZipCode;
                case config.SortByProviderSpecialty:
                    return currentObject.PrimarySpecialty;
                case config.SortByDistance:
                    return currentObject.DistanceInMiles;
            }
        });
        this.filteredProviderInfo = asc;

    }

    showSearchPanel() {
        this.expandSearchPanel.emit(false);
    }

    onChange(provider: ProviderInfoSummary, index: number) {

        if (this.previousProvider !== undefined) {
            this.filteredProviderInfo[this.previousProvider].Selected = false;
        }

        if (this.pager.currentPage === 1) {
            this.previousProvider = index;
        } else {
            // correctly determine where in the array the previous pcp was clicked
            // so we can use that to de-select that pcp
            // 1) subtract 1 from the current page
            // 2) use that number and multiply by the page size to get us to the correct section
            // 3) then use the index as an offset in that section to get us the correct position in the array
            // 4) retain that value so when the user clicks another pcp, we can de-select the previous
            this.previousProvider = ((this.pager.currentPage - 1) * (this.pager.pageSize)) + index;
        }
        provider.Selected = true;

        if (provider.IsPCP_FQHC === true ) {
            this.showModalMessage = true;
        }

        this.selectedProvider.emit(provider);
    }

    openProviderDetails(event: any) {
        event.stopPropagation();
        this.providerDetails.emit(null);

    }
}

