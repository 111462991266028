export class SearchForAProviderSitecoreTemplate {

    public LastNameLabel: string;
    public FirstNameLabel: string;
    public ProviderTypeLabel: string;
    public PCPLabel: string;
    public SpecialtyLabel: string;
    public GenderLabel: string;
    public LanguageLabel: string;
    public AccecptingNewPatientsLabel: string;
    public FacilityAffiliationsLabel: string;
    public MedicalGroupAffiliationsLabel: string;
    public CityLabel: string;
    public ZipCodeLabel: string;
    public WithinLabel: string;
    public SearchButtonText: string;
    public CancelButtonText: string;
    public ProviderDetailsLabel: string;
    public SearchForAPCPLabel: string;
    public MilesLabel: string;
    public ProviderLocatedQuestion: string;
}
