export class ChangePCPSitecoreTemplate {

    public AssignMeToADefaultPCPOption: string;
    public ChangeMyPCPOption: string;
    public KeepMyCurrentPCPOption: string;
    public MediCalInstructionsLabel: string;
    public PCPAssociatedWithHealthNetworkLabel: string;
    public YouWillBeAssignedToTheFollowingPCPLabel: string;
    public CanNotChangePCPLabel: string;
    public KaiserHealthNetworkLabel: string;
    public OCAndOCCInstructionsLabelPCPAssociatedWithHN: string;
    public OCAndOCCInstructionsLabelPCPNotAssociatedWithHN: string;
    public OCAndOCCInstructionsLabel: string;
    public MediCalInstructionsLabelNotAssociatedWithHN: string;
    public PrimaryCareProviderLabel: string;
    public Select: string;
}
