
<div class="centered-content container">
    
    <div class="col-md-12">
        <div class="h1">{{memberProfileContent.ViewPersonalInformationHeaderLabel || "view your personal information"}}</div>
     
        <p>
            {{memberProfileContent.ChangePersonInformationContent || "below is a summary of your member information with caloptima. "}}
            <span *ngIf="memberProfile.IsEligible && memberProfile.LOB.LOBCode != this.configuration.PaceLOBCode"> {{memberProfileContent.ChangePersonInformationEditContent || "if you need to make updates to information on your member record,"}} 
            <a class="nav-link-test" *ngIf="memberProfile.IsEligible && memberProfile.LOB.LOBCode != this.configuration.PaceLOBCode" routerLink="/updatememberprofile">{{memberProfileContent.ChangePersonInformationLinkContent || "click here."}}</a> </span>
        </p>
        <error-summary></error-summary>
    </div>
    <div class="col-md-12">

            <!-- BEGIN Your Info -->
            <div class="hdrow-md-12">
                <h2>{{memberProfileContent.YourInfoHeaderText || "your info"}}</h2>
                
                <div class="row">
                    <div class="col-md-4 control-label closer-group-info-box">
                        <label>{{memberProfileContent.FullNameLabel || "name:"}}</label>
                        <div>{{memberFullName}}</div>
                    </div>
                    <div class="col-md-4 control-label closer-group-info-box">
                        <label>{{memberProfileContent.DateOfBirthLabel || "date of birth:"}}</label>
                        <div>{{memberProfile?.DateOfBirth | dateFormat: configuration.MomentDateFormat}}</div>
                    </div>
                    <div class="col-md-4 control-label closer-group-info-box">
                        <label>{{memberProfileContent.ShareOfCostLabel || "share of cost member:"}}</label>
                        <div>{{memberProfile?.ShareOfCost}}</div>
                    </div>
               
                    <div class="col-md-4 control-label closer-group-info-box">
                        <label>{{memberProfileContent.MemberIDLabel || "member ID:"}}</label>
                        <div>{{memberProfile?.CIN}}</div>
                    </div>
                    <div class="col-md-4 control-label closer-group-info-box">
                        <label>{{memberProfileContent.PlanNameLabel || "program name:"}}</label>
                        <div>{{memberProfile?.LOB?.LobDisplayText}}</div>
                    </div>
                </div>
            </div>
            <!-- END Your Info -->

            <div class="hdrow-md-12">
                <h2>{{memberProfileContent.ContactInfoHeader || "contact details"}}</h2>
                <div class="row">
                    <div class="col-md-6 vdcol-md-6 info-box">
                        <app-address [properties]="primaryAddress.properties" [address]="primaryAddress.address"></app-address>
                    </div>
                    <div class="col-md-6 vdcol-md-6 info-box">
                        <app-address [properties]="mailingAddress.properties" [address]="mailingAddress.address"></app-address>
                    </div>

                </div>
            </div>

            <div class="hdrow-md-12">
                &nbsp;
                <div class="row">
                    <div class="col-md-4 vdcol-md-4 control-label info-box">
                        <app-email [viewProperties]="emailViewProperties" emailValue={{memberProfile.Email}}></app-email>
                    </div>
                    <div class="col-md-4 vdcol-md-4 control-label info-box">
                        <app-phone [viewProperties]="phoneViewProperties" phoneValue={{memberProfile.HomePhoneNumber}}></app-phone>
                    </div>

                    <div class="col-md-4 vdcol-md-4 control-label info-box">
                        <app-phone [viewProperties]="mobilePhoneViewProperties" phoneValue={{memberProfile.MobilePhoneNumber}}></app-phone>
                    </div>
                </div>
            </div>
            <!-- BEGIN communication preference-->
            <div class="hdrow-md-12">
                <h4>{{memberProfileContent?.TCPAHeader || "your communication preferences"}}</h4>
                <div class="row">
                    
                    <div class="col-md-12 info-language-box">
                        <label class="control-label">{{memberProfileContent?.TCPAMessage || "grant Caloptima permission to send member updates to your cell phone via automated calls or text messages."}}</label>
                        <div *ngIf="TCPAOptIn == true">{{memberProfileContent?.TCPAOptInSelection || "yes"}}</div>
                        <div *ngIf="TCPAOptIn == false">{{memberProfileContent?.TCPAOptOutSelection || "no"}}</div>
                        <div *ngIf="TCPAOptIn == null">{{memberProfileContent?.TCPANoPreference || "no preference set"}}</div>
                    </div>
                   
                </div>
            </div>
            <!-- BEGIN language preference-->
            <div class="hdrow-md-12">
                    <h4>{{memberProfileContent.PreferredLanguagesHeaderLabel || "language preferences"}}</h4>
                <div class="row">
                    
                    <div class="col-md-6 info-language-box">
                        <label class="control-label">{{memberProfileContent.PreferredWrittenLanguageLabel || "preferred written language:"}}</label>
                        <div>{{memberProfile?.PreferredWrittenLanguage?.Description}}</div>
                    </div>
                    <div class="col-md-6 info-language-box">
                        <label class="control-label">{{memberProfileContent.PreferredSpokenLanguageLabel || "preferred spoken language:"}}</label>
                        <div>{{memberProfile?.PreferredSpokenLanguage?.Description}}</div>
                    </div>
                </div>
            </div>
            <!-- END language preference-->
            <br />
            <!-- BEGIN current health plan-->
            <div class="hdrow-md-12">
                <h2>{{memberProfileContent.CurrentHealthNetworkPlanHeader || "current health network plan"}}</h2>
                <div class="row">
                    <div class="current-health-network-plan col-12 ">
                        <div class="control-label closer-group-info-box">
                            <label class="control-label">{{memberProfileContent.CurrentHealthNetworkPlanLabel || "health network name:"}}</label>
                            <div>{{memberProfile?.HealthNetworkPlan?.HealthNetworkName}}</div>
                        </div>
                    </div>
                    <div class="current-health-network-plan col-12 ">
                        <div class="control-label closer-group-info-box">
                            <label class="control-label">{{memberProfileContent.CurrentHealthNetworkPhoneNumberLabel || "phone number:"}}</label>
                            <div>{{memberProfile?.HealthNetworkPlan?.HealthNetworkPlanPhone | phone}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- END current health plan-->
            <!-- BEGIN current pcp-->
            <div *ngIf="memberProfile.IsEligible">
                <app-pcpinfo [properties]="pcpInfo.properties" [provider]="pcpInfo.provider"  [healthNetworkCode]="memberProfile.HealthNetworkPlan.HealthNetworkPlanCode"></app-pcpinfo>
            </div>
            <!-- END current pcp-->

            <div class="nohdrow-md-12">
                    <div class="info-bottom-row row col-md-12">
                        <div class="col-md-6 vdcol-md-6" *ngIf="memberProfile.IsEligible && memberProfile.LOB.LOBCode != this.configuration.PaceLOBCode" id="personal-information">
                            <h2>{{memberProfileContent.PersonalInformationHeaderText || "personal information"}}</h2>
                            <div class="info-bottom-row-main-text">
                                {{memberProfileContent.PersonInformationUpdateContent || "please note that changes to your personal information will take one (1) business day to update."}}
                            </div>
                            <div class="col-md-12 text-right bottom-align-text ">
                                <button class="btn btn-primary" type="submit" routerLink="/updatememberprofile">{{memberProfileContent.PersonalInformationButtonText || "update"}}</button>
                            </div>
                        </div>
                        <div class="col-md-6 vdcol-md-6" id="user-account">
                            <h2>{{memberProfileContent.MemberPortalAccountHeaderText || "member portal account"}}</h2>
                            <div class="info-bottom-row-main-text">
                                {{memberProfileContent.LoginInfoLinkContentContent || "click here to view and update your login credentials for member portal."}}
                            </div>
                            <div class="col-md-12 text-right bottom-align-text">
                                <button class="btn btn-primary" type="submit" (click)="redirectToUserProfile();">{{memberProfileContent.MemberPortalAccountButtonText || "update"}}</button>
                            </div>
                        </div>
                    </div>
                
            </div>
        </div>
    </div>