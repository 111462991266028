import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from "@angular/core";
import { ChangeHealthNetworkService } from "../shared/services/change-health-network.service";
import { ChangeHnSitecoreTemplate } from "../shared/change-hn-sitecore-template";
import { MemberProfile } from "../shared/member-profile";
import { HealthNetworkPlan } from "../shared/health-network-plan";
import { MemberProfileService } from "../shared/services/member-profile.service";
import { Configuration } from "../../app.constants";
import { PhonePipe } from "../../core/shared/pipes/phone.pipe";
import { MemberPlanOperation } from "../shared/member-plan-operation";
import { Subscription } from "rxjs";
import {
  INotifyChange,
  LanguageService,
} from "../../core/shared/services/language.service";
import { DateOfBirthProperties } from "../../core/shared/date-of-birth-properties";
import { environment } from "../../../environments/environment";

declare var $: any;

@Component({
  selector: "app-change-health-network",
  templateUrl: "./change-health-network.component.html",
  providers: [ChangeHealthNetworkService],
})
export class ChangeHealthNetworkComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Output() healthNetwork = new EventEmitter();
  @Output() kaiserEvent = new EventEmitter();

  @Input() selectedHealthNetwork = new HealthNetworkPlan();
  @Input() memberProfile = new MemberProfile();
  @Input() memberPlanOperation = new MemberPlanOperation();
  @Input() isReadOnly: boolean = false;

  public changeHNContent: ChangeHnSitecoreTemplate;
  public healthNetworks: Array<HealthNetworkPlan> =
    new Array<HealthNetworkPlan>();

  public showModalMessage: boolean = false;
  public show = false;
  public showSearchForProvider: boolean = false;
  public message: string;
  public healthNetworkPhone: string;
  public showKaiser12Months: boolean = false;
  public showKaiserFriendsFamily: boolean = false;
  public showNoKaiserMessage: boolean = false;
  public showKaiserSuccessMessage: boolean = false;
  public selectedKaiser12Months: boolean = null;
  public selectedKaiserFriendsFamily: boolean = null;
  public reachedEndOfKaiserWizard: boolean = false;
  public kaiser12MonthsQuestion: string;
  public kaiser12MonthsResponse: string;
  public kaiserFamilyMembersQuestion: string;
  public kaiserFamilyMemberResponse: string;
  public changePCPNoteString: string;
  private subscription: Subscription;
  // You must provide the Kaiser Member's Record Number
  public kaiserMemberFirstName: string;
  public kaiserMemberLastName: string;
  public kaiserMemberRecordNumber: string;
  public relationshipToKaiserMember: string;
  public kaiserMemberDobProperties = new DateOfBirthProperties();
  public kaiserMemberDob: string;
  public kaiserCoverageRegion: string;

  constructor(
    private changeHNService: ChangeHealthNetworkService,
    private memberProfileService: MemberProfileService,
    public configuration: Configuration,
    private languageService: LanguageService
  ) {}

  ngOnChanges(changes: SimpleChanges) {}
  ngOnInit() {
    try {
      this.getHealthNetworks();

      this.subscription = this.languageService.notifyObservable$.subscribe(
        (change: INotifyChange) => {
          if (change.propName === "languageCode") {
            this.onLoad();
          }
        }
      );
    } catch (ex) {
      throw ex;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onLoad() {
    try {
      this.changeHNService.getChangeHealthNetworkSitecoreContent().subscribe(
        (response) => {
          this.changeHNContent = response;
          this.kaiser12MonthsQuestion =
            this.changeHNContent.KaiserLast12MonthsMessage;
          this.kaiserFamilyMembersQuestion =
            this.changeHNContent.KaiserFamilyMembersMessage;
          this.loadDateOfBirthView();
        },
        (error) => {
          this.message = <any>error;
        }
      );
    } catch (ex) {
      throw ex;
    }
  }

  private loadDateOfBirthView(): void {
    this.kaiserMemberDobProperties.LabelText =
      this.changeHNContent && this.changeHNContent.KaiserMemberBirthdateLabel
        ? this.changeHNContent.KaiserMemberBirthdateLabel
        : "kaiser Member Birthdate *";
    this.kaiserMemberDobProperties.ErrorMessage =
      this.changeHNContent.InvalidFormatError;
    this.kaiserMemberDobProperties.Placeholder = "mm/dd/yyyy";
    this.kaiserMemberDobProperties.IsCorrectRange = (obj) => {
      return true;
    };
    this.kaiserMemberDobProperties.MonthName = "memberDobMonth";
    this.kaiserMemberDobProperties.DayName = "memberDobDay";
    this.kaiserMemberDobProperties.YearName = "memberDobYear";
    this.kaiserMemberDobProperties.ErrorMsgName = "memberDobErrorMsg";
    this.kaiserMemberDobProperties.TabIndexMonth = "0";
    this.kaiserMemberDobProperties.TabIndexDay = "0";
    this.kaiserMemberDobProperties.TabIndexYear = "0";
  }

  allowedNetworks(initialhealthNetworks: HealthNetworkPlan[]) {
    for (const network of initialhealthNetworks) {
      // Append (Current) to the text of the current Health Network text
      if (
        network.HealthNetworkPlanCode ===
        this.selectedHealthNetwork.HealthNetworkPlanCode
      ) {
        network.HealthNetworkPlanDisplayText =
          network.HealthNetworkPlanDisplayText +
          this.configuration.SpaceString +
          this.configuration.CurrentValue;
        this.healthNetworks.push(network);
      } else {
        this.healthNetworks.push(network);
      }
    }

    // FILTERS EXCLUSION NETWORKS
    this.healthNetworks = this.filterHealthNetworks(this.healthNetworks, environment.exclusionMapHN);
  }

  public showHealthNetworkDropDown(): boolean {
    let result: boolean = false;
    if (this.isReadOnly === false) {
      if (
        !this.memberPlanOperation.IsNetworkChangeAllowed &&
        this.memberProfile.LOB.LOBCode === this.configuration.MediCalLOBCode
      ) {
        result = false;
      } else {
        result = true;
      }
    } else {
      result = false;
    }
  
    return result;
  }

  public filterHealthNetworks(data: HealthNetworkPlan[], filterArray: string[]): HealthNetworkPlan[] {
    return data.filter(item => {
      return !filterArray.some(filterItem => {
        const [filterCspiId, filterHnId, filterDate, isShownAfter] = filterItem.split('|');

        const cspiMatch = filterCspiId ? item.HealthNetworkPlanCode === filterCspiId : true;
        const healthNetworkMatch = filterHnId ? item.HealthNetworkCode === filterHnId : true;
        let dateMatch = filterDate ? new Date() >= new Date(filterDate) : true;
        dateMatch = isShownAfter === "1" ? !dateMatch : dateMatch;

        // Exclude the item if all conditions match
        return cspiMatch && healthNetworkMatch && dateMatch;
      });
    });
  }

  public getHealthNetworks() {
    this.changeHNService.getHealthNetworks().subscribe((res) => {
      this.allowedNetworks(res);
      const phonePipe = new PhonePipe();
      this.healthNetworkPhone = phonePipe.transform(
        this.memberProfile.HealthNetworkPlan.HealthNetworkPlanPhone,
        ""
      );
      this.selectedHealthNetwork = this.memberProfile.HealthNetworkPlan;
    });
  }

  compareHealthNetworkPlan(
    l1: HealthNetworkPlan,
    l2: HealthNetworkPlan
  ): boolean {
    return l1.HealthNetworkCode === l2.HealthNetworkCode;
  }

  public resetKaiserWizard() {
    this.showKaiser12Months = false;
    this.showNoKaiserMessage = false;
    this.showKaiserFriendsFamily = false;
    this.selectedKaiser12Months = null;
    this.selectedKaiserFriendsFamily = null;
    this.showKaiserSuccessMessage = false;
    this.reachedEndOfKaiserWizard = false;
    this.kaiser12MonthsResponse = "";
    this.kaiserFamilyMemberResponse = "";

    this.resetKaiserFamilyMember();
  }

  private resetKaiserFamilyMember() {
    // this.selectedKaiserFriendsFamily = false;
    this.kaiserMemberFirstName = "";
    this.kaiserMemberLastName = "";
    this.kaiserMemberRecordNumber = "";
    this.relationshipToKaiserMember = "";
    this.kaiserMemberDob = "";
    this.kaiserCoverageRegion = "";
  }

  public onNetworkChange(healthNetworkCode) {
    this.healthNetwork.emit(this.selectedHealthNetwork);

    this.resetKaiserWizard();
    if (this.isKaiser()) {
      this.showKaiser12Months = true;
    } else if (
      this.selectedHealthNetwork?.HealthNetworkPlanCode !==
        this.memberProfile?.HealthNetworkPlan?.HealthNetworkPlanCode &&
      healthNetworkCode !==
        this.memberProfile?.HealthNetworkPlan?.HealthNetworkPlanCode
    ) {
      this.showModalMessage = true;
    }
  }

  showSuccessKaiserMessage() {
    this.showKaiserSuccessMessage = true;
    this.showNoKaiserMessage = false;
  }

  showFailedKaiserMessage() {
    this.showKaiserSuccessMessage = false;
    this.showNoKaiserMessage = true;
  }

  hideAllKaiserMessages() {
    this.showKaiserSuccessMessage = false;
    this.showNoKaiserMessage = false;
  }

  showKaiserMessage() {
    // Reached end of wizard and if questions are all true show success
    if (
      (this.selectedKaiser12Months === true ||
        this.selectedKaiserFriendsFamily === true) &&
      this.reachedEndOfKaiserWizard === true
    ) {
      this.showSuccessKaiserMessage();

      // if any of the selected values are false show failed kaiser message
    } else if (
      this.selectedKaiser12Months === false &&
      this.selectedKaiserFriendsFamily === false &&
      this.reachedEndOfKaiserWizard === true
    ) {
      this.showFailedKaiserMessage();

      // Catch all at end is to hide all messages
    } else {
      this.hideAllKaiserMessages();
    }
  }

  onKaiser12MonthsChange(changedValue: string) {
    if (changedValue === this.configuration.Yes) {
      this.selectedKaiser12Months = true;
      this.showKaiserFriendsFamily = false;
    } else if (changedValue === this.configuration.No) {
      this.selectedKaiser12Months = false;
      this.showKaiserFriendsFamily = true;
    } else {
      this.selectedKaiser12Months = null;
      this.showKaiserFriendsFamily = false;
    }
    this.kaiserFamilyMemberResponse = "";
    this.selectedKaiserFriendsFamily = null;
    this.resetKaiserFamilyMember();
    this.kaiser12MonthsResponse = changedValue;

    this.emitKaiserInfo();
    this.showKaiserMessage();
  }

  onKaiserFriendsFamilyChange(changedValue: string) {
    this.reachedEndOfKaiserWizard = true;
    if (changedValue === this.configuration.Yes) {
      this.selectedKaiserFriendsFamily = true;
    } else if (changedValue === this.configuration.No) {
      this.selectedKaiserFriendsFamily = false;
    } else {
      this.selectedKaiserFriendsFamily = null;
    }
    this.resetKaiserFamilyMember();
    this.kaiserFamilyMemberResponse = changedValue;
    this.emitKaiserInfo();
    this.showKaiserMessage();
  }

  onKaiserMemberFirstNameChange(changedValue: string) {
    this.kaiserMemberFirstName = changedValue;
    this.emitKaiserInfo();
    this.showKaiserMessage();
  }
  onKaiserMemberLastNameChange(changedValue: string) {
    this.kaiserMemberLastName = changedValue;
    this.emitKaiserInfo();
    this.showKaiserMessage();
  }
  onKaiserMemberRecordNumberChange(changedValue: string) {
    this.kaiserMemberRecordNumber = changedValue;
    this.emitKaiserInfo();
    this.showKaiserMessage();
  }
  onRelationshipToKaiserMemberChange(changedValue: string) {
    this.relationshipToKaiserMember = changedValue;
    this.emitKaiserInfo();
    this.showKaiserMessage();
  }
  public onKaiserMemberDobChanged($event): void {
    this.kaiserMemberDob = $event.dateOfBirthValue;
    //this.isValidMemberDob = $event.isValid;
    //this.isValidMemberDobRange = $event.isCorrectRange;
    if ($event.isValid && $event.isCorrectRange) {
      this.emitKaiserInfo();
      this.showKaiserMessage();
    }
  }

  public onKaiserCoverageRegionChanged(changedValue: string): void {
    this.kaiserCoverageRegion = changedValue;
    this.emitKaiserInfo();
    this.showKaiserMessage();
  }

  isKaiser(): boolean {
    let result: boolean = false;
    if (
      this.selectedHealthNetwork &&
      this.selectedHealthNetwork.HealthNetworkCode
    ) {
      if (
        this.selectedHealthNetwork.HealthNetworkCode?.toLocaleUpperCase() ===
          this.configuration.KaiserCodeRegular ||
        this.selectedHealthNetwork.HealthNetworkCode?.toLocaleUpperCase() ===
          this.configuration.KaiserCodeExpansion
      ) {
        result = true;
      }
    }

    return result;
  }

  emitKaiserInfo() {
    if (!this.isKaiser()) return;

    if (this.kaiser12MonthsResponse) {
      //Have you been with Kaiser in the last 12 months?
      this.changePCPNoteString =
        this.kaiser12MonthsQuestion + " " + this.kaiser12MonthsResponse;
    } else {
      this.changePCPNoteString = "";
    }

    if (!this.selectedKaiser12Months) {
      if (this.kaiserFamilyMemberResponse) {
        //Do you have family members in Kaiser?
        this.changePCPNoteString +=
          "\n" +
          this.kaiserFamilyMembersQuestion +
          " " +
          this.kaiserFamilyMemberResponse;
      } else {
        this.changePCPNoteString = "";
      }
    }

    if (this.selectedKaiserFriendsFamily) {
      if (
        this.kaiserMemberFirstName &&
        this.kaiserMemberLastName &&
        this.kaiserMemberRecordNumber &&
        this.relationshipToKaiserMember &&
        this.kaiserMemberDob &&
        this.kaiserCoverageRegion
      ) {
        this.changePCPNoteString +=
          "\nKaiser member first name: " +
          this.kaiserMemberFirstName +
          "\nKaiser member last name: " +
          this.kaiserMemberLastName +
          "\nKaiser member record number: " +
          this.kaiserMemberRecordNumber +
          "\nRelationship to Kaiser member: " +
          this.relationshipToKaiserMember +
          "\nKaiser member date of birth: " +
          this.kaiserMemberDob +
          "\nKaiser coverage region: " +
          this.kaiserCoverageRegion;
      } else {
        this.changePCPNoteString = "";
      }
    }
    this.kaiserEvent.emit({
      selectedKaiserFriendsFamily: this.selectedKaiserFriendsFamily,
      selectedKaiser12Months: this.selectedKaiser12Months,
      changePCPNote: this.changePCPNoteString,
    });
  }
}
