import { Component, OnInit, OnDestroy } from '@angular/core';
import { combineLatest } from 'rxjs';
import { MemberProfile } from '../shared/member-profile';
import { MemberProfileService } from '../shared/services/member-profile.service';
import { ImportantDocumentsSiteCoreTemplate } from '../shared/important-documents-sitecore-template';
import { ImportantLinkSitecoreTemplate } from '../shared/important-link-sitecore-template'
import { ImportantDocumentsService } from '../shared/services/important-documents.service';
import { Configuration } from '../../app.constants';
import { LanguageService } from '../../core/shared/services/language.service';
import { SubSink } from 'subsink';

@Component({
    providers: [ImportantDocumentsService],
    templateUrl: './important-documents.component.html',
    styleUrls: ['./important-documents.component.scss']
})
export class ImportantDocumentsComponent implements OnInit, OnDestroy {

    private message: string;
    private memberProfile: MemberProfile;

    importantDocumentsContent: ImportantDocumentsSiteCoreTemplate;
    importantLinksContent: Array<ImportantLinkSitecoreTemplate>;

    lineOfBusiness: string = this.config.EmptyString;
    MediCalCode: string = this.config.MediCalLOBCode;
    OCCCode: string = this.config.OneCareConnectLOBCode;
    OCCode: string = this.config.OneCareLOBCode;
    private subscriptions = new SubSink();

    constructor(private memberProfileService: MemberProfileService,
        private importantDocumentsService: ImportantDocumentsService,
        private config: Configuration,
        private languageService: LanguageService) { }

    ngOnInit() {
        this.subscriptions.sink = combineLatest([
            this.languageService.notifyObservable$,
            this.memberProfileService.notifyObservable$
        ]).subscribe(result => this.onLoad());
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    onLoad() {

        try {
            this.memberProfile = this.memberProfileService.memberProfile;
            this.lineOfBusiness = this.memberProfile.LOB.LOBCode;
            this.setImportantDocumentsContent(this.lineOfBusiness);
        }
        catch (ex) {
            this.message = ex;
            throw ex;
        }
    }

     private setImportantDocumentsContent(lineOfBusiness: string) {

        this.importantDocumentsContent = new ImportantDocumentsSiteCoreTemplate();
        this.importantDocumentsService.getGeneralImportantDocumentsContent()
            .subscribe(
                response => {
                    this.importantDocumentsContent = response;
                    this.importantLinksContent = this.getImportantLinks(lineOfBusiness, this.importantDocumentsContent);
                },
                error => {
                    this.message = <any>error;
                });
    }


    private getImportantLinks(lineOfBusiness: string, importantDoc: ImportantDocumentsSiteCoreTemplate): Array<ImportantLinkSitecoreTemplate> {

        let result: Array<ImportantLinkSitecoreTemplate>;
        switch (lineOfBusiness.toUpperCase()) {
            case this.config.OneCareConnectLOBCode.toUpperCase():
                result = importantDoc.ListOfDocuments.filter(link => link.OCC == true);
                break;

            case this.config.OneCareLOBCode.toUpperCase():
                result = importantDoc.ListOfDocuments.filter(link => link.OC == true);
                break;
            case this.config.MediCalLOBCode.toUpperCase():
                result = importantDoc.ListOfDocuments.filter(link => link.Medical == true);
                break;

        }
        // Get Eligiblity based content 
        if (this.memberProfile.IsEligible) {
            result = result.filter(link => link.DisplayName != this.config.InactiveMembersCommonFormsContent && link.DisplayName != this.config.InactiveMembersCommonQuestionsContent);
        }
        else {
            result = result.filter(link => link.DisplayName != this.config.CommonFormsContent && link.DisplayName != this.config.CommonQuestionsContent);
        }

        return result;
    }
}

