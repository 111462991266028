export class UpdateMemberProfileSitecoreTemplate {

    public UpdateMeberProfileZipCodeLabel: string = "";
    public UpdateMemberProfileCancelButtonText: string = "";
    public UpdateMemberProfileChangeButtonText: string = "";
    public UpdateMemberProfilePrimaryAddressZipCodeExceededMaxCharactersAllowedError: string = "";
    public UpdateMemberProfilePrimaryAddressZipCodeRequiredError
    public UpdateMeberProfileZipCodUpdateMemberProfileChangeButtonTexteLabel: string = "";
    public UpdateMemberProfileClearButtonText: string = "";
    public UpdateMemberProfileCurrentAddressLabel: string = "";
    public UpdateMemberProfileHeaderLabel: string = "";
    public UpdateMemberProfileInstructionsLabel: string = "";
    public UpdateMemberProfileMainPanelTitle: string = "";
    public UpdateMemberProfilePrimaryAddressCityExceededMaxCharactersAllowedError: string = "";
    public UpdateMemberProfilePrimaryAddressCityLabel: string = "";
    public UpdateMemberProfilePrimaryAddressCityRequiredError: string = "";
    public UpdateMemberProfilePrimaryAddressEnterYourNewAddrssLabel: string = "";
    public UpdateMemberProfilePrimaryAddressForwadedToSocialSecurityLabel: string = "";
    public UpdateMemberProfilePrimaryAddressIsRequiredError: string = "";
    public UpdateMemberProfilePrimaryAddressLine1ExceededNumbeOfCharactersAllowedError: string = "";
    public UpdateMemberProfilePrimaryAddressLine1Label: string = "";
    public UpdateMemberProfilePrimaryAddressLine2ExceededNumbeOfCharactersAllowedError: string = "";
    public UpdateMemberProfilePrimaryAddressLine2Label: string = "";
    public UpdateMemberProfilePrimaryAddressMakeMailingSameAsPrimaryLabel: string = "";
    public UpdateMemberProfilePrimaryAddressPanelTitle: string = "";
    public UpdateMemberProfilePrimaryAddressStateExceededMaxCharactersAllowedError: string = "";
    public UpdateMemberProfilePrimaryAddressStateLabel: string = "";
    public UpdateMemberProfilePrimaryAddressStateRequiredError: string = "";
    public UpdateMeberProfileZipCoUpdateMemberProfilePrimaryAddressZipCodeExceededMaxCharactersAllowedErrordeLabel: string = "";
    public UpdateMemberProfileSubmitButtonText: string = "";
    public UpdateMemberProfilePrimaryAddressInavlidZipCodeError: string = "";
    public UpdateMemberProfileCurrentMailingAddressLabel: string = "";
    public UpdateMemberProfileMailingAddressCityExceededMaxCharactersAllowedError: string = "";
    public UpdateMemberProfileMailingAddressCityLabel: string = "";
    public UpdateMemberProfileMailingAddressForwadedToSocialSecurityLabel: string = "";
    public UpdateMemberProfileMailingAddressLine1ExceededNumbeOfCharactersAllowedError: string = "";
    public UpdateMemberProfileMailingAddressLine2Label: string = "";
    public UpdateMemberProfileMailingAddressStateExceededMaxCharactersAllowedError: string = "";
    public UpdateMemberProfileMailingAddressStateLabel: string = "";
    public UpdateMemberProfileMailingAddressZipCodeRequiredError: string = "";
    public UpdateMemberProfileMailingAddressCityRequiredError: string = "";
    public UpdateMemberProfileMailingAddressEnterYourNewAddrssLabel: string = "";
    public UpdateMemberProfileMailingAddressIsRequiredError: string = "";
    public UpdateMemberProfileMailingAddressLine1Label: string = "";
    public UpdateMemberProfileMailingAddressLine2ExceededNumbeOfCharactersAllowedError: string = "";
    public UpdateMemberProfileMailingAddressPanelTitle: string = "";
    public UpdateMemberProfileMailingAddressStateRequiredError: string = "";
    public UpdateMemberProfileMailingAddressZipCodeExceededMaxCharactersAllowedError: string = "";
    public UpdateMemberProfileMailingAddressInavlidZipCodeError: string = "";
    public UpdateMemberProfilePrimaryPhoneNumberPanelTitle: string = "";
    public UpdateMemberProfilePrimaryPhoneNumberYourCurrentTelephoneNumberLabel: string = "";
    public UpdateMemberProfilePrimaryPhoneNumberEnterYourPrimaryPhoneNumberLabel: string = "";
    public UpdateMemberProfilePhoneNumberPrimaryPhoneNumberLabel: string = "";
    public UpdateMemberProfilePrimaryPhoneNumberRequiredError: string = "";
    public UpdateMemberProfilePrimaryPhoneNumberSuccessLabel: string = "";
    public UpdateMemberProfilePrimaryPhoneNumberExceededNumberOfCharactersError: string = "";
    public UpdateMemberProfilePrimaryPhoneNumberInvalidError: string = "";
    public UpdateMemberProfileMobilePhoneNumberPanelTitle: string = "";
    public UpdateMemberProfileMobilePhoneNumberYourCurrentTelephoneNumberLabel: string = "";
    public UpdateMemberProfileMobilePhoneNumberEnterYourMobilePhoneNumberLabel: string = "";
    public UpdateMemberProfileMobileNumberLabel: string = "";
    public UpdateMemberProfileMobilePhoneNumberRequiredError: string = "";
    public UpdateMemberProfileMobileNumberExceededNumberOfCharactersError: string = "";
    public UpdateMemberProfileMobilePhoneNumberInvalidError: string = "";
    public UpdateMemberProfileEmailPanelTitle: string = "";
    public UpdateMemberProfileEmailCurrentEmailLabel: string = "";
    public UpdateMemberProfileEmailEnterYourEmailLabel: string = "";
    public UpdateMemberProfileEmailLabel: string = "";
    public UpdateMemberProfileEmailRequiredError: string = "";
    public UpdateMemberProfilePrimaryAddressEmailBody: string = "";
    public UpdateMemberProfileEmailExceededNumberOfCharactersError: string = "";
    public UpdateMemberProfileEmailInvalidError: string = "";
    public UpdateMemberProfilePrimaryAddressEmailSubject: string = "";
    public UpdateMemberProfileMailingAddressEmailSubject: string = "";
    public UpdateMemberProfileMailingAddressEmailBody: string = "";
    public UpdateMemberProfilePrimaryPhoneNumberEmailSubject: string = "";
    public UpdateMemberProfilePrimaryPhoneNumberEmailBody: string = "";
    public UpdateMemberProfileMobilePhoneNumberEmailBody: string = "";
    public UpdateMemberProfileMobilePhoneNumberEmailSubject: string = "";
    public UpdateMemberProfileEmailAddressEmailSubject: string = "";
    public UpdateMemberProfileEmailAddressEmailBody: string = "";
    public UpdateMemberProfilePreferredLanguagesEmailSubject: string = "";
    public UpdateMemberProfilePreferredLanguagesEmailBody: string = "";
    public UpdateMemberProfilePreferredLanguagesPanelTitle: string = "";
    public UpdateMemberProfilePreferredLangaugesYourCurrentSpokenLanguageLable: string = "";
    public UpdateMemberProfilePreferredLangaugesYourCurrentWrittenLanguageLabel: string = "";
    public UpdateMemberProfilePreferredLangaugesYourCurrentFormatLabel: string = "";
    public UpdateMemberProfilePreferredLangaugesSpokenLanguageLabel: string = "";
    public UpdateMemberProfilePreferredLangaugesWrittenLanguageLabel: string = "";
    public UpdateMemberProfilePreferredFormatLabel: string = "";
    public UpdateMemberProfileFooterText: string = "";
    public UpdateMemberProfileAnErrorOccured: string = "";
    public UpdateMemberProfileSuccessMessage: string = "";
    public UpdateMemberProfileMailingAddressDisclaimar: string = "";
    public UpdatememberProfileAlternatePhoneNumberLabel: string = "";
    public UpdateMemberProfileAlternatePhoneNumberMedicalDisclaimar: string = "";
    public UpdateMemberProfileMediCalAltPhoneDisclaimerText: string = "";
    public UpdateMemberProfileMediCalOutOfCountyText: string = "";
    public UpdateMemberProfileOcInCountyText: string = "";
    public UpdateMemberProfileOccInCountyText: string = "";
    public UpdateMemberProfileMediCalAidRestrictionText: string = "";
    public UpdateMemberProfileOneCareConnectOutofAreaAddressChangeText = "";
    public UpdateMemberProfileOneCareOutofAreaAddressChangeText = "";
    public UpdateMemberProfileSafeAtHomeMemberPrimaryAddressDisclaimar: string = "";
    public UpdateMemberProfileSafeAtHomeMemberMailingAddressDisclaimar: string = "";
    public UpdateMemberProfileMediCalBcctpDisclaimerText: string = "";
    public UpdateMemberPofilePhoneNumberInfoAlertAidCodes: string = "";
    public UpdateMemberPofilePhoneNumberInfoAlertBCCTP: string = "";
    public UpdateMemberProfilePrimaryPhoneSSAConsentText: string = "";
    public UpdateMemberProfileAddressConsentLabel: string = "";
    public UpdateMemberProfileAddressConsentYesLabel: string = "";
    public UpdateMemberProfileAddressConsentNoLabel: string = "";
    public UpdateMemberProfileAddressCityMaxCharacters: string = "";
    public UpdateMemberProfileAddressCityInvalid: string = "";
    public UpdateMemberProfilePreferredLanguagesContactHealthNetworkText: string = "";
    public UpdateMemberProfileTCPAHeader: string = "";
    public UpdateMemberProfileTCPAMessage: string = "";
    public UpdateMemberProfileTCPAOptInSelection: string = "";
    public UpdateMemberProfileTCPAOptOutSelection: string = "";
    public UpdateMemberProfileTCPANote: string = "";
    public UpdateMemberProfileTCPAErrorMessage: string = "";
    public UpdateMemberProfileTCPASameDayUpdateAlert: string = "";
}
