import { Injectable, Inject } from '@angular/core';
import {
  HttpClient,
  HttpParams,
  HttpHeaders,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { OAuthService } from 'angular-oauth2-oidc';
import { PrivacyPolicySitecoreTemplate } from '../privacy-policy-sitecore-template';
import { Configuration } from '../../../app.constants';
import { HttpResponseHelper } from '../../../core/shared/helpers/http-response-helper';
import {publishReplay, refCount, catchError} from 'rxjs/operators';
import { LanguageService } from '../../../core/shared/services/language.service';

@Injectable()
export class PrivacyPolicyService {
  constructor(private http: HttpClient, private configuration: Configuration,  private languageService: LanguageService) {
  }

  getPrivacyPolicyContent(): Observable<PrivacyPolicySitecoreTemplate> {
    try {
      return this.http
        .get<PrivacyPolicySitecoreTemplate>(
          this.languageService.getContentServiceUrlWithParameter(this.configuration.SitecoreServicePrivacyPolicyParameter)
          )
          .pipe(
          publishReplay(1)
          , refCount()
          , catchError(error =>
              HttpResponseHelper.handleError(this.configuration, error)
          ));
    } catch (ex) {
      return null;
    }
  }
}
