import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';

import { Configuration } from '../../app.constants';
import { GeneralInquiryService } from '../shared/services/general-inquiry.service';
import { GeneralInquiry } from '../shared/general-inquiry';
import { GeneralInquirySitecoreTemplate } from '../shared/general-inquiry-sitecore-template';
import { CustomerServiceResponse } from '../shared/customer-service-response';

import { MemberProfileService } from '../shared/services/member-profile.service';
import { MemberProfile } from '../shared/member-profile';
import { PhonePipe } from '../../core/shared/pipes/phone.pipe';
import { combineLatest, Subscription } from 'rxjs';
import { INotifyChange, LanguageService } from '../../core/shared/services/language.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-general-inquiry',
  providers: [GeneralInquiryService],
  templateUrl: './general-inquiry.component.html',
  styleUrls: ['./general-inquiry.component.scss']
})
export class GeneralInquiryComponent implements OnInit, OnDestroy {
  public generalInquiry: GeneralInquiry = new GeneralInquiry();
  private subject: string = this.config.EmptyString;
  private customerServiceResponse: CustomerServiceResponse = new CustomerServiceResponse();
  inquiry: string = this.config.EmptyString;
  readonly: boolean = true;
  remainingCharactersMessage: string = this.config.EmptyString;
  remainingCharacters: number;
  message: string = this.config.EmptyString;
  errorMessage: string;
  requirePreferredContactMessage: string;
  minLength: number;
  maxLength: number;
  generalInquiryTemplate: GeneralInquirySitecoreTemplate = new GeneralInquirySitecoreTemplate();
  memberProfile: MemberProfile = new MemberProfile();
  phonePipe = new PhonePipe();
  initialPhoneNumber: string;
  initialEmail: string;
  grievanceLink: string;
  grievanceBottomMessage: string;
  grievanceLinkLabel: string;
  IsEmailPreferredContact: boolean = false;
  IsPhonePreferredContact: boolean = false;
  private subscriptions = new SubSink();
  
  contactPhoneNumber: string;
  contactEmail: string;
  isSubmit: boolean =  false;

  constructor(
    private generalInquiryService: GeneralInquiryService,
    public config: Configuration,
    private memberProfileService: MemberProfileService,
    private languageService: LanguageService
  ) {}

  ngOnInit() {
    try {

      this.subscriptions.sink = combineLatest([
        this.languageService.notifyObservable$,
        this.memberProfileService.notifyObservable$
      ]).subscribe(result => {
          this.memberProfile = this.memberProfileService.memberProfile;
          if (this.memberProfile) this.onLoad();
        });

    } catch (ex) {
      throw ex;
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onLoad() {
    try {
      // Setup default values
      this.readonly = true;
      this.remainingCharacters = this.config.MaxNumberOfCharacters;
      this.maxLength = this.config.MaxNumberOfCharacters;
      this.minLength = this.config.MinNumberOfCharacters;

      // populate General Inquiry Sitecore template entity
      this.loadView();
      this.loadMemberProfile();
    } catch (ex) {
      throw ex;
    }
  }

  loadView() {
    this.generalInquiryService.getGeneralInquirySitecoreContent().subscribe(
      response => {
        this.generalInquiryTemplate = response;
        this.loadMemberProfile();
      },
      error => (this.errorMessage = <any>error)
    );
  }

  loadMemberProfile() {
      this.contactEmail = this.memberProfile.UserProfileEmail;
      this.initialEmail = this.memberProfile.UserProfileEmail;
      this.initialPhoneNumber = this.memberProfile.HomePhoneNumber;
      this.contactPhoneNumber = this.phonePipe.transform(
        this.initialPhoneNumber,
        ''
      );
      this.setGrievanceLinkAndMessage();
  }

  valuechange(newValue) {
    this.remainingCharacters = this.maxLength - newValue.length;
    if (this.maxLength - this.remainingCharacters >= this.minLength) {
      this.readonly = false;
    }
  }

  onSubmit(form) {
    try {
      this.isSubmit = true;
      // Validate data input
      let IsValidEmailState: boolean = false;
      if ((this.IsEmailPreferredContact && this.contactEmail.length > 0)
          || (!this.IsEmailPreferredContact && this.contactEmail.length >= 0 )) {
            IsValidEmailState = true;
      }

      let IsValidPhoneState: boolean = false;
      if ((this.IsPhonePreferredContact && this.contactPhoneNumber.length > 0)
          || (!this.IsPhonePreferredContact && this.contactPhoneNumber.length >= 0)) {
            IsValidPhoneState = true;
      }

      if (!this.IsEmailPreferredContact && !this.IsPhonePreferredContact) {
        this.requirePreferredContactMessage = this.generalInquiryTemplate.RequiredPreferredContactMessage;
      } else if (form.form.valid
        && form.form.dirty
        && !this.readonly
        && (this.inquiry.length >= this.config.MinNumberOfCharacters)
        && IsValidEmailState
        && IsValidPhoneState) {

        // Populate General Inquiry entity if any data to show
        this.generalInquiry.question = this.inquiry;
        this.generalInquiry.subject = this.subject;

        if (!this.IsEmailPreferredContact) {
          this.generalInquiry.contactEmail = ' ';
        } else {
          this.generalInquiry.contactEmail = this.contactEmail;
        }

        if (!this.IsPhonePreferredContact) {
          this.generalInquiry.contactPhoneNumber = ' ';
        } else {
          this.generalInquiry.contactPhoneNumber = this.contactPhoneNumber;
        }

        // Call data service to submit inquiry
        this.generalInquiryService
          .submitGeneralInquiry(this.generalInquiry)
          .subscribe(
            response => {
              this.customerServiceResponse = response;
              this.generalInquiry.contactEmail = '';
              this.generalInquiry.contactPhoneNumber = '';
              let successMessage =  this.generalInquiryTemplate.SuccessMessage.replace(
                  this.config.FXIRequestIDToken, this.customerServiceResponse.RequestId);

              this.message = successMessage;
              this.onReset(form);
            },
            error => {
              this.errorMessage = <any>error;
              this.errorMessage = this.generalInquiryTemplate.ErrorMessage;
            }
          );
      }
    } catch (ex) {
      throw ex;
    }

    window.scrollTo(0, 0);
  }

  onReset(form) {
    this.generalInquiry = new GeneralInquiry();
    // Clear fields
    this.inquiry = this.config.EmptyString;
    this.isSubmit = false;
    this.remainingCharacters = this.maxLength;
    this.subject = this.config.EmptyString;
    this.requirePreferredContactMessage = '';
    this.generalInquiry.contactPhoneNumber = this.phonePipe.transform(
      this.initialPhoneNumber,
      ''
    );
    this.generalInquiry.contactEmail = this.memberProfile.UserProfileEmail;
    // Enable submit button
    this.readonly = true;
    form.form.markAsPristine();
  }

  setGrievanceLinkAndMessage() {
    switch (this.memberProfile.LOB.LOBCode) {
      case this.config.MediCalLOBCode: {
        this.grievanceBottomMessage = this.generalInquiryTemplate.Note1Label;
        this.grievanceLink = this.generalInquiryTemplate.GrievanceMediCalLink;
        this.grievanceLinkLabel = this.generalInquiryTemplate.GrievanceLabel;
        break;
      }
      case this.config.OneCareLOBCode: {
        this.grievanceBottomMessage = this.generalInquiryTemplate.Note1Label;
        this.grievanceLink = this.generalInquiryTemplate.GrievanceOCLink;
        this.grievanceLinkLabel = this.generalInquiryTemplate.GrievanceLabel;
        break;
      }
      case this.config.OneCareConnectLOBCode: {
        this.grievanceBottomMessage = this.generalInquiryTemplate.Note1Label;
        this.grievanceLink = this.generalInquiryTemplate.GrievanceOCCLink;
        this.grievanceLinkLabel = this.generalInquiryTemplate.GrievanceLabel;
        break;
      }
      case this.config.PaceLOBCode: {
        this.grievanceBottomMessage = this.generalInquiryTemplate.GrievancePaceMessage;
        this.grievanceLink = this.generalInquiryTemplate.GrievancePaceLink;
        this.grievanceLinkLabel = this.generalInquiryTemplate.GrivancePaceLinkLabel;
        break;
      }
    }
  }
}
