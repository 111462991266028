import { Component } from '@angular/core';
import { VirtualVisitsService } from '../shared/services/virtual-visits.service';

@Component({
  selector: 'app-virtual-visits-redirect',
  standalone: true,
  imports: [],
  templateUrl: './virtual-visits-redirect.component.html',
  styleUrl: './virtual-visits-redirect.component.scss'
})
export class VirtualVisitsRedirectComponent {

  constructor(private virtualVisitsService: VirtualVisitsService) {}

  ngOnInit() {
    this.getRedirect();
  };

  public getRedirect(): void {
    this.virtualVisitsService.getVirtualVisitConfig()
      .subscribe({
        next: (response) => {
          let url: string = response.BHVirtualVisitUrl + "?";
          url += "client_id=" +  response.ClientID + "&";
          url += "access_time=" + response.AccessTime + "&";
          url += "patient_id=" + response.PatientID + "&";
          url += "mac=" + response.HmacValue;
          window.open( url, "_self");
        }
      });
  }
}
