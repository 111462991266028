<div class="container-main">
    <h1>
        {{authorizationDetailsTemplate?.AuthorizationDetailLabel || "authorizations and referrals"}}
    </h1>

    <div class="row">
        <div class="panel panel-default">
            <div class="col-md-12">
                {{authorizationDetailsTemplate?.DisclaimerMessage || "a doctor or a specialist may submit a referral/authorization request on your behalf.
                Authorization requests marked as Urgent are processed within 1-3 business days. Authorization requests
                marked as Routine
                are processed within 5-7 business days."}}
            </div>

        </div>
    </div>
    <error-summary></error-summary>

        <div>
            <button class="btn btn-primary" routerLink="/authorizations">
                <i class="fa-duotone fa-arrow-left" aria-hidden="true"></i>
                <span> {{authorizationDetailsTemplate?.BackBtn || "Back"}}</span>
            </button>
        </div>
        <div id="referralInfo">
        <h2 >
            <span>{{authorizationDetailsTemplate?.ReferralNumberLabel || "Referral #:"}} {{ referralDetail?.ReferralId }}</span>
        </h2>

        
            <div class="row">
                <div class="col-md-2">
                    <label class="col-form-label"><b>{{authorizationDetailsTemplate?.StatusLabel || "Current
                            Status:"}}&nbsp;</b></label>
                    <i class="fa-duotone fa-circle fa-xs text-muted"
                        [ngClass]="{ 'text-success': referralDetail?.ReferralStatus =='Approved' || referralDetail?.ReferralStatus =='Closed'  }"></i>
                    <span>&nbsp;{{referralDetail?.ReferralStatus }}</span>
                </div>
                <div class="col-md-2">
                    <label class="col-form-label"><b>{{authorizationDetailsTemplate?.PriorityLabel ||
                            "Priority:"}}&nbsp;</b></label>
                    <span>{{ referralDetail?.Priority }}</span>
                </div>
                <div class="col-md-2">
                    <label class="col-form-label"><b>{{authorizationDetailsTemplate?.DateRequestedLabel ||
                            "Requested:"}}&nbsp;</b></label>
                    <span>{{ referralDetail?.DateRequested | date:'MM/dd/yy' }}</span>
                </div>
                <div class="col-md-2">
                    <label class="col-form-label"><b>{{authorizationDetailsTemplate?.DeterminedOnLabel || "Determined
                            On:"}}&nbsp;</b></label>
                    <span>{{ (referralDetail?.DateDecision | date:'MM/dd/yy') || 'NA' }}</span>
                </div>
                <div class="col-md-4">
                    <label class="col-form-label"><b>{{authorizationDetailsTemplate?.ValidThruLabel || "Valid
                            Thru:"}}&nbsp;</b></label>
                    <span>
                        {{ referralDetail?.ServiceBeginDate | date:'MM/dd/yy' }} {{ referralDetail.ServiceBeginDate &&
                        referralDetail.ServiceEndDate ? '-' : '' }} {{ referralDetail.ServiceEndDate | date:'MM/dd/yy' }}
                    </span>
                </div>
                <!-- <div class="download-container">
                        <span><b>Printable</b></span>
                        <a href="javascript:void()" (click)="onDownloadReferral()">Download</a>
                        </div> -->
            </div>
        </div>
            <div id="providerInfo">
            <div class="row">
                <div class="col-md-6">
                    <h3>
                        {{authorizationDetailsTemplate?.ReferringProviderLabel || "referring provider"}}
        
                    </h3>
                    <div class="form-group">
                        <h4>{{ referralDetail?.ReferringProvider?.ProviderName }}</h4>
                        <div class="text-muted">
                            <span><b>{{authorizationDetailsTemplate?.ProviderIDLabel || "Provider ID:"}}</b></span>
                            <span>{{ referralDetail?.ReferringProvider?.ProviderId }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8">
                            <label class="col-form-label"><b>{{authorizationDetailsTemplate?.AddressLabel ||
                                    "Address:"}}</b></label>
                            <div>{{ referralDetail?.ReferringProviderAddress?.ProviderAddressLine1 }}
                                {{ referralDetail?.ReferringProviderAddress?.ProviderAddressLine2 }}
                                {{ referralDetail?.ReferringProviderAddress?.ProviderCity }},
                                {{ referralDetail?.ReferringProviderAddress?.ProviderState }}
                                {{ referralDetail?.ReferringProviderAddress?.ProviderZipCode | zipcode}}
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label class="col-form-label"><b>{{authorizationDetailsTemplate?.PhoneLabel ||
                                    "Phone:"}}</b></label>
                            <div>{{ referralDetail?.ReferringProviderAddress?.ProviderPhone | phone}}</div>
                        </div>
                        <div class="col-md-8">
        
                        </div>
                        <div class="col-md-4">
                            <label class="col-form-label"><b>{{authorizationDetailsTemplate?.FaxLabel || "Fax:"}}</b></label>
                            <div>{{ referralDetail?.ReferringProviderAddress?.ProviderFax | phone}}</div>
                        </div>
                    </div>
                </div>
        
                <div class="col-md-6">
                    <h3>
                        {{authorizationDetailsTemplate?.ReferredProviderLabel || "referred provider"}}
                    </h3>
                    <div class="form-group">
                        <h4>{{ referralDetail?.ReferredProvider?.ProviderName }}</h4>
                        <div class="text-muted">
                            <span><b>{{authorizationDetailsTemplate?.ProviderIDLabel || "Provider ID:"}}</b></span>
                            <span>{{ referralDetail?.ReferredProvider?.ProviderId }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8">
                            <label class="col-form-label"><b>{{authorizationDetailsTemplate?.AddressLabel ||
                                    "Address:"}}</b></label>
                            <div>{{ referralDetail?.ReferredProviderAddress?.ProviderAddressLine1 }}
                                {{ referralDetail?.ReferredProviderAddress?.ProviderAddressLine2 }}
                                {{ referralDetail?.ReferredProviderAddress?.ProviderCity }},
                                {{ referralDetail?.ReferredProviderAddress?.ProviderState }}
                                {{ referralDetail?.ReferredProviderAddress?.ProviderZipCode | zipcode}}
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label class="col-form-label"><b>{{authorizationDetailsTemplate?.PhoneLabel ||
                                    "Phone:"}}</b></label>
                            <div>{{ referralDetail?.ReferredProviderAddress?.ProviderPhone | phone}}</div>
                        </div>
                        <div class="col-md-8">
        
                        </div>
                        <div class="col-md-4">
                            <label class="col-form-label"><b>{{authorizationDetailsTemplate?.FaxLabel || "Fax:"}}</b></label>
                            <div>{{ referralDetail?.ReferredProviderAddress?.ProviderFax | phone}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Services -->

        <div class="form-group">
            <h3>
                {{authorizationDetailsTemplate?.ServicesLabel || "services"}}
            </h3>
        </div>

        <p-table *ngIf="referralDetail && referralDetail.ReferralProcedures" [value]="referralDetail.ReferralProcedures"
            responsiveLayout="scroll" [rows]="10" [rowHover]="true" [autoLayout]="true"
            styleClass="p-datatable-responsive-demo">
            <ng-template pTemplate="header">
                <tr>
                    <th style="width:10%">{{authorizationDetailsTemplate?.StatusCol || "Status"}} </th>
                    <th style="width:10%">{{authorizationDetailsTemplate?.ServiceCol || "Service"}} </th>
                    <th style="width:10%">{{authorizationDetailsTemplate?.ModifierCol || "Modifier"}} </th>
                    <th style="width:10%">{{authorizationDetailsTemplate?.DecisionCol || "Decision"}} </th>
                    <th style="min-width:100px">{{authorizationDetailsTemplate?.DescriptionCol || "Description"}} </th>
                    <th style="width:10%">{{authorizationDetailsTemplate?.DurationCol || "Duration"}} </th>
                    <th style="width:10%">{{authorizationDetailsTemplate?.ServiceUnitsCol || "Service Units"}} </th>
                    <th style="width:10%">{{authorizationDetailsTemplate?.ApprovedUnitsCol || "Approved Units"}} </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-auth>
                <tr>
                    <td>
                        <span class="p-column-title">{{authorizationDetailsTemplate?.StatusCol || "Status"}}</span>
                        
                        <i class="fa-duotone fa-circle fa-xs text-muted"
                            [ngClass]="{ 'text-success': auth.Decision =='Approved' || auth.Decision =='Closed'}"></i>
                        <span>&nbsp;{{auth.Decision}}</span>
                    </td>
                    <td>
                        <span class="p-column-title">{{authorizationDetailsTemplate?.ServiceCol || "Service"}}</span>
                        {{auth.ProcedureCode}}
                    </td>
                    <td>
                        <span class="p-column-title">{{authorizationDetailsTemplate?.ModifierCol || "Modifier"}}</span>
                        {{auth.Modifiers}}
                    </td>
                    <td>
                        <span class="p-column-title">{{authorizationDetailsTemplate?.DecisionCol || "Decision"}}</span>
                        {{auth.DecisionStatusCode}}
                    </td>
                    <td>
                        <span class="p-column-title">{{authorizationDetailsTemplate?.DescriptionCol || "Description"}}</span>
                        {{auth.ProcedureDesc}}
                    </td>
                    <td>
                        <span class="p-column-title">{{authorizationDetailsTemplate?.DurationCol || "Duration"}} </span>
                        {{auth.Duration}}
                    </td>

                    <td>
                        <span class="p-column-title">{{authorizationDetailsTemplate?.ServiceUnitsCol || "Service Units"}}</span>
                        {{auth.UnitsRequested}}
                    </td>
                    <td>
                        <span class="p-column-title">{{authorizationDetailsTemplate?.ApprovedUnitsCol || "Approved Units"}}</span>
                        {{auth.UnitsApproved}}
                    </td>
                </tr>
            </ng-template>

        </p-table>

        <!-- End services -->
        <!-- Diagnosis -->

        <div class="form-group">
            <h3>
                {{authorizationDetailsTemplate?.DiagnosisLabel || "diagnosis"}}
            </h3>
        </div>
        <p-table *ngIf="referralDetail && referralDetail.ReferralDiagnoses" [value]="referralDetail.ReferralDiagnoses"
            responsiveLayout="scroll" [rows]="10" [rowHover]="true" [autoLayout]="true"
            styleClass="p-datatable-responsive-demo">
            <ng-template pTemplate="header">
                <tr>
                    <th style="width:10%">{{authorizationDetailsTemplate?.CodeCol || "Code"}} </th>
                    <th style="min-width:100px">{{authorizationDetailsTemplate?.DescriptionCol || "Description"}} </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-auth>
                <tr>
                    <td>
                        <span class="p-column-title">{{authorizationDetailsTemplate?.CodeCol || "Code"}}</span>
                        {{auth.DiagnosisCode}}
                    </td>
                    <td>
                        <span class="p-column-title">{{authorizationDetailsTemplate?.DescriptionCol || "Description"}}</span>
                        {{auth.DiagnosisDesc}}
                    </td>
                </tr>
            </ng-template>

        </p-table>
        
        <!-- End Diagnosis -->

</div>