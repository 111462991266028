import { LineOfBusiness } from './line-of-business';
import { HealthNetworkPlan } from './health-network-plan';

export class MemberIdCard {
  constructor() {
    this.HealthNetworkPlan = new HealthNetworkPlan();
    this.LineOfBusiness = new LineOfBusiness();
  }
  public CIN: string = '';
  public FirstName: string = '';
  public MiddleName: string = '';
  public LastName: string = '';
  public DateOfBirth: string = '';
  public HealthNetworkPlan: HealthNetworkPlan;
  public LineOfBusiness: LineOfBusiness;
  public ProviderName: string = '';
  public ProviderPhoneNumber: string = '';
  public RxBin: string = '';
  public RxPCN: string = '';
  public RxGroup: string = '';
  public RxServicesPhoneNumber: string = '';
  public VisionServicesPhoneNumber: string = '';
  public MemberServicesPhoneNumber: string = '';
  public KaiserDisclaimerMessage: string = '';
}
