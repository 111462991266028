import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { DashboardService } from './services/dashboard.service';
import { GeneralInquiryService } from './services/general-inquiry.service';
import { PrintMemberIdCardService } from './services/print-member-id-card.service';
import { FooterService } from './services/footer.service';
import { ProviderService } from './services/provider.service';
import { SearchForProviderService } from '../../core/shared/services/search-for-provider.service';

@NgModule({
    imports: [CommonModule, FormsModule],
    declarations: [
    ],
    providers: [
        //Add service here if that service will be used from more than one component 
        //If service is required only at one component level, use providers at component level
        DashboardService,// Keeping Dashboard here as this service will be called most oftenly 
        SearchForProviderService,
        ProviderService,
        
    ],
    exports: [
        CommonModule,
        FormsModule,       
    ]
})
export class MemberPortalSharedModule { }
