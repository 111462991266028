import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { AuthorizationsService } from '../shared/services/authorizations.service';
import { Authorization } from '../shared/authorization';
import { AuthorizationSitecoreTemplate } from '../shared/authorizations-sitecore-template';
import { MemberProfileService } from '../shared/services/member-profile.service';
import { LanguageService, INotifyChange } from '../../core/shared/services/language.service';
import { Referral } from '../shared/referral';


@Component({
    selector: 'app-authorizations-summary',
    templateUrl: './authorizations-summary.component.html',
    styleUrls: ['./authorizations-summary.component.scss'],
})
export class AuthorizationsSummaryComponent implements OnInit, OnDestroy {
    public filterVoid = true;
    public message: string;
    public auths: Referral[];
    public authTemplate: AuthorizationSitecoreTemplate;
    error: any;
    private subscription: Subscription;


    constructor(
        private authService: AuthorizationsService,
        private router: Router,
        private languageService: LanguageService
    ) { }

    ngOnInit() {
        try {
          this.subscription = this.languageService.notifyObservable$.subscribe((change: INotifyChange) => {
            if(change.propName === 'languageCode'){
              this.onLoad();
            }
          });
        }
        catch (ex) {
          throw ex;
        }
      }
    
      ngOnDestroy() {
        this.subscription.unsubscribe();
      }
    
    onLoad() {
        this.getSitcoreContent();
        this.getReferralSummary();
    }

    private getSitcoreContent() {
        this.authService.getAuthorizationsSitecoreContent().subscribe(
            response => {
                this.authTemplate = response;
            },
            error => {
                this.message = <any>error
                this.error = error.status;
            }
        );
    }
    private getReferralSummary() {
        this.authService.getReferralSummary().subscribe(
            response => {
                this.error = "";
                this.auths = response;
                if (this.filterVoid && this.auths) {
                    this.auths = this.auths.filter(
                        r => !r.ReferralStatus.includes('Void')
                    );
                }
            },
            error => {
                this.message = <any>error
                this.error = error.status;
            }

        );
    }
    
    authDetail(event:Event, auth: Authorization)
    {
        event.preventDefault();
        this.router.navigate(['authorizationdetail'],{state:{data:auth}});
    }
}
