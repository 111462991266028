
import {Configuration} from '../../app.constants';
import { Component, OnInit, HostListener } from '@angular/core';


@Component({
    selector: 'health-check',
    templateUrl: './health-check.component.html',
})
export class HealthCheckComponent implements OnInit {
 
    public memberPortalURL = "";
    constructor(private config: Configuration) {
        this.memberPortalURL = this.config.BaseMemberPortalUrl;
        }
    ngOnInit() {  }
}