<style type="text/css" media="screen">

  table{
  border-collapse:collapse;
  border:1px solid #FF0000;
  }
  
  table td{
  border:1px solid #FF0000;
  }
  </style>

<div class="container-main">
  <h1>
    {{authTemplate?.AuthorizationLabel || "authorizations and referrals"}}
  </h1>

  <div class="row">
    <div class="panel panel-default">
      <div class="col-md-12">
        {{authTemplate?.DisclaimerMessage || "a doctor or a specialist may submit a referral/authorization request on your behalf.
        Authorization requests marked as Urgent are processed within 1-3 business days. Authorization requests marked as Routine
        are processed within 5-7 business days."}}
      </div>

    </div>

  </div>
    <error-summary></error-summary>
    <div *ngIf="!auths || auths.length == 0" class="alert alert-info"> <i class="fa-duotone fa-info-circle"></i> {{authTemplate?.NoReferrals || 'You have no Authorizations and Referrals.'}} </div>
    <p-table #dt *ngIf="auths && auths.length > 0" [value]="auths" responsiveLayout="scroll" 
    dataKey = "ReferralId"
    [rows]="10"
    [paginator]="true"
    currentPageReportTemplate="{{authTemplate?.PagingMessage || 'Showing {first} to {last} of {totalRecords} records'}}"
    [showCurrentPageReport] = "true"
    [rowHover]="true"
    [autoLayout]="true"
    styleClass="p-datatable-responsive-demo"
    >
      <ng-template pTemplate="header">
          <tr>
              <th pSortableColumn="ReferralStatus" style="min-width:130px;width:10%">{{authTemplate?.StatusCol || "Status"}} <p-sortIcon field="ReferralStatus"></p-sortIcon></th>
              <th pSortableColumn="ReferralId" style="width:10%">{{authTemplate?.CodeCol || "Code"}} <p-sortIcon field="ReferralId"></p-sortIcon></th>
              <th pSortableColumn="ReferringProvider.ProviderName" style="width:20%">{{authTemplate?.ReferringProviderCol || "Referring Provider"}} <p-sortIcon field="ReferringProvider.ProviderName"></p-sortIcon></th>
              <th pSortableColumn="ReferredProvider.ProviderName" style="width:20%">{{authTemplate?.ReferredProviderCol || "Referred Provider"}} <p-sortIcon field="ReferredProvider.ProviderName"></p-sortIcon></th>
              <th pSortableColumn="DateRequested" style="width:10%">{{authTemplate?.RequestDateCol || "Request Date"}} <p-sortIcon field="DateRequested"></p-sortIcon></th>
              <th pSortableColumn="ServiceBeginDate" style="width:15%">{{authTemplate?.ValidFromDateCol || "Valid Thru"}}<p-sortIcon field="ServiceBeginDate"></p-sortIcon></th>
              <th pSortableColumn="Priority" style="width:10%">{{authTemplate?.PriorityCol || "Priority"}} <p-sortIcon field="Priority"></p-sortIcon></th>
              <th style="width:5%"></th>
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-auth>
          <tr>
            <td>
              <span class="p-column-title">{{authTemplate?.StatusCol || "Status"}}</span>
              <i class="fa-duotone fa-circle fa-xs text-muted" [ngClass]="{ 'text-success': auth.ReferralStatus =='Approved' || auth.ReferralStatus =='Closed'}"></i>
              <span>&nbsp;{{auth.ReferralStatus}}</span>
            </td>
              <td>
                <span class="p-column-title">{{authTemplate?.CodeCol || "Code"}}</span>
                <a href="#" (click)="authDetail($event,auth)">{{auth.ReferralId}}</a>
              </td>
              <td>
                <span class="p-column-title">{{authTemplate?.ReferringProviderCol || "Referring Provider"}}</span>
                {{auth.ReferringProvider?.ProviderName || "N/A"}}
              </td>
              <td>
                <span class="p-column-title">{{authTemplate?.ReferredProviderCol || "Referred Provider"}}</span>
                {{auth.ReferredProvider?.ProviderName || "N/A"}}
              </td>
              <td>
                <span class="p-column-title">{{authTemplate?.RequestDateCol || "Request Date"}} </span>
                {{auth.DateRequested | date : 'MM/dd/yy'}}
              </td>
              <td>
                <span class="p-column-title">{{authTemplate?.ValidFromDateCol || "Valid Thru"}}</span>
                {{ auth?.ServiceBeginDate | date:'MM/dd/yy' }} {{ auth.ServiceBeginDate &&
                  auth.ServiceEndDate ? '-' : '' }} {{ auth.ServiceEndDate | date:'MM/dd/yy' }}

              </td>
              <td>
                <span class="p-column-title">{{authTemplate?.PriorityCol || "Priority"}}</span>
                {{auth?.Priority}}
              </td>
              <td>
                <i class="fa-duotone fa-chevron-right fa-lg text-secondary" (click)="authDetail($event,auth)" ></i>
              </td>
          </tr>
      </ng-template>
  </p-table>
</div>
