import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateFn, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Configuration } from '../../app.constants';
import { MemberPlanOperation } from '../../member-portal/shared/member-plan-operation';
import { MemberProfile } from '../../member-portal/shared/member-profile';
import { MemberProfileService } from '../../member-portal/shared/services/member-profile.service';
import { map,switchMap, filter } from 'rxjs/operators';

// Check the access of pages as allowed in their plan
export const MemberPlanAccessGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
    
    try {

        const router: Router = inject(Router);
        const config: Configuration = inject(Configuration);

        return canUserAccessRoute(state.url, config)
            .pipe(
            map(
                res => {
                    if (!res) {
                        router.navigate([config.ApplicationComponent]);
                        return false;
                    }
                    else {
                        return true;
                    }
                },
                err => {
                    return false;
                }));
    }
    catch (ex) {
        throw ex;
    }
}

function canUserAccessRoute(activeUrl: string, config: Configuration): Observable<boolean> {
    return loadMemberPlanAllowedOperation()
        .pipe(
        map(res => {
            return isUrlAllowedToAccess(res, activeUrl, config);
        }));
}

function loadMemberPlanAllowedOperation(): Observable<MemberPlanOperation> {

    const memberProfileService: MemberProfileService = inject(MemberProfileService);
    let memberProfile: MemberProfile;

    return memberProfileService.getMemberProfile()
        .pipe
        (map(r => memberProfile = r)
        , filter(r=> r.IsEligible)
        , switchMap(res => memberProfileService.getMemberPlanAllowedOperation())
        , map(res => {
            return res;
        }));
}

function isUrlAllowedToAccess(memberPlanOperation: MemberPlanOperation, requestedRoute: string, config: Configuration): boolean {

    if ((requestedRoute === config.ChangePCPChangeHealthNetworkLink && memberPlanOperation.IsProviderChangeAllowed)
        || (requestedRoute === config.ChangePCPChangeHealthNetworkLink && memberPlanOperation.IsNetworkChangeAllowed)) {
        return true;
    }
    return false;
}

