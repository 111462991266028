
import { Component, OnInit, Output, Input, EventEmitter, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';

// CORE
import { SearchForProviderSitecoreTemplate } from '../../core/shared/search-for-provider-sitecore-template';

// Member Portal
import { Configuration } from '../../app.constants';
import { MemberProfile } from '../shared/member-profile';
import { MemberProfileService } from '../shared/services/member-profile.service';
import { ProviderSearchParameter } from '../shared/provider-search-parameter';
import { ProviderSearchService } from '../shared/services/provider-search.service';
import { ProviderInfoSummary } from '../shared/provider-info-summary';
import { GenericLookup } from '../shared/generic-lookup';
import { SearchForAProviderSitecoreTemplate } from '../shared/search-for-a-provider-sitecore-template';
import { Subscription } from 'rxjs';
import { INotifyChange, LanguageService } from '../../core/shared/services/language.service';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'search-for-provider',
    templateUrl: './search-for-provider.component.html',
    providers: [ProviderSearchService, Configuration],
    styleUrls: ['./search-for-provider.component.scss']
})

export class SearchForProviderComponent implements OnInit,OnChanges, OnDestroy {
    providerFilter: ProviderSearchParameter = new ProviderSearchParameter();

    public isBusy = true;
    public providerSpecialties: Array<GenericLookup> = new Array<GenericLookup>();
    public languages:  Array<GenericLookup> = new Array<GenericLookup>();
    public hospitalAffilitaions:  Array<GenericLookup> = new Array<GenericLookup>();
    public searchForAProviderSitecoreTemplate: SearchForAProviderSitecoreTemplate = new SearchForAProviderSitecoreTemplate();
    public searchForProviderContent: SearchForProviderSitecoreTemplate = new SearchForProviderSitecoreTemplate();
    fontAwesome;
    message: string;
    private subscription: Subscription;
    @Input() HealthNetwork: string;

    @Output() cancel = new EventEmitter();
    @Output() showSearchResults = new EventEmitter();
    @Output() searchForAProvider = new EventEmitter();
    @Output() togglePanel = new EventEmitter();

    @Input() showSearch: boolean = true;
    @Input() memberProfile: MemberProfile = new MemberProfile();
    @Input() collapseSearch: boolean = true;
    @Input() showCollapsibleButton: boolean;
    @Input() fontAwesomeArrow: string = this.configuration.FontAwesomeDoubleDownAngle;

    providerList = new Array<ProviderInfoSummary>();

    constructor(private searchForProviderService: ProviderSearchService,
        private memberProfileService: MemberProfileService,
        private configuration: Configuration,
        private languageService: LanguageService
        ) { }
        
        ngOnInit() {
            try {
              this.subscription = this.languageService.notifyObservable$.subscribe((change: INotifyChange) => {
                if(change.propName === 'languageCode'){
                  this.onLoad();
                }
              });
            }
            catch (ex) {
              throw ex;
            }
          }
        
          ngOnDestroy() {
            this.subscription.unsubscribe();
          }

    ngOnChanges(changes: SimpleChanges) {
    }

    onLoad() {
        this.resetSearchCriteria();
        try {
            this.getProviderSearchSitecoreContent();
            this.populateDropDowns();
        } catch (ex) {
            throw (ex);
        }
    }


    private populateDropDowns() {
        this.getLanguages();
        this.getProviderSpecialties();
        this.getHospitalAffiliations();
    }

    private getUrlForProviderSearch(lineOfBusiness: string) {
        try {
            let result: string = this.configuration.EmptyString;

            if (lineOfBusiness.toUpperCase() === this.configuration.OneCareLOBCode.toUpperCase()) {
                result = this.searchForProviderContent.OneCareSearchLink;
            }
            else if (lineOfBusiness.toUpperCase() === this.configuration.OneCareConnectLOBCode.toUpperCase()) {
                result = this.searchForProviderContent.OneCareConnectSearchLink;
            }
            else {
                result = this.searchForProviderContent.MediCalSearchLink;
            }
            return result;
        } catch (ex) {
            throw ex;
        }
    }

    private getProviderSpecialties() {
        try {
            this.searchForProviderService.getProviderSpecialties()
                .subscribe(response => {
                    this.providerSpecialties = response;
                },
                error => {
                    this.message = error;
                });
        } catch (ex) {
            throw (ex);
        }
    }

    private getHospitalAffiliations() {
        try {
            this.searchForProviderService.getHospitalAffiliations()
                .subscribe(response => {
                    this.hospitalAffilitaions = response;
                },
                error => {
                    this.message = error;
                });
        } catch (ex) {
            throw (ex);
        }
    }

    private getLanguages() {
        try {
            this.searchForProviderService.getProviderLanguages()
                .subscribe(response => {
                    this.languages = response;
                },
                error => {
                    this.message = error;
                });
        } catch (ex) {
            throw (ex);
        }
    }

    private getProviderSearchSitecoreContent() {
        this.searchForProviderService.getSearchForAProviderSitecoreTemplate()
            .subscribe(response => {
                this.searchForAProviderSitecoreTemplate = response;
            },
            error => {
                this.message = error;
            });
    }

    onCancel() {
        this.cancel.emit(null);
    }

    onClick(event: any) {
        event.stopPropagation();
        this.toggleSearchPanel();
        this.searchForAProvider.emit(this.providerFilter);
    }

    resetSearchCriteria() {
        this.providerFilter = new ProviderSearchParameter();
        this.providerFilter.ZipCode = this.memberProfile.HomeAddress.ZipCode;
        this.providerFilter.City = this.memberProfile.HomeAddress.City;
        this.providerFilter.Distance = '20+';
    }

    toggleSearchPanel() {
        this.collapseSearch = !this.collapseSearch;
        if (this.collapseSearch) {
            this.fontAwesomeArrow = this.configuration.FontAwesomeDoubleRightAngle;
        }
        else {
            this.fontAwesomeArrow = this.configuration.FontAwesomeDoubleDownAngle;
        }
        this.togglePanel.emit(this.fontAwesomeArrow);
    }
}

