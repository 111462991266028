<div class="container">
  <h1>
    Claims
  </h1>
  <div class="row">
    <div class="panel panel-default">
      <div class="col-md-12 col-xs-12">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
        laboris nisi ut
      </div>
    </div>
  </div>
  <div class="row" *ngIf="claims">
    <div class="col-xs-12">
      last updated: {{claims.LastUpdated | date:"short"}}
    </div>
    <div class="col-xs-12" style="margin-bottom: 110px;">
      <p-dataView #dv [value]="claims.Claims" selectionMode="single" [sortField]="sortField" [sortOrder]="sortOrder"
        [paginator]="true" [rows]="10" [rowsPerPageOptions]="[5,10,25,50]" paginatorPosition="bottom">
        <p-header>
          <div class="ui-helper-clearfix">
            <div class="ui-g">
              <div class="ui-g-12 ui-md-4">
                <p-dropdown [options]="sortOptions" [(ngModel)]="sortKey" placeholder="Sort By" (onChange)="onSortChange($event)"
                 [style]="{'min-width':'15em'}"></p-dropdown>
              </div>
            </div>
          </div>
        </p-header>
        <ng-template let-claim pTemplate="listItem" class="list-group">
          <div class="ui-g test" style="padding: 1em;border-bottom: 2px solid #d9d9d9" (click)="selectClaim($event, claim)">
            <div class="ui-g-12 ui-lg-6 ui-md-6 ui-sm-12">
              <div class="ui-g">
                <div class="ui-lg-4 ui-md-5 ui-sm-12"><b>{{cols[0].header}}:</b> </div>
                <div class="ui-lg-6 ui-md-7 ui-sm-12">{{claim.ClaimNumber}}</div>

                <div class="ui-lg-4 ui-md-5 ui-sm-12"><b>{{cols[6].header}}:</b></div>
                <div class="ui-lg-6 ui-md-7 ui-sm-12">{{claim.DateOfService | date:"shortDate"}}</div>

                <div class="ui-lg-4 ui-md-5 ui-sm-12"><b>{{cols[2].header}}:</b></div>
                <div class="ui-lg-6 ui-md-7 ui-sm-12">{{claim.HealthNetworkPlanName}}</div>

                <div class="ui-lg-4 ui-md-5 ui-sm-12"><b>{{cols[5].header}}:</b></div>
                <div class="ui-lg-6 ui-md-7 ui-sm-12">{{claim.Provider?.ProviderName}}</div>
              </div>
            </div>
            <div class="ui-g-12 ui-lg-6 ui-md-6 ui-sm-12">
              <div class="ui-g">
                <div class="ui-lg-4 ui-md-4 ui-sm-12"><b>{{cols[1].header}}:</b> </div>
                <div class="ui-lg-6 ui-md-8 ui-sm-12">{{claim.ProviderBilledAmt | currency}}</div>

                <div class="ui-lg-4 ui-md-4 ui-sm-12"><b>{{cols[3].header}}:</b> </div>
                <div class="ui-lg-6 ui-md-8 ui-sm-12">{{claim.COApprovedAmt | currency}}</div>

                <div class="ui-lg-4 ui-md-4 ui-sm-12"><b>{{cols[4].header}}:</b></div>
                <div class="ui-lg-6 ui-md-8 ui-sm-12"><b>{{claim.MemberPayableAmt | currency}}</b></div>
              </div>
            </div>
          </div>
        </ng-template>
      </p-dataView>
    </div>
  </div>
</div>
