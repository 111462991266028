export class PrintMemberIdCardSitecoreTemplate {
  public HeaderLabel = '';
  public InstructionsLabel = '';
  public PrintButtonText = '';
  public DownLoadButtonText = '';
  public MediCalFrontImage = '';
  public MedicalBackImage = '';
  public OneCareConnectFrontImage = '';
  public OneCareConnectBackImage = '';
  public OneCareFrontImage = '';
  public OneCareBackImage = '';
  public RequestIdCardByMailLink = '';
  public MediCalMemberIdLabel = '';
  public MediCalEffectiveDateLabel = '';
  public MediCalRxServicesLabel = '';
  public MediCalDOBLabel = '';
  public MediCalRxBinLabel = '';
  public MediCalVisionServicesLabel = '';
  public MediCalRXPCNLabel = '';
  public MediCalForMembersWhoMeetRequirementsLabel = '';
  public LargerFontLabel = '';
  public FooterLabel = '';
  public FooterRequestIdCardByMailLink = '';
  public KaiserDisclaimerMessage = '';
  public MediCalPcpLabel = '';
  public MediCalRxGrpLabel = '';
}
