export enum MemberProfileSection {
    PrimaryAddress = 1,
    MailingAddress = 2,
    Phone = 3,
    MobilePhone = 4,
    AlternatePhone = 5,
    Email = 6,
    Language = 7,
    TCPAOption
}
