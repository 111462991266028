import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Configuration } from '../../../app.constants';
import { HttpResponseHelper } from '../../../core/shared/helpers/http-response-helper';
import { AuthorizationList } from '../authorization-list';
import { AuthorizationSitecoreTemplate } from '../authorizations-sitecore-template';
import { AuthorizationDetailsSitecoreTemplate } from '../authorization-details-sitecore-template';
import { Authorization } from '../authorization';
import { Referral } from '../referral';
import { publishReplay, refCount, catchError } from 'rxjs/operators';
import { LanguageService } from '../../../core/shared/services/language.service';

@Injectable()
export class AuthorizationsService {
  private authorizationsServiceUrl: string;
  private authAll = 'authorizations/all/';
  private referralSummaryMemberServiceUrl: string;
  private referralDetailMemberServiceUrl: string;

  private authSummary: BehaviorSubject<any> = new BehaviorSubject(null);
  //getSelectedAuth$: Observable<any>;

  constructor(private http: HttpClient, private configuration: Configuration, private languageService: LanguageService) {
    this.referralSummaryMemberServiceUrl =
      configuration.BaseMemberServiceUrl +
      configuration.ReferralSummaryMemberServiceParameter;
    this.referralDetailMemberServiceUrl = 
    configuration.BaseMemberServiceUrl +
      configuration.ReferralDetailsMemberServiceParameter;
    //this.getSelectedAuth$ = this.authSummary.asObservable();
  }
  

  getAuthorizationsSitecoreContent(): Observable<AuthorizationSitecoreTemplate> {
    return this.http
      .get<AuthorizationSitecoreTemplate>(
        this.languageService.getContentServiceUrlWithParameter(this.configuration.SitecoreServiceAuthorizationParameter)
        )
      .pipe(
        publishReplay(1),
        refCount(),
        catchError(error =>
          HttpResponseHelper.handleError(this.configuration, error)
        )
      );
  }
  getAuthorizationDetailsSitecoreContent(): Observable<AuthorizationDetailsSitecoreTemplate> {
    return this.http
      .get<AuthorizationDetailsSitecoreTemplate>(
        this.languageService.getContentServiceUrlWithParameter(this.configuration.SitecoreServiceAuthorizationDetailsParameter)
      )
      .pipe(
        publishReplay(1),
        refCount(),
        catchError(error =>
          HttpResponseHelper.handleError(this.configuration, error)
        )
      );
  }

  getReferralSummary() {
    return this.http.get<Referral[]>(this.referralSummaryMemberServiceUrl).pipe(
      publishReplay(1),
      refCount(),
      catchError(error =>
        HttpResponseHelper.handleError(this.configuration, error)
      )
    );
  }
  getReferralDetails(referralId: string) {
    return this.http
      .get<Referral>(this.referralDetailMemberServiceUrl + '/' + referralId)
      .pipe(
        catchError(error =>
          HttpResponseHelper.handleError(this.configuration, error)
        )
      );
  }


  authSelectedChange(auth: any) {
    this.authSummary.next(auth);
  }
}
