import { Pipe, PipeTransform } from '@angular/core';
import { ProviderInfoSummary } from './../provider-info-summary';

@Pipe({
    name: 'providerInfoFilter',
    pure: false
})
export class ProviderInfoFilterPipe implements PipeTransform {
    transform(items: any[], filter: string): any {
        if (!items) return [];
        if (!filter) return items;;

        filter = filter.toLocaleLowerCase()
       let  results: any;
       return items.filter(item => item.ProviderName.toLocaleLowerCase().indexOf(filter) !== -1
           || item.City.toLocaleLowerCase().indexOf(filter) !== -1 ||
           item.ZipCode.toLocaleLowerCase().indexOf(filter) !== -1 ||
           item.PrimarySpecialty.toLocaleLowerCase().indexOf(filter) !== -1);

    }
}  
