import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';

import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';


import { DashboardSitecoreContentTemplate, DashboardSitecoreTemplate } from '../dashboard-sitecore-template';
import { Member} from '../member';
import { Configuration } from '../../../app.constants';
import { HttpResponseHelper } from '../../../core/shared/helpers/http-response-helper';
import {map, publishReplay, refCount, catchError, delay, retryWhen, timeout} from 'rxjs/operators';
import { LanguageService } from '../../../core/shared/services/language.service';
import { LanguageCode } from '../enum';
import { Alert } from '../alert';
import { CustomerServiceResponse } from '../customer-service-response';
import { TCPUpdate } from '../TCPUpdate';
import { CardItem, CtaItem, CarouselItem, MemberInfo, TCPAPreference } from '../carousel-item-content';
import { keyframes } from '@angular/animations';

@Injectable()
export class DashboardService {

private httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

    constructor(private http: HttpClient
        , private configuration: Configuration
        , private languageService: LanguageService
        , private oauthService: OAuthService) {
    }

    getDashboardContent(): Observable<DashboardSitecoreTemplate> {
        const dashboardContent_ByLanguage_ServiceUrl =
        this.languageService.getContentServiceUrlWithParameter(this.configuration.SitecoreServiceDashboardParameter);
        return this.http.get<DashboardSitecoreTemplate>(dashboardContent_ByLanguage_ServiceUrl)
            .pipe(
            map(res => {
                return res;
            })
            , publishReplay(1)
            , refCount()
            , catchError(error => HttpResponseHelper.handleError(this.configuration, error)));
    }

    getDashboardContentData(): Observable<DashboardSitecoreContentTemplate> {
        const dashboardContentUrl = this.languageService.getContentServiceUrlWithParameter(this.configuration.SitecoreServiceDashboardContentParameter);
        return this.http.get<DashboardSitecoreContentTemplate>(dashboardContentUrl).pipe(
            map(res => { 
                const carouselItems: CarouselItem[] = Object.keys(res)
                    .filter(key => key.startsWith('PortalMember.DashboardContent.CarouselItems'))
                    .map(key => res[key]);
                const cardItems: CardItem[] = Object.keys(res)
                    .filter(key => key.startsWith('PortalMember.DashboardContent.CardItems'))
                    .map(key => res[key]);
                const ctaItems: CtaItem[] = Object.keys(res)
                    .filter(key => key.startsWith('PortalMember.DashboardContent.CallToActionItems'))
                    .map(key => res[key]);
                const memberInfos: MemberInfo[] = Object.keys(res)
                    .filter(key => key.startsWith('PortalMember.DashboardContent.MemberInfo'))
                    .map(key => res[key]);
                const tcpaPreferences: TCPAPreference[] = Object.keys(res)
                    .filter(key => key.startsWith('PortalMember.DashboardContent.TCPAPreference'))
                    .map(key => res[key]);
                return { CarouselItems: carouselItems, CardItems: cardItems, CtaItems: ctaItems, MemberInfo: memberInfos[0], TCPAPreference: tcpaPreferences[0] };
            })
        , catchError(err => HttpResponseHelper.handleError(this.configuration, err)));
    }

    getAlerts(): Observable<Alert[]> {
        // tslint:disable-next-line:radix
        const timeOutObject: object = { 'first': Number(this.configuration.TimeoutValue)};
        return this.http
            .post<Alert[]>(this.configuration.AlertServiceUrl, { 'languageCode': this.languageService.languageCode}, this.httpOptions)
            .pipe(
                retryWhen(error => error.pipe(delay(this.configuration.ErrorDelay)))
                , timeout(timeOutObject)
                , publishReplay(1)
                , refCount()
                , catchError(error =>
                    HttpResponseHelper.handleError(this.configuration, error)
                    )
            );
    }
}
