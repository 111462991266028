
export class GeneralInquirySitecoreTemplate {
    public TitleLabel: string;
    public TitleMessage: string;
    public SubTitleLabel: string;
    public SubjectLabel: string;
    public SubjectPlaceholderLabel: string;
    public QuestionLabel: string;
    public QuestionPlaceholderLabel: string;
    public Note1Label: string;
    public Note2Label: string;
    public SubmitButtonLabel: string;
    public ResetButtonLabel: string;
    public RemainingCharactersLabel: string;
    public GrievanceLabel: string;
    public GrievanceMediCalLink: string;
    public SuccessMessage: string;
    public ErrorMessage: string;
    public CommentRequiredMessage: string;
    public CommentMinLengthMessage: string;
    public CommentMaxLengthMessage: string;
    public CommentLengthCharactersMessage: string;
    public ContactPhoneNumberLabel: string;
    public InvalidPhoneNumberErrorMessage: string;
    public PhoneNumberRequiredErrorMessage: string;
    public EmailRequiredErrorMessage: string;
    public GrievanceOCLink: string;
    public GrievanceOCCLink: string;
    public GrievancePaceLink: string;
    public GrievancePaceMessage: string;
    public GrivancePaceLinkLabel: string;
    public PreferredContactEmailLabel: string;
    public PreferredContactPhoneLabel: string;
    public InvalidEmailFormatMessage: string;
    public ContactEmailLabel: string;
    public RequiredPreferredContactMessage: string;
}

