import { Component, OnInit, ElementRef, Renderer2, OnDestroy } from '@angular/core';

import { Configuration } from '../../app.constants'; 
import { PrivacyPolicyService } from '../shared/services/privacy-policy.service';
import { PrivacyPolicySitecoreTemplate } from '../shared/privacy-policy-sitecore-template';
import { CustomerServiceResponse } from '../shared/customer-service-response';
import { Subscription } from 'rxjs';
import { LanguageService, INotifyChange } from '../../core/shared/services/language.service';

@Component({
    selector: 'app-privacy-policy',
    providers: [PrivacyPolicyService],
    templateUrl: './privacy-policy.component.html',
    styleUrls: ['./privacy-policy.component.scss'],
})

export class PrivacyPolicyComponent implements OnInit, OnDestroy {
 
    privacyPolicySitecoreTemplate: PrivacyPolicySitecoreTemplate = new PrivacyPolicySitecoreTemplate();
    message: string;
    private subscription: Subscription;

    constructor(private privatePolicyService: PrivacyPolicyService, public config: Configuration, private languageService: LanguageService) { }

    ngOnInit() {
        try {
          this.subscription = this.languageService.notifyObservable$.subscribe((change: INotifyChange) => {
            if(change.propName === 'languageCode'){
              this.onLoad();
            }
          });
        }
        catch (ex) {
          throw ex;
        }
      }
    
      ngOnDestroy() {
        this.subscription.unsubscribe();
      }
    
      onLoad() {
        this.privatePolicyService.getPrivacyPolicyContent()
            .subscribe(response => {
                this.privacyPolicySitecoreTemplate = response;
            },
            error => this.message = <any>error);
    }
}
