<div class="container">
    <div class="centered-content container-fluid">
        <div class="terms-of-use-body col-md-12">
            <wait-cursor [show]="isContentServiceBusy && isContentServiceBusy"></wait-cursor>
            <div [ngClass]="{'loading':isContentServiceBusy && isContentServiceBusy}"></div>
            <br />

            <div>
                <label>{{viewTemplate.TermsOfUseInstructions}}</label>
            </div>
            <error-summary></error-summary>
            <div [innerHtml]="viewTemplate.TermsOfUseMessage"></div>
            <div *ngIf="!memberProfile.HasAcknowledgedTermsOfUse">
                <hr />
                <div>
                    <p class="text-center"><strong>{{viewTemplate.AcknowledgementControlText}}</strong></p>
                </div>
                <div style="text-align:center">
                    <button type="submit" class="btn btn-primary" (click)="acknowledgeTermsOfUse();">{{viewTemplate.SubmitButtonText}}</button>
                </div>
            </div>
        </div>
    </div>
</div>
