export class RequestIDCardSitecoreTemplate {
    public MainHeading: string = "";
    public MainMessage: string = "";
    public MemberHeading: string = "";
    public MemberName: string = "";
    public MemberMailingAddress: string = "";
    public MemberCity: string = "";
    public MemberState: string = "";
    public MemberZipCode: string = "";
    public UpdateProfileLink1: string = "";
    public UpdateProfileLink2: string = "";
    public IfTemporaryMailingAddressLabel: string = "";
    public TemporaryMailingAddressMessage: string = "";
    public TemporaryMailingAddress1: string = "";
    public TemporaryMailingAddress2: string = "";
    public TemporaryMailingCity: string = "";
    public TemporaryMailingState: string = "";
    public TemporaryMailingZipCode: string = "";
    public IfPrintAlternateFormatLabel: string = "";
    public SubmitButton: string = "";
    public CancelButton: string = "";
    public ProfileUpdateMessage: string = "";
    public FooterDisclaimer: string = "";
    public RequestErrorMessage: string = "";
    public RequestSuccessMessage: string = "";
    public KaiserDisclaimerMessage: string = "";
    public TemporaryMailingCareOfLabel: string = "";
    public SecondaryMessageText: string = "";
    public TemporaryMailingAddressMessage2Text: string = "";
}