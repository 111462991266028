import { Component, OnInit, Directive, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { SubSink } from 'subsink';

import { Configuration } from '../../app.constants';
import { DashboardService } from '../shared/services/dashboard.service';
import { DashboardSitecoreContentTemplate, DashboardSitecoreTemplate } from '../shared/dashboard-sitecore-template';
import { Member } from '../shared/member';


import { MemberProfile } from '../shared/member-profile';
import { MemberProfileService } from '../shared/services/member-profile.service';

import { DashboardButtonContent } from '../shared/dashboard-button-content';
import { map, switchMap, finalize } from 'rxjs/operators';
import { LanguageService, INotifyChange } from '../../core/shared/services/language.service';
import { Alert, AlertType } from '../shared/alert';
import { TCPUpdate } from '../shared/TCPUpdate';
import { CustomerServiceResponse } from '../shared/customer-service-response';
// Importing JQuery - remove if dashboard removed or if change to use ng-bootstrap

declare var $: any;

@Component({

  templateUrl: './dashboard.component.html',
  providers: [DashboardService, Configuration],
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('carNextBtn', { static: false }) carNextBtn!: ElementRef;

  dashboard: DashboardSitecoreTemplate;
  dashboard2: DashboardSitecoreContentTemplate;
  member: Member[];
  dashboardButtons: Array<DashboardButtonContent> = new Array<DashboardButtonContent>();

  isLoading: boolean = true;
  memberProfile: MemberProfile = new MemberProfile();
  showTCPAModalMessage: boolean = false;
  TCPAOptIn: boolean = true;
  private customerServiceResponse: CustomerServiceResponse = new CustomerServiceResponse();
  // Initialized to specific date (09.10.2018).
  private model: Object;
  private subscriptions = new SubSink();
  public disablePersonalRepresentative = false;
  public alertList: Alert[];
  public generalAlerts: Alert[];
  public ageOutPRAlerts: Alert[];

  public alertTypeList: Map<string, object> = new Map([
    ['Warning', { Icon: 'fa-duotone fa-exclamation-triangle', Class: 'alert alert-warning' }],
    ['Info', { Icon: 'fa-duotone fa-info-circle', Class: 'alert alert-info' }]
  ]);

  constructor(
    private dashboardservice: DashboardService,
    private config: Configuration,
    private languageService: LanguageService,
    private memberProfileService: MemberProfileService
  ) { }

  public message: string;

  ngOnInit() {
    try {

      this.subscriptions.sink = combineLatest([
        this.languageService.notifyObservable$,
        this.memberProfileService.notifyObservable$
      ]).subscribe(result => {
          this.memberProfile = this.memberProfileService.memberProfile;
          if (this.memberProfile) this.onLoad();
        });

    } catch (ex) {
      throw ex;
    }
  }

  private onLoad(): void {

    this.subscriptions.sink = this.dashboardservice.getAlerts().subscribe(response => {
      this.alertList = response;
      this.generalAlerts = this.filterAlert(true);
      this.ageOutPRAlerts = this.filterAlert(false);
    });

    this.subscriptions.sink = this.dashboardservice.getDashboardContentData().pipe(
      finalize(() => this.isLoading = false)
    ).subscribe(res => {
      this.dashboard2 = res;
      // console.log('dashboard2:', this.dashboard2);
    })

    this.subscriptions.sink = this.dashboardservice.getDashboardContent().pipe(
      finalize(
        () => this.isLoading = false
      )).subscribe((response) => {
        this.dashboard = response;

        if (this.memberProfile.PersonalRepresentative) {
          this.disablePersonalRepresentative =
            this.memberProfile.PersonalRepresentative.PersonalRepresentativeStatusId === this.config.PersonalRepresentativePending
            || this.memberProfile.PersonalRepresentative.PersonalRepresentativeStatusId === this.config.PersonalRepresentativeDenied;
        }
        this.dashboardButtons = this.dashboard.DashboardButtons;

        const eligibilityStatus = this.memberProfile.IsEligible;
        const paceRestriction = (this.memberProfile.LOB.LOBCode !== this.config.PaceLOBCode);
        const activePR = !this.disablePersonalRepresentative;

        // Show Communication Dialog
        if (eligibilityStatus
          && paceRestriction
          && activePR
          && !this.memberProfile.TCPAValue) {
          this.showTCPAModalMessage = true;
        }
        else {
          this.showTCPAModalMessage = false;
        }
        // Using JQuery - remove if dashboard change to use ng-bootstrap
        // $(document).ready(function () {
        //   $('.carousel').carousel();
        // });

        if (eligibilityStatus) {
          this.dashboardservice.getAlerts().subscribe(responseAlerts => {
            this.alertList = responseAlerts;
          });
        }
      });

  }

  showCommunication() {
    this.showTCPAModalMessage = true;
  }

  submitCommunication() {

    this.showTCPAModalMessage = false;
    let request = new TCPUpdate();
    request.UserWarningMessageCode = this.TCPAOptIn ? 133 : 135;
    this.memberProfileService
      .TCPAUpdate(request)
      .subscribe(
        response => {
          this.customerServiceResponse = response;
          this.message = "Success";
        },
        error => {
          this.message = "Error";
        }
      );
  }

  public filterAlert(isGeneral: boolean) {
    return this.alertList.filter(x => x.IsGeneral == isGeneral);
  }

  public isExternalLink(url: string): boolean {
    //  Check if the link is external
    return /^(http|https):\/\//.test(url);
  }

  public setLinkTarget(url: string): string {
    return this.isExternalLink(url) ? '_blank' : '_self';
  }

  ngAfterViewInit() {
    // Automatically click the button when the view is initialized
    setTimeout(() => {
      this.carNextBtn.nativeElement.click();
    }, 10000);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
