<div class="container">
    <div class="centered-content container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div>
                    <h1>{{importantDocumentsContent?.LinksPageTitle||"links page title"}}</h1>
                    <p>{{importantDocumentsContent?.Instructions||"for your convenience, we have provided links below to commonly used forms, documents, and other resources accessible on www.caloptima.org."}}</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="well margin-well">
                    <div class="row">
                        <div class="col-md-1 push-right-icons">
                                <!-- assets\images\hand-holding-heart-solid.svg -->
                            <img 
                                src="{{importantDocumentsContent.YourBenefitsImage}}"
                                height="64px"
                                width="64px"                            
                            />
                        </div>
                        <div class="col-md-10" style="margin-left:10px;">
                            <div class="h3 no-margin-top-h3"> 
                                {{importantDocumentsContent?.YourBenfitsTitle||"your benefits"}}
                            </div>
                            <p>
                                {{importantDocumentsContent?.YourBenefitsPargraph}}
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-11">
                            <div class="pull-right" [ngSwitch]=lineOfBusiness>
                                <p *ngSwitchCase=MediCalCode>
                                    <a 
                                        class="btn btn-primary" 
                                        href="{{importantDocumentsContent?.MediCalBenefitsLink}}" target="_blank">{{importantDocumentsContent?.ViewBenefitsText||"view covered benefits"}}
                                    </a>
                                </p>
                                <p *ngSwitchCase=OCCode>
                                    <a 
                                        class="btn btn-primary" 
                                        href="{{importantDocumentsContent?.OCBenefitsLink}}" target="_blank">{{importantDocumentsContent?.ViewBenefitsText||"view covered benefits"}}
                                    </a>
                                </p>
                                <p *ngSwitchCase=OCCCode>
                                    <a 
                                        class="btn btn-primary" 
                                        href="{{importantDocumentsContent?.OCCBenefitsLink}}" target="_blank">{{importantDocumentsContent?.ViewBenefitsText||"view covered benefits"}}
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row"  *ngFor="let link of importantLinksContent">
            <div class="col-md-12">
                <div class="well margin-well">
                    <div class="row">
                        <div class="col-md-1 push-right-icons">
                            <img 
                                src="{{link.LinkDisplayImage}}"
                                height="64px"
                                width="64px"
                            />
                        </div>
                        <div class="col-md-10">        
                            <div class="h3 no-margin-top-h3" [innerHtml]> {{link?.BeforeLinkText}}</div>
                            <p [innerHtml]="link.AfterLinkText"></p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-11">
                            <div class="pull-right" [ngSwitch]=lineOfBusiness>
                                <p *ngSwitchCase=MediCalCode>
                                    <a  class="btn btn-primary" 
                                        href="{{link?.MedicalNavigationLinkSource}}" 
                                        target="_blank">
                                        {{link.LinkDisplayText}}
                                    </a>
                                </p>
                                <p *ngSwitchCase=OCCode>
                                    <a  class="btn btn-primary" 
                                        href="{{link?.OCNavigationLinkSource}}" 
                                        target="_blank">
                                        {{link.LinkDisplayText}}
                                    </a>
                                </p>
                                <p *ngSwitchCase=OCCCode>
                                    <a  class="btn btn-primary" 
                                        href="{{link?.OCCNavigationLinkSource}}" 
                                        target="_blank">
                                        {{link.LinkDisplayText}}
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


