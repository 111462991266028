import { Component, OnInit, HostListener } from '@angular/core';

import { ClaimsService } from '../shared/services/claims.service';
import { ClaimList } from '../shared/claim-list';
import { Claim } from '../shared/claim';
import { SelectItem } from 'primeng/api/selectitem';
import {finalize } from 'rxjs/operators';

@Component({
  selector: 'app-claims-summary',
  templateUrl: './claims-summary.component.html',
  styleUrls: ['./claims-summary.component.scss'],
  providers: [ClaimsService]
})
export class ClaimsSummaryComponent implements OnInit {
  public claims: ClaimList;
  public isBusy: false;
  public message: string;
  public maxSizeViewport = true;
  public innerWidth: number;
  public filterVoid = true;
  public rows: 10;
  public records: 9999;
  public cols: any;
  public selectedClaim: Claim;
  public voidFilter: string;
  // public authTemplate: AuthorizationSitecoreTemplate;

  // Expirement
  sortOptions: SelectItem[];
  sortKey: string;
  sortField: string;
  sortOrder: number;

  constructor(private claimsService: ClaimsService) {}

  ngOnInit() {
    this.cols = [
      { field: 'claim', header: 'claim #' },
      { field: 'billedAmount', header: 'billed amount' },
      { field: 'healthNetworkPlanName', header: 'health network' },
      { field: 'referringProvider', header: 'approved amount' },
      { field: 'referredProvider', header: 'member payable amount' },
      { field: 'providerName', header: 'provider name' },
      { field: 'serviceDate', header: 'service date' }
    ];

    this.sortOptions = [
      { label: 'Service Date - Newest', value: 'serviceDate' },
      { label: 'Service Date - Oldest', value: '!serviceDate' }
    ];

    this.setClaimsList();
  }

  private updateColumns() {
    // this.cols[0].header = this.authTemplate.ReferralHashLabel || 'referral #';
    // this.cols[1].header = this.authTemplate.ProcedureLabel || 'procedure';
    // this.cols[2].header = this.authTemplate.DateRequestedLabel || 'date requested';
    // this.cols[3].header = this.authTemplate.ReferringProviderLabel || 'referring provider';
    // this.cols[4].header = this.authTemplate.ReferredProviderLabel || 'referred provider';
    // this.cols[5].header = this.authTemplate.StatusLabel || 'provider name';
    // this.cols[6].header = this.authTemplate.ValidThruLabel || 'valid thru';
  }

  private setClaimsList() {
    this.claimsService
      .getClaims().pipe(
      finalize(() => (this.isBusy = false)))
      .subscribe(
        response => {
          this.claims = response;
        },
        error => (this.message = <any>error)
      );
  }

  onRowSelect(event) {
    // navigate to details
    console.log(event);
  }

  // Expirements
  public selectClaim(event: Event, claim: Claim) {
    this.selectedClaim = claim;
    event.preventDefault();
  }

  onSortChange(event) {
    const value = event.value;

    if (value.indexOf('!') === 0) {
      this.sortOrder = -1;
      this.sortField = value.substring(1, value.length);
    }
    else {
      this.sortOrder = 1;
      this.sortField = value;
    }
  }
}
