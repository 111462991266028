import { Injectable, Inject } from "@angular/core";
import {
  HttpClient,
  HttpParams,
  HttpHeaders,
  HttpResponse,
} from "@angular/common/http";

import { Observable } from "rxjs";
import { publishReplay, refCount, catchError } from "rxjs/operators";

import { OAuthService } from "angular-oauth2-oidc";

import { PrintMemberIdCardSitecoreTemplate } from "../print-member-id-card-sitecore-template";
import { MemberIdCard } from "../member-id-card";
import { Configuration } from "../../../app.constants";
import { HttpResponseHelper } from "../../../core/shared/helpers/http-response-helper";
import { LanguageService } from "../../../core/shared/services/language.service";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable()
export class PrintMemberIdCardService {
  private requestPrintMemberIdCardUrl: string;
  private requestDownloadMemberIdCardUrl: string;
  private memberIdCardServiceUrl;

  constructor(
    private http: HttpClient,
    private configuration: Configuration,
    private languageService: LanguageService
  ) {
    this.memberIdCardServiceUrl = configuration.MemberIdCardServiceUrl;
    this.requestPrintMemberIdCardUrl =
      configuration.RequestPrintMemberIdCardUrl;
    this.requestDownloadMemberIdCardUrl =
      configuration.RequestDownloadMemberIdCardUrl;
  }

  getmemberIdCardContent(): Observable<PrintMemberIdCardSitecoreTemplate> {
    return this.http
      .get<PrintMemberIdCardSitecoreTemplate>(
        this.languageService.getContentServiceUrlWithParameter(
          this.configuration.SitecoreServicePrintMemberIdCardParameter
        )
      )
      .pipe(
        publishReplay(1),
        refCount(),
        catchError((error) =>
          HttpResponseHelper.handleError(this.configuration, error)
        )
      );
  }

  getmemberIdCard(): Observable<MemberIdCard> {
    return this.http.get<MemberIdCard>(this.memberIdCardServiceUrl).pipe(
      publishReplay(1),
      refCount(),
      catchError((error) =>
        HttpResponseHelper.handleError(this.configuration, error)
      )
    );
  }

  setPrintMemberIdCardRequest(): Observable<boolean> {
    return this.http
      .post<boolean>(this.requestPrintMemberIdCardUrl, "")
      .pipe(
        catchError((error) =>
          HttpResponseHelper.handleError(this.configuration, error)
        )
      );
  }

  setDownloadMemberIdCardRequest(): Observable<boolean> {
    return this.http
      .post<boolean>(this.requestDownloadMemberIdCardUrl, "")
      .pipe(
        catchError((error) =>
          HttpResponseHelper.handleError(this.configuration, error)
        )
      );
  }
}
