

<div style="margin-top:50px;width: 100%;margin-left: 100px;height:60px;font-size: 1.3em;">
    You are being redirected, please wait...
</div>
<div style="margin-top:20px;width: 100%;margin-left: 100px;height:100px;font-size: 1.2em;">
    If you are not automatically redirected please click 
    <span> <a class="external-link" (click)="getRedirect()"> 
        here.  
    </a></span>
</div>

