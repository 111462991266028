export class ChangePCPChangeHealthNetworkSitecoreTemplate {
    public TitleLabel: string;
    public InstrusctionsLabel: string;
    public PromptLabel: string;
    public SubmitButtonText: string;
    public CancelButtonText: string;
    public SuccessMessageText: string;
    public ChangePCPRetroactiveDateMessageText: string;
    public ChangePCPEffectiveDateMessageText: string;
    public ChangePCPChangeHealthnetworkMedicalCCNMembersMessageText: string;
    public ChangePCPChangeHealthnetworkReasonForChangeLabel: string;
    public ChangePCPChangeHealthnetworkFuturePRSegmentMessage1: string;
    public ChangePCPChangeHealthnetworkFuturePRSegmentMessage2: string;
    public ErrorMessage: string;
    public ChangePCPChangeHealthnetworkOtherReasonForChangeLabel: string;
    public ChangePCPChangeHealthnetworkReasonForChangeErrorMessage: string;
    public ChangePCPChangeHealthnetworkOtherReasonForChangeErrorMessage: string;
    public SecondarySuccessMessageText: string;
    public KaiserSuccessMessage: string;
}