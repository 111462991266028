<div class="hdrow-md-12">
    <h2 id="lblHealthNetwork" name="lblHealthNetwork">{{changeHNContent?.HealthNetworkLabel || "health network"}}</h2>

    <div class="row">
        <div class="col-md-6 pcp-info-box">
            <label class="control-label" id="lblCalOptimaProgram" name="lblCalOptimaProgram">{{changeHNContent?.CalOptimaProgramLabel || "calOptima program"}}</label>
            <div id="lbllCalOptimaProgramValue" name="lbllCalOptimaProgramValue"> {{memberProfile?.LOB?.LobDisplayText}}</div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 pcp-info-box">
            <label
                class="control-label" id="lblHNphone" name="lblHNphone">{{changeHNContent?.HealthNetworkPhoneLabel || "health network telephone"}}</label>
            <div id="lblHNphoneValue" name="lblHNphoneValue"> {{healthNetworkPhone}}</div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 pcp-info-box">
            <label
                class="control-label" id=lblDesiredNetwork name=lblDesiredNetwork>{{changeHNContent?.HealthNetworkProviderLabel || "health network provider"}}</label>
            <div *ngIf="!showHealthNetworkDropDown()">{{memberProfile.HealthNetworkPlan.HealthNetworkPlanDisplayText}}
            </div>
            <select *ngIf="showHealthNetworkDropDown()" class="form-select" id="dropDesiredNetwork" name="dropDesiredNetwork"
                [compareWith]="compareHealthNetworkPlan" [(ngModel)]="selectedHealthNetwork" 
                (ngModelChange)="onNetworkChange($event)">
                <option [selected]="selectedHealthNetwork.HealthNetworkPlanCode==''"> {{changeHNContent?.Select}}</option>
                <option *ngFor="let network of healthNetworks" [ngValue]="network">
                    {{network?.HealthNetworkPlanDisplayText}}
                </option>
            </select>
        </div>
    </div>

    <div class="info-main-content-section-top" *ngIf="isKaiser()">
        <div class="row">
            <div class="col-md-6 pcp-info-box" *ngIf="showKaiser12Months">
                <label class="control-label" id = "lblKaiser12MonthsPast" name = "lblKaiser12MonthsPast">
                    {{changeHNContent?.KaiserLast12MonthsMessage || "have you been with kaiser in the last 12 months?"}}
                </label>
                <select class="form-select" id = "dropKaiser12MonthsPast" name = "dropKaiser12MonthsPast" (change)="onKaiser12MonthsChange($event.target.value)" ngModel
                    #withKaiserLast12Months="ngModel">
                    <option value="" selected>--- Select ---</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </select>
                <div class="alert alert-danger" *ngIf="withKaiserLast12Months.touched && !withKaiserLast12Months.valid">
                    <div *ngIf="withKaiserLast12Months.errors.required">please select an option</div>
                </div>
            </div>
        </div>


        <div class="row">
            <div class="col-md-6 pcp-info-box" *ngIf="showKaiserFriendsFamily">
                <label class="control-label">
                    {{changeHNContent?.KaiserFamilyMembersMessage || "do you have family members in kaiser?"}}
                </label>
                <select id="dropFamilyMembersInKaiser" name="familyMembersInKaiser" 
                class="form-select is-invalid" 
                [ngClass]="{'is-invalid': familyMembersInKaiser.touched && !familyMembersInKaiser.valid}"
                (change)="onKaiserFriendsFamilyChange($event.target.value)"
                    ngModel #familyMembersInKaiser="ngModel"  
                    >
                    <option value="" selected>--- Select ---</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </select>
                <label
                        class="invalid-feedback"
                        *ngIf="familyMembersInKaiser.touched && familyMembersInKaiser.invalid">
                            please select an answer
                </label>
                <!-- <div class="alert alert-danger" *ngIf="familyMembersInKaiser.touched && !familyMembersInKaiser.valid">
                    <div *ngIf="familyMembersInKaiser.errors.required">please select an option</div>
                </div> -->
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 pcp-info-box" *ngIf="selectedKaiserFriendsFamily">
                <label class="control-label">
                    {{changeHNContent?.KaiserMemberFirstNameLabel || "kaiser Member First Name *"}}
                </label>

                <input id="txtKaiserMemberFirstName" name="txtKaiserMemberFirstName" type="text" class="form-control" 
                    [ngClass]="{'is-invalid': kaiserMemberFirstNameInput.touched && kaiserMemberFirstNameInput.invalid}"
                    ngModel 
                    #kaiserMemberFirstNameInput="ngModel"
                    (change)="onKaiserMemberFirstNameChange($event.target.value)" 
                    required/>
                <label class="invalid-feedback"
                    *ngIf="kaiserMemberFirstNameInput.touched && kaiserMemberFirstNameInput.invalid">
                    {{changeHNContent?.KaiserMemberFirstNameValidation || "the Kaiser Member First Name you entered is invalid."}}
                </label>    
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 pcp-info-box" *ngIf="selectedKaiserFriendsFamily">
                <label class="control-label">
                    {{changeHNContent?.KaiserMemberLastNameLabel || "kaiser Member Last Name *"}}
                </label>

                <input id="txtKaiserMemberLastName" name="txtKaiserMemberLastName" type="text" class="form-control" 
                    [ngClass]="{'is-invalid': kaiserMemberLastNameInput.touched && kaiserMemberLastNameInput.invalid}"
                    ngModel 
                    #kaiserMemberLastNameInput="ngModel" (change)="onKaiserMemberLastNameChange($event.target.value)"
                    required />
                <label class="invalid-feedback"
                    *ngIf="kaiserMemberLastNameInput.touched && kaiserMemberLastNameInput.invalid">
                    {{changeHNContent?.KaiserMemberLastNameValidation || "the Kaiser Member Last Name you entered is invalid."}}
                </label> 
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 pcp-info-box" *ngIf="selectedKaiserFriendsFamily">
                <label class="control-label">
                    {{changeHNContent?.KaiserMemberRecordNumberLabel || "kaiser Member Record Number *"}}
                </label>

                <input id="txtKaiserMemberRecordNumber" name="txtKaiserMemberRecordNumber" type="text" class="form-control"
                    [ngClass]="{'is-invalid': kaiserMemberRecordNumberInput.touched && kaiserMemberRecordNumberInput.invalid}"
                    ngModel #kaiserMemberRecordNumberInput="ngModel" 
                    (change)="onKaiserMemberRecordNumberChange($event.target.value)" 
                    required/>
                <label class="invalid-feedback"
                    *ngIf="kaiserMemberRecordNumberInput.touched && kaiserMemberRecordNumberInput.invalid">
                    {{changeHNContent?.KaiserMemberRecordNumberValidation || "the Kaiser Member Record Number you entered is invalid."}}
                </label> 
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 pcp-info-box" *ngIf="selectedKaiserFriendsFamily">
                <label class="control-label">
                    {{changeHNContent?.RelationshipToKaiserMemberLabel || "relationship to Kaiser Member *"}}
                </label>
                <select class="form-select" id="dropKaiserMemberRelationship" name="dropKaiserMemberRelationship"
                    [ngClass]="{'is-invalid': relationshipToKaiserMemberControl.touched && relationshipToKaiserMemberControl.invalid}"
                    (change)="onRelationshipToKaiserMemberChange($event.target.value)"
                    ngModel #relationshipToKaiserMemberControl="ngModel"
                    required
                    >
                    <option value="" selected>--- Select ---</option>
                    <option>Parent</option>
                    <option>Spouse</option>
                    <option>Sibling</option>
                    <option>Stepparent</option>
                    <option>Foster Parent</option>
                    <option>Legal Guardian</option>
                    <option>Dependent Child under 21 years old</option>
                </select>
                <label class="invalid-feedback"
                    *ngIf="relationshipToKaiserMemberControl.touched && relationshipToKaiserMemberControl.invalid">
                    {{changeHNContent?.RelationshipToKaiserMemberValidation || "please select Relationship to Kaiser Member."}}
                </label> 
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 pcp-info-box" *ngIf="selectedKaiserFriendsFamily">
                <app-date-of-birth [dateOfBirthProperties]="kaiserMemberDobProperties"
                    (dateOfBirthEValue)="onKaiserMemberDobChanged($event)">
                </app-date-of-birth>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 pcp-info-box" *ngIf="selectedKaiserFriendsFamily">
                <label class="control-label">
                    {{changeHNContent?.KaiserCoverageRegionLabel || "kaiser Coverage Region *"}}
                </label>
                <select class="form-select" id="dropKaiserCoverageRegion" name="dropKaiserCoverageRegion"
                    [ngClass]="{'is-invalid': kaiserCoverageRegionControl.touched && kaiserCoverageRegionControl.invalid}"
                    ngModel #kaiserCoverageRegionControl="ngModel"
                    (change)="onKaiserCoverageRegionChanged($event.target.value)"
                    required>
                    <option value="" selected>--- Select ---</option>
                    <option>Southern CA</option>
                    <option>Northen CA</option>
                    <option>Colorado</option>
                    <option>Georgia</option>
                    <option>Hawaii</option>
                    <option>Mid-Atlantic</option>
                    <option>Northwest</option>
                </select>
                <label class="invalid-feedback"
                    *ngIf="kaiserCoverageRegionControl.touched && kaiserCoverageRegionControl.invalid">
                    {{changeHNContent?.KaiserCoverageRegionValidation || "please select Kaiser Coverage Region."}}
                </label> 
            </div>
        </div>

        <div class="info-main-content-section-top">
            <div class="alert alert-info" id="msgKaiserNotMeetEnrollmentCriteria" name="msgKaiserNotMeetEnrollmentCriteria" *ngIf="showNoKaiserMessage">
                <i class="fa-duotone fa-info-circle fa-lg" aria-hidden="true"></i>
                &nbsp;
                {{changeHNContent?.KaiserNotMeetEnrollmentCriteria || "we're sorry. you currently do not meet kaiser's enrollment criteria. please select another health network or contact Customer Service for additional questions."}}
            </div>
        </div>
        <div class="info-main-content-section-top">
            <div class="alert alert-info" id="msgKaiserSuccess" name="msgKaiserSuccess" *ngIf="showKaiserSuccessMessage">
                <i class="fa-duotone fa-info-circle fa-lg" aria-hidden="true"></i>
                &nbsp;
                {{changeHNContent?.KaiserSuccessMessage || "your health network change request to kaiser requires additional eligibility validation. caloptima customer service will be contacting you to obtain this information shortly in order to process your request."}}
            </div>
        </div>
        <div class="info-main-content-section-top">
            <div class="alert alert-info" id="msgChangePCPNotAllowedByNetwork" name="msgChangePCPNotAllowedByNetwork" *ngIf="showKaiserSuccessMessage">
                <i class="fa-duotone fa-info-circle fa-lg" aria-hidden="true"></i> &nbsp;
                {{changeHNContent.KaiserHealthNetworkLabel || 'we are sorry, but you belong to a plan that does not allow you to change your PCP.'}}
            </div>
        </div>
    </div>
    <p-dialog [(visible)]="showModalMessage"  [modal]="true" [baseZIndex]="10000" styleClass="modal-dialog-md"
        [draggable]="false" [resizable]="false" [focusOnShow]="true" [closable]="false">
        <p-header>
            {{changeHNContent?.NewPriorTitle || "health network provider"}}
        </p-header>
        <div style="text-align: left;  margin-left: 15px;margin-right: 15px;">
           <p> {{changeHNContent?.NewPriorAuthMessage || "if you currently have open authorizations, you will need to resubmit those to your new health care provider following a change in health network"}}</p>
        </div>
        <p-footer>
            <button ID="HealthNetworkBtn" (click)="showModalMessage=false"class="btn btn-primary" 
           >
            {{changeHNContent?.OkLabel || "ok"}}
            </button>
        </p-footer>
    </p-dialog>

</div>