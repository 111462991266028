import { Configuration } from '../../app.constants';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { MemberProfileService } from '../shared/services/member-profile.service';
import { MemberProfile } from '../shared/member-profile';
import { RequestIDCard } from '../shared/request-id-card';
import { RequestIDCardService } from '../shared/services/request-id-card.service';
import { RequestIDCardSitecoreTemplate } from '../shared/request-id-card-sitecore-template';
import { CustomerServiceResponse } from '../shared/customer-service-response';
import { CommonService } from '../../core/shared/services/common.service';
import { State } from '../../core/shared/state';
import { AddressViewProperties } from '../../core/shared/address-view-properties';
import { AddressComponent } from '../../core/shared/components/address/address.component';
import { Address } from '../shared/address';
import {ErrorSummaryComponent} from "../../core/error-summary/error-summary.component";
import { LanguageService, INotifyChange } from '../../core/shared/services/language.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-request-id-card',
    templateUrl: './request-id-card.component.html',
    providers: [RequestIDCardService, CommonService ],
    styleUrls: ['./request-id-card.component.scss']
})
export class RequestIdCardComponent implements OnInit, OnDestroy {

    public requestIdCardContent: RequestIDCardSitecoreTemplate = new RequestIDCardSitecoreTemplate();
    public memberProfile: MemberProfile = new MemberProfile();
    public requestIDCard: RequestIDCard = new RequestIDCard();
    private customerServiceResponse: CustomerServiceResponse = new CustomerServiceResponse();
    mailingAddress: AddressComponent = new AddressComponent(this.commonService);

    successMessage: string;
    errorMessage: string;

    showSuccessMessage: boolean = false;
    showKaiserMessage: boolean = false;
    IfMailToTempAddr: boolean = true;

    printFormats: any;

    selectedItem: string;
    states = new Array<State>();
    private subscription: Subscription;


    constructor(
        private memberProfileService: MemberProfileService,
        private requestIDCardService: RequestIDCardService,
        public configuration: Configuration,
        private commonService: CommonService,
        private languageService: LanguageService
    ) { }
    ngOnInit() {
        try {
          this.subscription = this.languageService.notifyObservable$.subscribe((change: INotifyChange) => {
            if(change.propName === 'languageCode'){
              this.onLoad();
            }
          });
        }
        catch (ex) {
          throw ex;
        }
      }
    
      ngOnDestroy() {
        this.subscription.unsubscribe();
      }
    
    onLoad() {
        try {

            this.IfMailToTempAddr = false;
            this.showSuccessMessage = false;
            this.requestIDCard.AlternateFormat = this.configuration.DefaultPrintFormatValue;
            this.getSitecorePrintFormats();
            this.states = this.commonService.getStates();

            this.requestIDCardService.getRequestIDCardContent()
                .subscribe(response => {
                    this.requestIdCardContent = response;
                },
                error => {
                    this.showErrorMessage(error.message);
                });

            this.memberProfileService.getMemberProfile()
                .subscribe(
                response => {
                    this.memberProfile = response;
                    this.requestIDCard.MemberCareOf = this.memberProfile.FirstName + ' ' + this.memberProfile.LastName;

                    if (this.isKaiserAndMediCal()) {
                        this.showKaiserMessage = true;
                    }
                    this.setMailingAddressView();
                    this.setMailingAddressData();
                },
                error => {
                    this.showErrorMessage(error.message);
                });
        }
        catch (ex) {
            this.showErrorMessage(ex.message);
            throw ex;
        }
    }
    getSitecorePrintFormats()
    {
        this.requestIDCardService.getSitecorePrintFormats()
                .subscribe(response => {
                    delete response[this.configuration.Version];
                    this.printFormats = response;
                },
                error => {
                    this.showErrorMessage(error.message);
                });
    }
    setMailingAddressView() {
        this.mailingAddress.properties = new AddressViewProperties();
        this.mailingAddress.properties.ReadOnly = true;
    }

    setMailingAddressData() {
        this.mailingAddress.address = new Address();
        this.mailingAddress.address.Address1 = this.memberProfile.MailingAddress.Address1;
        this.mailingAddress.address.Address2 = this.memberProfile.MailingAddress.Address2;
        this.mailingAddress.address.City = this.memberProfile.MailingAddress.City;
        this.mailingAddress.address.State = this.memberProfile.MailingAddress.State;
        this.mailingAddress.address.ZipCode = this.memberProfile.MailingAddress.ZipCode;
    }

    onSubmit() {
        try {
            this.errorMessage = '';
            this.showSuccessMessage = false;

            this.requestIDCardService.submitRequestIDCard(this.requestIDCard)
                .subscribe(response => {
                    this.customerServiceResponse = response;
                    this.showSuccessMessage = true;
                    if (this.customerServiceResponse != null && this.customerServiceResponse.RequestId != null) {
                        let successMsg =  this.requestIdCardContent.RequestSuccessMessage.replace(this.configuration.FXIRequestIDToken, this.customerServiceResponse.RequestId);

                        this.successMessage = successMsg;
                    }
                    else {
                        this.showErrorMessage(this.requestIdCardContent.RequestErrorMessage);
                    }
                },
                error => {
                    this.showErrorMessage(error.message);
                });
        }
        catch (ex) {
            this.showErrorMessage(ex.message);
            throw ex;
        }
        this.scrollToTopPage();
    }

    onChange() {
        this.IfMailToTempAddr = !this.IfMailToTempAddr;
        this.requestIDCard.IfTemporaryAddress = this.IfMailToTempAddr;
    }

    showErrorMessage(errorMessage: string) {
        this.errorMessage = errorMessage;
    }

    scrollToTopPage() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    isKaiserAndMediCal(): boolean {
        let result: boolean = false;
        if(this.memberProfile && this.memberProfile.HealthNetworkPlan && this.memberProfile.LOB && this.memberProfile.LOB.LOBCode)
        {
            if ((this.memberProfile.HealthNetworkPlan.HealthNetworkPlanCode?.toLocaleUpperCase() === this.configuration.KaiserCodeRegular
                || this.memberProfile.HealthNetworkPlan.HealthNetworkPlanCode?.toLocaleUpperCase() === this.configuration.KaiserCodeExpansion)
                && this.memberProfile.LOB.LOBCode.toUpperCase() === this.configuration.MediCalLOBCode.toUpperCase()) {
                result = true;
            }
        }

        return result;
    }

    resetVariables() {
        this.IfMailToTempAddr = false;
        this.requestIDCard.IfTemporaryAddress = false;
        this.showSuccessMessage = false;
        this.errorMessage = '';
        this.successMessage = '';
        this.requestIDCard.MemberCareOf = this.memberProfile.FirstName + ' ' + this.memberProfile.LastName;
        this.getSitecorePrintFormats();
        this.requestIDCard.AlternateFormat = this.configuration.DefaultPrintFormatValue;
    }
}
