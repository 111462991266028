export class Appointment {
    public Id: string;
    public Type: string;
    public ScheduledDate: Date;
    public Hour: string;
    public Minute: string;
    public AMPM: string;
    public Duration_hh: string;
    public Duration_mm: string;
    public ProviderName: string;
    public EventPhoneNumber: string;
    public ContactPerson: string;
    public EventAddress: string;
    public EventCity: string;
    public EventZipCode: string;
}
