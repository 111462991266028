
import { Component, OnInit, Pipe, OnDestroy } from '@angular/core';

import { HttpClient, HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { Configuration } from '../../app.constants';
import { MemberProfileSitecoreTemplate } from '../shared/member-profile-sitecore-template'
import { MemberProfile } from '../shared/member-profile'

import { MemberProfileService } from '../shared/services/member-profile.service';
import { EmailViewProperties } from '../../core/shared/email-view-properties';
import { PhoneComponent } from '../../core/shared/components/phone/phone.component';
import { PhoneViewProperties } from '../../core/shared/phone-view-properties';
import { AddressViewProperties } from '../../core/shared/address-view-properties';
import { AddressComponent } from '../../core/shared/components/address/address.component';
import { PCPInfoComponent } from '../../core/shared/components/pcp-info/pcp-info.component';
import { Address } from '../shared/address';
import { PCPInfoViewProperties } from '../../core/shared/pcp-info-view-properties';
import { PhonePipe } from '../../core/shared/pipes/phone.pipe';
import { CommonService } from '../../core/shared/services/common.service';
import {map, switchMap} from 'rxjs/operators'
import { LanguageService, INotifyChange } from '../../core/shared/services/language.service';
import { SubSink } from 'subsink';

@Component({
    templateUrl: './member-profile.component.html',
    providers: [Configuration, CommonService],
    styleUrls: ['./member-profile.component.scss']
})
export class MemberProfileComponent implements OnInit, OnDestroy {
    memberProfileContent: MemberProfileSitecoreTemplate = new MemberProfileSitecoreTemplate();
    memberProfile: MemberProfile = new MemberProfile();
    emailViewProperties: EmailViewProperties = new EmailViewProperties();
    phoneViewProperties: PhoneViewProperties = new PhoneViewProperties();
    mobilePhoneViewProperties: PhoneViewProperties = new PhoneViewProperties();
    primaryAddress: AddressComponent = new AddressComponent(this.commonService);
    mailingAddress: AddressComponent = new AddressComponent(this.commonService);
    pcpInfo: PCPInfoComponent = new PCPInfoComponent(this.configuration, this.commonService);
    TCPAOptIn:boolean = true;
    private subscriptions = new SubSink();

    constructor(
        private memberProfileService: MemberProfileService,
        public configuration: Configuration,
        private commonService: CommonService,
        private languageService: LanguageService
       )

     { }

    public message: string
    
    ngOnInit() {
        try {
    
          this.subscriptions.sink = combineLatest([
            this.languageService.notifyObservable$,
            this.memberProfileService.notifyObservable$
          ]).subscribe(result => {
              this.memberProfile = this.memberProfileService.memberProfile;
              if (this.memberProfile) this.onLoad();
            });
    
        } catch (ex) {
          throw ex;
        }
      }

      ngOnDestroy() {
        this.subscriptions.unsubscribe();
      }
    
    onLoad() {
        try {

            this.memberProfileService.getMemberProfileContent()
                .subscribe(
                response => {
                    this.memberProfileContent = response;
                    this.loadChildComponents();
                    this.loadTCPA();
                })
        }
        catch (ex) {
            this.message = ex;
            throw ex;
        }
    }

    loadTCPA()
    {
         
         if (this.memberProfile?.TCPAValue === 135)
         {
            this.TCPAOptIn = false;
         }
         else if (this.memberProfile?.TCPAValue === 133)
         {
            this.TCPAOptIn = true;
         }
         else
         {
            this.TCPAOptIn = null;
         }
    }

    loadChildComponents() {
        
        this.setMailingAddressView();
        this.setPrimaryAddressView();        
        this.setPCPInfoView();
        this.setPrimaryPhoneView();
        this.setMobilePhoneView();
        this.setEmailView();

        this.setPrimaryAddressData();
        this.setMailingAddressData();
        this.setPCPInfoData();
    }

    // Redirect to SecureAuth (IdP) Self Service realm 
    public redirectToUserProfile() {
        window.location.href = this.configuration.UserProfileUrl;
    }

    public redirectToUpdateUserProfile() {
        window.location.href = this.configuration.UserProfileUrl;
    }

    get memberFullName() {
        if (this.memberProfile.FirstName) {
            return this.memberProfileService.getMemberFullName(this.memberProfile);
        }
    }

    setEmailView() {
        this.emailViewProperties.ReadOnly = true;
        this.emailViewProperties.Title = this.memberProfileContent.EmailAddressHeaderText;
        this.emailViewProperties.FieldLabel = this.memberProfileContent.EmailLabel;
    }

    setMobilePhoneView() {
        this.mobilePhoneViewProperties.ReadOnly = true;
        this.mobilePhoneViewProperties.Title = this.memberProfileContent.MobilePhoneHeaderText;
        this.mobilePhoneViewProperties.FieldLabel = this.memberProfileContent.MobilePhoneNumberLabel;
    }

    setPrimaryPhoneView() {
        this.phoneViewProperties.ReadOnly = true;
        this.phoneViewProperties.Title = this.memberProfileContent.PrimaryPhoneHeaderText;
        this.phoneViewProperties.FieldLabel = this.memberProfileContent.PrimaryPhoneNumberLabel;
    }

    setPCPInfoView() {
        this.pcpInfo.properties = new PCPInfoViewProperties();
        this.pcpInfo.properties.AddressLine1LabelText = this.memberProfileContent.PCPAddress1Label;
        this.pcpInfo.properties.AddressLine2LabelText = this.memberProfileContent.PCPAddress2Label;
        this.pcpInfo.properties.CityLabelText = this.memberProfileContent.PCPAddressCityLabel;
        this.pcpInfo.properties.StateLabelText = this.memberProfileContent.PCPAddressStateLabel;
        this.pcpInfo.properties.ZipCodeLabelText = this.memberProfileContent.PCPAddressZipCodeLabel;
        this.pcpInfo.properties.PhoneNumber = this.memberProfileContent.PCPPhoneLabel;
        this.pcpInfo.properties.PCPNameLabelText = this.memberProfileContent.PCPNameLabel;
        this.pcpInfo.properties.HeaderText = this.memberProfileContent.PCPInfoHeader;
    }

    setPCPInfoData() {
        let phonePipe = new PhonePipe();
        let phoneValue = phonePipe.transform(this.memberProfile.PrimaryCareProviderInfo.PhoneNumber, "");
        this.pcpInfo.provider.LOBCode = this.memberProfile.LOB.LOBCode;
        
        this.pcpInfo.provider.ProviderId = this.memberProfile.PrimaryCareProviderInfo.ProviderId;
        this.pcpInfo.provider.ProviderName = this.memberProfile.PrimaryCareProviderInfo.ProviderName;
        this.pcpInfo.provider.PhoneNumber = phoneValue;
        if (this.memberProfile.PrimaryCareProviderInfo.Address != null) {
            this.pcpInfo.provider.AddressInfo.Address1 = this.memberProfile.PrimaryCareProviderInfo.Address.Address1;
            this.pcpInfo.provider.AddressInfo.Address2 = this.memberProfile.PrimaryCareProviderInfo.Address.Address2;
            this.pcpInfo.provider.AddressInfo.City = this.memberProfile.PrimaryCareProviderInfo.Address.City;
            this.pcpInfo.provider.AddressInfo.State = this.memberProfile.PrimaryCareProviderInfo.Address.State;
            this.pcpInfo.provider.AddressInfo.ZipCode = this.memberProfile.PrimaryCareProviderInfo.Address.ZipCode;
        }
    }

    setPrimaryAddressView() {

        this.primaryAddress.properties = new AddressViewProperties();
        this.primaryAddress.properties.HeaderText = this.memberProfileContent.PrimaryAddressHeaderText;
        this.primaryAddress.properties.AddressLine1LabelText = this.memberProfileContent.PrimaryAddress1Label;
        this.primaryAddress.properties.CityLabelText = this.memberProfileContent.PrimaryAddressCityLabel;
        this.primaryAddress.properties.StateLabelText = this.memberProfileContent.PrimaryAddressStateLabel;
        this.primaryAddress.properties.ZipCodeLabelText = this.memberProfileContent.PrimaryAddressZipCodeLabel;
        this.primaryAddress.properties.ReadOnly = true;

    }

    setPrimaryAddressData() {

        this.primaryAddress.address = new Address();
        this.primaryAddress.address.Address1 = this.memberProfile.HomeAddress.Address1;
        this.primaryAddress.address.Address2 = this.memberProfile.HomeAddress.Address2;
        this.primaryAddress.address.City = this.memberProfile.HomeAddress.City;
        this.primaryAddress.address.State = this.memberProfile.HomeAddress.State;
        this.primaryAddress.address.ZipCode = this.memberProfile.HomeAddress.ZipCode;
    }

    setMailingAddressView() {
        this.mailingAddress.properties = new AddressViewProperties();
        this.mailingAddress.properties.HeaderText = this.memberProfileContent.MailingAddressHeaderText;
        this.mailingAddress.properties.AddressLine1LabelText = this.memberProfileContent.MailingAddress1Label;
        this.mailingAddress.properties.CityLabelText = this.memberProfileContent.MailingAddressCityLabel;
        this.mailingAddress.properties.StateLabelText = this.memberProfileContent.MailingAddressStateLabel;
        this.mailingAddress.properties.ZipCodeLabelText = this.memberProfileContent.MailingAddressZipCodeLabel;
        this.mailingAddress.properties.ReadOnly = true;
    }

    setMailingAddressData() {
        
        this.mailingAddress.address = new Address();
        this.mailingAddress.address.Address1 = this.memberProfile.MailingAddress.Address1;
        this.mailingAddress.address.Address2 = this.memberProfile.MailingAddress.Address2;
        this.mailingAddress.address.City = this.memberProfile.MailingAddress.City;
        this.mailingAddress.address.State = this.memberProfile.MailingAddress.State;
        this.mailingAddress.address.ZipCode = this.memberProfile.MailingAddress.ZipCode;
    }
}
