import { ProcedureService } from './procedure-service';
import { Diagnosis } from './diagnosis';
import { ProviderDetailInfo } from './ProviderDetailInfo';
import { RefAuthNote } from './referral-auth-note';
import { ProviderAddressInfo } from './provider-detail-info';

export class Referral {
  ReferralId: string;
  VendorReferralId: number;
  ReferralType: string;
  ReferralStatus: string;
  MemberName: string;
  MemberCIN: string;
  GrgR_CK: number;
  Lob: string;
  HealthNetworkPlanCode: string;
  HealthNetworkPlanDesc: string;
  PcpName: string;
  Priority: string;
  PlaceOfService: string;
  PlaceOfServiceDesc: string;
  AuthorizationType: string;
  ServiceBeginDate: Date;
  ServiceEndDate: Date;
  DateReceived: Date;
  DateRequested: Date;
  DateDecision: Date;
  FacilityNPI: string;
  AdmittingProvider: ProviderDetailInfo;
  RefAuthNotes: RefAuthNote[];
  ReferringProvider: ProviderDetailInfo;
  ReferredProvider: ProviderDetailInfo;
  ReferralDiagnoses: Diagnosis[];
  ReferralProcedures: ProcedureService[];
  RequestSource: string;
  StatusReasonDesc:string;
  ReferredProviderAddress: ProviderAddressInfo;
  ReferringProviderAddress: ProviderAddressInfo;
}
