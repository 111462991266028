import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpParams,
  HttpHeaders,
  HttpResponse,
} from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, publishReplay, refCount } from "rxjs/operators";

import { ChangeHnSitecoreTemplate } from "../change-hn-sitecore-template";
import { Configuration } from "../../../app.constants";
// import { HealthNetwork } from '../health-network';
import { MemberProfile } from "../member-profile";
import { HealthNetworkPlan } from "../health-network-plan";
import { MemberProfileService } from "./member-profile.service";
import { RoutingHelper } from "../../../core/shared/helpers/routing-helper";
import { HttpResponseHelper } from "../../../core/shared/helpers/http-response-helper";
import { LanguageService } from "../../../core/shared/services/language.service";

@Injectable()
export class ChangeHealthNetworkService {
  private providerServiceUrl: string;
  private getHealthNetworkServiceUrl: string;
  private checkProviderHealthNetworkUrl: string;

  constructor(
    private http: HttpClient,
    private configuration: Configuration,
    private memberProfileService: MemberProfileService,
    private routingHelper: RoutingHelper,
    private languageService: LanguageService
  ) {
    this.providerServiceUrl = configuration.ProviderServiceUrl;
    this.getHealthNetworkServiceUrl = configuration.GetHealthNetworkServiceUrl;
    this.checkProviderHealthNetworkUrl =
      configuration.CheckProviderHealthNetworkUrl;
  }

  getChangeHealthNetworkSitecoreContent(): Observable<ChangeHnSitecoreTemplate> {
    try {
      return this.http
        .get<ChangeHnSitecoreTemplate>(
          this.languageService.getContentServiceUrlWithParameter(
            this.configuration.ChangeHealthNetworkParameter
          )
        )
        .pipe(
          publishReplay(1),
          refCount(),
          catchError((error) =>
            HttpResponseHelper.handleError(this.configuration, error)
          )
        );
    } catch (ex) {
      // log error
      return null;
    }
  }

  getHealthNetworks(): Observable<HealthNetworkPlan[]> {
    try {
      return this.http
        .get<HealthNetworkPlan[]>(this.getHealthNetworkServiceUrl)
        .pipe(
          publishReplay(1),
          refCount(),
          catchError((error) =>
            HttpResponseHelper.handleError(this.configuration, error)
          )
        );
    } catch (ex) {
      return null;
    }
  }

  getProviderSearchUrl(providerId: string, healthNetworkCode: string): string {
    try {
      let url: string;
      let queryParams = [
        {
          keyName: this.configuration.HealthNetworkQueryParamName,
          keyValue: healthNetworkCode,
        },
      ];
      return url;
    } catch (ex) {
      // log error
      return null;
    }
  }

  isCurrentPcpInHN(
    selectedHealthNetworkPlanCode: string,
    memberProfile: MemberProfile
  ): Observable<boolean> {
    try {
      let params: HttpParams = new HttpParams();

      params.set(
        this.configuration.IdQueryParamName,
        String(memberProfile.PrimaryCareProviderInfo.ProviderId)
      );
      params.set(
        this.configuration.MemberGroupIdParamName,
        String(memberProfile.GroupId)
      );
      params.set(
        this.configuration.MemberSubGroupIdParamName,
        String(memberProfile.SubGroupId)
      );
      params.set(
        this.configuration.LobQueryParamName,
        String(memberProfile.LOB.LOBCode.toUpperCase())
      );
      params.set(
        this.configuration.AgeQueryParamName,
        String(this.memberProfileService.getMemberAge(memberProfile))
      );
      params.set(
        this.configuration.HealthNetworkPlanCodeQueryParamName,
        String(selectedHealthNetworkPlanCode)
      );

      return this.http
        .get<boolean>(this.checkProviderHealthNetworkUrl, { params: params })
        .pipe(
          catchError((error) =>
            HttpResponseHelper.handleError(this.configuration, error)
          )
        );
    } catch (ex) {
      // log error
      return of(false);
    }
  }
}
