<div class="nohdrow-md-12">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="panel-title">
                <h4> 
                    <a *ngIf="showCollapsibleButton" href="#collapse1" (click)="toggleSearchPanel();">
                        <i class="{{fontAwesomeArrow}}"></i> &nbsp;{{searchForAProviderSitecoreTemplate?.SearchForAPCPLabel||'search for a primary care provider'}}
                    </a>
                </h4>
            </div>
        </div>
        <div class="panel-body" id="collapse1" [ngClass]="{'panel-collapse collapse in':!collapseSearch,'panel-collapse collapse':collapseSearch}">
            <h4>{{searchForAProviderSitecoreTemplate.ProviderLocatedQuestion||'where do You Want the Provider to be Located?'}}</h4> 
            <div class="row form-row-override last">

                <div class="col-md-4 border">
                    <div class="form-group">
                        <label class="control-label">
                            {{searchForAProviderSitecoreTemplate.CityLabel|| 'city'}}
                        </label>
                        <input type="text" 
                            class="form-control"
                            id="City"
                            name="City"
                            [(ngModel)]="providerFilter.City" />
                    </div>
                </div>

                <div class="col-md-4 border">
                    <div class="form-group">
                        <label class="control-label">
                            {{searchForAProviderSitecoreTemplate.ZipCodeLabel || 'zip code'}}
                        </label>
                        <input type="text" class="form-control"
                               [ngModel]="providerFilter.ZipCode|zipcode" #zipcode="ngModel"
                               name="ZipCode"
                               id="ZipCode" 
                               text="memberProfile.HomeAddress.ZipCode"
                               pattern="^[0-9]{5}(?:-[0-9]{4})?$"
                               (ngModelChange)="providerFilter.ZipCode=$event"/>
                    </div>
                </div>
                <div class="col-md-4 border">
                    <div class="form-group">
                        <label class="control-label">
                            {{searchForAProviderSitecoreTemplate.WithinLabel||'within'}}
                        </label>
                        <select class="form-control"
                                [(ngModel)]="providerFilter.Distance"
                                name="Distance"
                                id="Distance">
                            <option value="1">1&nbsp;{{searchForAProviderSitecoreTemplate.MilesLabel||'miles'}}</option>
                            <option value="3">3&nbsp;{{searchForAProviderSitecoreTemplate.MilesLabel||'miles'}}</option>
                            <option value="5">5&nbsp;{{searchForAProviderSitecoreTemplate.MilesLabel||'miles'}}</option>
                            <option value="10">10&nbsp;{{searchForAProviderSitecoreTemplate.MilesLabel||'miles'}}</option>
                            <option value="20">20&nbsp;{{searchForAProviderSitecoreTemplate.MilesLabel||'miles'}}</option>
                            <option selected value="20+">20+&nbsp;{{searchForAProviderSitecoreTemplate.MilesLabel||'miles'}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <h4>{{searchForAProviderSitecoreTemplate?.ProviderDetailsLabel||'provider Details (Optional, to Narrow Your Search)'}}</h4>
            <div class="row form-row-override last">
                <div class="col-md-4 border">
                    <div class="form-group">
                        <label class="control-label">
                            {{searchForAProviderSitecoreTemplate.LastNameLabel ||'last name / clinic name'}}
                        </label>
                        <input type="text" class="form-control" [(ngModel)]="providerFilter.LastName" />
                    </div>
                </div>
                <div class="col-md-4 border">
                    <div class="form-group">
                        <label class="control-label">
                            {{searchForAProviderSitecoreTemplate.FirstNameLabel ||'first name'}}
                        </label>

                        <input type="text" class="form-control" 
                            id="FirstName"
                            name="FirstName"
                            [(ngModel)]="providerFilter.FirstName" />
                    </div>
                </div>
                <div class="col-md-4 border">
                    <div class="form-group">
                        <label class="control-label">
                            {{searchForAProviderSitecoreTemplate.GenderLabel ||'gender'}}
                        </label>
                        <select class="form-control" 
                            id="ProvGender"
                            name="ProvGender"
                            [(ngModel)]="providerFilter.ProvGender">
                            <option>Any</option>
                            <option>Male</option>
                            <option>Female</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row form-row-override last">
                <div class="col-md-6 border">
                    <div class="form-group">
                        <label class="control-label">
                            {{searchForAProviderSitecoreTemplate.MedicalGroupAffiliationsLabel||'medical group affiliations'}}
                        </label>
                        <input type="text" class="form-control"
                               [(ngModel)]="providerFilter.MedicalGroups"
                               name="MedicalGroups"
                               id="MedicalGroups" />
                    </div>
                </div>
                <div class="col-md-6 border">
                    <div class="form-group">
                        <label class="control-label">
                            {{searchForAProviderSitecoreTemplate.LanguageLabel ||'language'}}
                        </label>
                        <select class="form-control"
                                [(ngModel)]="providerFilter.LanguageText"
                                name="LanguageText"
                                id="LanguageText">
                            <option>Any</option>
                            <option *ngFor="let language of languages" [ngValue]="language.Description">
                                {{language.Description}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row form-row-override last">
                <div class="col-md-12 border">
                    <div class="form-group">
                        <label class="control-label">
                            {{searchForAProviderSitecoreTemplate.FacilityAffiliationsLabel ||'facility affiliations'}}
                        </label>
                        <select class="form-control" 
                            name="Hospitals" 
                            id="Hospitals"
                            [(ngModel)]="providerFilter.Hospitals">
                            <option>Any</option>
                            <option *ngFor="let hospital of hospitalAffilitaions" [ngValue]="hospital.Description">
                                {{hospital.Description}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row form-row-override last">
                <div class="col-md-12 border">
                    <div class="form-group">
                        <label class="control-label">
                            {{searchForAProviderSitecoreTemplate.SpecialtyLabel ||'specialty'}}
                        </label>
                        <select class="form-control" 
                            name="PracPrimSpec" 
                            id="PracPrimSpec" 
                            [(ngModel)]="providerFilter.PracPrimSpec">
                            <option>Any</option>
                            <option *ngFor="let specialty of providerSpecialties" [ngValue]="specialty.Description">
                                {{specialty.Description}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 text-center">
                    <button class="btn btn-link" (click)="resetSearchCriteria()">{{searchForAProviderSitecoreTemplate.CancelButtonText ||'cancel'}}</button>
                    <button data-bs-toggle="collapse" class="btn btn-primary" type="submit" (click)=" onClick($event); collapseSearch=true; ">
                        {{searchForAProviderSitecoreTemplate.SearchButtonText ||'search'}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
