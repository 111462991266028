export class ProviderSearchParameter {
    public LOB: string;
    public ProviderId: string;
    public FirstName: string;
    public LastName: string;
    public TIN: string;
    public NPI: string;
    public LanguageText: string;
    public ProvGender: string;
    public City: string;
    public ZipCode: string;
    public UserProximity: boolean;
    public IsPCP: boolean;
    public Distance: string;
    public PracPrimSpec: string;
    public BoardCert: string;
    public TargetHealthNetwork: string;
    public ContractedServices: string;
    public SpecialPrograms: string;
    public AcceptingNewPatients: string;
    public Hospitals: string;
    public MedicalGroups: string;
    public MemberVipCode: string;
    public MemberBirthDate: Date;
    public MemberGender: string;
    public MemberSubGroupKey: number;
    public CSPI_ID: string;
    public PRCR_ID: string;
    public Network: string;
    public CSCS_ID: string;
    public ChangeEffectiveDate: Date;
}
