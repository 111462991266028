export class AuthorizationDetailsSitecoreTemplate {
    public AuthorizationDetailLabel: string;
    public DisclaimerMessage: string;
    public ReferralNumberLabel: string;
    public StatusLabel: string;
    public PriorityLabel: string;
    public DateRequestedLabel: string;
    public DeterminedOnLabel: string;
    public ValidThruLabel: string;
    public ReferringProviderLabel: string;
    public ReferredProviderLabel: string;
    public ProviderIDLabel: string;
    public ProviderNameLabel: string;
    public SpecialtyLabel: string;
    public AddressLabel: string;
    public PhoneLabel: string;
    public FaxLabel: string;
    public ServicesLabel: string;
    public StatusCol: string;
    public ServiceCol: string;
    public ModifierCol: string;
    public DecisionCol: string;
    public DescriptionCol: string;
    public DurationCol: string;
    public ServiceUnitsCol: string;
    public ApprovedUnitsCol: string;
    public DiagnosisLabel: string;
    public CodeCol: string;
    public ClinicalIndicationsLabel: string;
    public BackBtn: string;
    public NotAvailableMessage: string;
}