import { Component, OnInit, OnDestroy } from "@angular/core";
import { EmailComponent } from "../../core/shared/components/email/email.component";
import { AddressComponent } from "../../core/shared/components/address/address.component";
import { AddressViewProperties } from "../../core/shared/address-view-properties";
import { combineLatest, Observable, Subscription } from "rxjs";
import {
  NgForm,
  NgControl,
  Validators,
  FormBuilder,
  FormControl,
} from "@angular/forms";
import { map, switchMap } from "rxjs/operators";

import { UppercaseInputDirective } from "../../core/shared/directives/uppercase-input.directive";
import { Configuration } from "../../app.constants";
import { MemberProfile } from "../shared/member-profile";
import { Address } from "../shared/address";
import { UpdateMemberProfileSitecoreTemplate } from "../shared/update-member-profile-sitecore-template";
import { MemberProfileService } from "../shared/services/member-profile.service";
import { CustomerServiceResponse } from "../shared/customer-service-response";
import { EmailService } from "../../core/shared/services/email.service";
import { MemberProfileSection } from "../shared/member-profile-section";
import { UpdateMemberProfileRequest } from "../shared/update-member-profile-request";
import { PhoneComponent } from "../../core/shared/components/phone/phone.component";
import { PhoneViewProperties } from "../../core/shared/phone-view-properties";
import { PhoneValidationMessages } from "../../core/shared/phone-validation-messages";
import { MemberLanguages } from "../shared/member-languages";
import { EmailViewProperties } from "../../core/shared/email-view-properties";
import { EmailValidationMessages } from "../../core/shared/email-validation-messages";
import { AddressValidationMessages } from "../../core/shared/address-validation-messages";
import { CommonService } from "../../core/shared/services/common.service";
import {
  LanguageService,
  INotifyChange,
} from "../../core/shared/services/language.service";
import { TCPUpdate } from "../shared/TCPUpdate";
import { SubSink } from "subsink";
@Component({
  selector: "app-update-member-profile",
  templateUrl: "./update-member-profile.component.html",
  styleUrls: ["./update-member-profile.component.scss"],
  providers: [CommonService],
})
export class UpdateMemberProfileComponent implements OnInit, OnDestroy {
  memberProfile: MemberProfile = new MemberProfile();
  updateMemberProfileSitecoreTemplate =
    new UpdateMemberProfileSitecoreTemplate();
  message: string;
  successMessage: string;
  errorMessage: string;
  // Default value for SSA Consent is true
  HasGivenSSAConsentForPrimaryPhone: boolean = false;
  isLanguagePreferenceChanged: boolean = false;
  TCPAOptIn: boolean;
  orgTCPAOptIn: boolean;
  maxNumberOfCharacters = this.configuration.AddressLinesMaxNumberOfCharacters;
  cityMaxNumberOfCharacters = this.configuration.CityMaxNumberOfCharacters;
  isMediCalPrimaryAndMailingAddressChangeAllowed: boolean = true;
  isBCCTPMember: boolean = false;
  customerServiceResponse: CustomerServiceResponse;
  showPrimaryAddressForwardMessage: boolean = false;
  showPrimaryAddressOneCareInCountyMessage: boolean = false;
  showPrimaryAddressOneCareConnectInCountyMessage: boolean = false;
  showPrimaryAddressOneCareOutOfCountyMessage: boolean = false;
  showPrimaryAddressOneCareConnectOutOfCountyMessage: boolean = false;
  showPrimaryAddressMediCalOutOfCountyMessage: boolean = false;
  showMailingAddressOneCareInCountyMessage: boolean = false;
  showMailingAddressOneCareConnectInCountyMessage: boolean = false;
  showPhoneNumberBCCTPMessage: boolean = false;
  showPhoneNumberAidCodeMessage: boolean = false;
  showPrimaryPhoneNumberMessage: boolean = false;
  showBCCTPPrimaryAddressMessage: boolean = false;
  showAgedBlindDisabledPrimaryAddressMessage: boolean = false;
  showSuccessMessage: boolean = true;
  showTCPASubmissionLimitMessage: boolean = false;
  preferredLanguageSupport: boolean = true;
  primaryAddress: AddressComponent = new AddressComponent(this.commonService);
  mailingAddress: AddressComponent = new AddressComponent(this.commonService);
  memberLanguagesList: Array<MemberLanguages>;
  alternateMemberLanguagesList: Array<MemberLanguages>;
  emailViewProperties: EmailViewProperties = new EmailViewProperties();
  phoneViewProperties: PhoneViewProperties = new PhoneViewProperties();
  mobilePhoneViewProperties: PhoneViewProperties = new PhoneViewProperties();
  primaryPhoneValidationMessages: PhoneValidationMessages =
    new PhoneValidationMessages();
  mobilePhoneValidationMessages: PhoneValidationMessages =
    new PhoneValidationMessages();
  emailValidationMessages: EmailValidationMessages =
    new EmailValidationMessages();
  allowPhoneContact: boolean = true;
  readOnlyTCPA: boolean = true;
  preferredWrittenLanguage = new MemberLanguages();
  preferredSpokenLanguage = new MemberLanguages();

  private subscriptions = new SubSink();

  constructor(
    private memberProfileService: MemberProfileService,
    private emailService: EmailService,
    private configuration: Configuration,
    private commonService: CommonService,
    private languageService: LanguageService
  ) {}

  ngOnInit() {
    try {
      this.subscriptions.sink = combineLatest([
        this.languageService.notifyObservable$,
        this.memberProfileService.notifyObservable$,
      ]).subscribe((result) => {
        this.memberProfile = this.memberProfileService.memberProfile;
        if (this.memberProfile) this.onLoad();
      });
    } catch (ex) {
      throw ex;
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onLoad() {
    this.loadView();
    window.scrollTo(0, 0);
  }

  loadView() {
    this.memberProfileService.getUpdateMemberProfileContent().subscribe(
      (response) => {
        this.updateMemberProfileSitecoreTemplate = response;
        this.loadChildComponentsView();
        this.loadData();
      },
      (error) => {
        this.showErrorMessage(error.message);
      }
    );
  }

  loadChildComponentsView() {
    // Load Primary Address
    this.loadPrimaryAddressView();

    // Load Mailing Address
    this.loadMailingAddressView();

    // Load Email
    this.emailViewProperties.Title =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfileEmailPanelTitle;

    this.emailViewProperties.FieldLabel =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfileEmailLabel;

    this.emailViewProperties.SubmitButtonText =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfileSubmitButtonText;

    this.emailViewProperties.CancelButtonText =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfileClearButtonText;

    this.emailValidationMessages.RequiredMessage =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfileEmailRequiredError;

    this.emailValidationMessages.MaxLengthMessage =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfileEmailExceededNumberOfCharactersError;

    this.emailValidationMessages.InvalidInputMessage =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfileEmailInvalidError;

    // Load Phone
    this.phoneViewProperties.Title =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfilePrimaryPhoneNumberPanelTitle;

    this.phoneViewProperties.FieldLabel =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfilePhoneNumberPrimaryPhoneNumberLabel;

    this.phoneViewProperties.SubmitButtonText =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfileSubmitButtonText;

    this.phoneViewProperties.CancelButtonText =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfileClearButtonText;
    // this.checkMediCalRestrictionsForPhoneView();
    this.phoneViewProperties.CheckboxText =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfilePrimaryPhoneSSAConsentText;

    this.primaryPhoneValidationMessages.RequiredMessage =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfilePrimaryPhoneNumberRequiredError;

    this.primaryPhoneValidationMessages.MaxLengthMessage =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfilePrimaryPhoneNumberExceededNumberOfCharactersError;

    this.primaryPhoneValidationMessages.InvalidInputMessage =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfilePrimaryPhoneNumberInvalidError;

    // Load Mobile Phone
    this.mobilePhoneViewProperties.Title =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfileMobilePhoneNumberPanelTitle;

    this.mobilePhoneViewProperties.FieldLabel =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfileMobileNumberLabel;

    this.mobilePhoneViewProperties.SubmitButtonText =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfileSubmitButtonText;

    this.mobilePhoneViewProperties.CancelButtonText =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfileClearButtonText;

    this.mobilePhoneValidationMessages.RequiredMessage =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfileMobilePhoneNumberRequiredError;

    this.mobilePhoneValidationMessages.MaxLengthMessage =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfileMobileNumberExceededNumberOfCharactersError;

    this.mobilePhoneValidationMessages.InvalidInputMessage =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfileMobilePhoneNumberInvalidError;
  }

  loadData() {
    this.loadChildComponentsDataAndRestrictions();
    this.loadTCPA();
    this.getMemberLanguages();
    this.resetLanguagePreferences();
  }

  loadTCPA() {
    this.readOnlyTCPA = this.memberProfile.TCPAShowSubmissionLimitMessage;
    if (this.memberProfile?.TCPAValue === 135) {
      this.orgTCPAOptIn = false;
      this.TCPAOptIn = false;
    } else if (this.memberProfile?.TCPAValue === -1) {
      this.orgTCPAOptIn = null;
      this.TCPAOptIn = null;
    } else if (this.memberProfile?.TCPAValue === 133) {
      this.orgTCPAOptIn = true;
      this.TCPAOptIn = true;
    } else {
      this.orgTCPAOptIn = null;
      this.TCPAOptIn = null;
    }
  }
  loadChildComponentsDataAndRestrictions() {
    this.loadPrimaryAddressData();
    this.loadMailingAddresData();
    this.loadRestrictions();
    this.checkMediCalRestrictionsForPhoneView();
    this.checkForPreferredLanguageSupport();
  }

  loadRestrictions() {
    this.checkSafeAtHomeRestriction();
  }

  checkMediCalRestrictionsForPhoneView() {
    if (
      ((this.memberProfile.LOB.LOBCode === this.configuration.MediCalLOBCode &&
        !this.memberProfile.HasBCCTPAidCode &&
        !this.memberProfile.HasRestrictedAidCode) ||
        this.memberProfile.LOB.LOBCode === this.configuration.OneCareLOBCode ||
        this.memberProfile.LOB.LOBCode ===
          this.configuration.OneCareConnectLOBCode) &&
      (this.memberProfile.HomeAddress.IsSafeAtHomeAddress !== true ||
        this.memberProfile.MailingAddress.IsSafeAtHomeAddress !== true)
    ) {
      this.phoneViewProperties.ShowCheckbox = true;
      this.HasGivenSSAConsentForPrimaryPhone = true;
    }
  }

  checkForPreferredLanguageSupport() {
    if (
      this.memberProfile.LOB.LOBCode === this.configuration.MediCalLOBCode &&
      !this.memberProfile.HealthNetworkPlan.HealthNetworkCode.startsWith(
        "COD"
      ) && // NOT CCN or COD
      !this.memberProfile.HealthNetworkPlan.HealthNetworkCode.startsWith("CCN")
    ) {
      this.preferredLanguageSupport = false;
    }
  }

  checkSafeAtHomeRestriction() {
    if (
      this.memberProfile.HomeAddress.IsSafeAtHomeAddress === true ||
      this.memberProfile.MailingAddress.IsSafeAtHomeAddress === true
    ) {
      this.primaryAddress.properties.TopDisclaimars.push(
        this.updateMemberProfileSitecoreTemplate
          .UpdateMemberProfileSafeAtHomeMemberPrimaryAddressDisclaimar
      );
      this.primaryAddress.properties.ButtonsVisible = false;
      this.primaryAddress.properties.Disabled = true;
      this.mailingAddress.properties.TopDisclaimars.push(
        this.updateMemberProfileSitecoreTemplate
          .UpdateMemberProfileSafeAtHomeMemberMailingAddressDisclaimar
      );
      this.mailingAddress.properties.ButtonsVisible = false;
      this.mailingAddress.properties.Disabled = true;
    }
  }

  checkLobAndCodesThenShowPhoneMessage() {
    if (
      this.memberProfile.LOB.LOBCode === this.configuration.MediCalLOBCode &&
      this.memberProfile.HasBCCTPAidCode
    ) {
      this.showPhoneNumberBCCTPMessage = true;
    } else if (
      this.memberProfile.LOB.LOBCode === this.configuration.MediCalLOBCode &&
      this.memberProfile.HasRestrictedAidCode
    ) {
      this.showPhoneNumberAidCodeMessage = true;
    } else if (
      (this.memberProfile.LOB.LOBCode === this.configuration.MediCalLOBCode ||
        this.memberProfile.LOB.LOBCode ===
          this.configuration.OneCareConnectLOBCode ||
        this.memberProfile.LOB.LOBCode === this.configuration.OneCareLOBCode) &&
      this.memberProfile.HasGivenSSAConsentForPrimaryPhone === true
    ) {
      this.showPrimaryPhoneNumberMessage = true;
    }
  }

  loadPrimaryAddressData() {
    this.primaryAddress.address = new Address();
    this.primaryAddress.address.Address1 =
      this.memberProfile.HomeAddress.Address1;
    this.primaryAddress.address.Address2 =
      this.memberProfile.HomeAddress.Address2;
    this.primaryAddress.address.City = this.memberProfile.HomeAddress.City;
    this.primaryAddress.address.State = this.memberProfile.HomeAddress.State;
    this.primaryAddress.address.ZipCode =
      this.memberProfile.HomeAddress.ZipCode;
    this.primaryAddress.properties.AddressLine1CharacterCount =
      this.primaryAddress.address.Address1.length;
    this.primaryAddress.properties.AddressLine2CharacterCount =
      this.primaryAddress.address.Address2.length;
    this.primaryAddress.properties.CityCharacterCount =
      this.primaryAddress.address.City.length;
    this.setPrimaryAddressInputSwitch();
  }

  loadPrimaryAddressView() {
    this.primaryAddress.properties = new AddressViewProperties();
    this.primaryAddress.properties.HeaderText =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfilePrimaryAddressPanelTitle;
    this.primaryAddress.properties.AddressLine1LabelText =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfilePrimaryAddressLine1Label;

    this.primaryAddress.properties.AddressLine2LabelText =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfilePrimaryAddressLine2Label;

    this.primaryAddress.properties.CityLabelText =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfilePrimaryAddressCityLabel;

    this.primaryAddress.properties.StateLabelText =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfilePrimaryAddressStateLabel;

    this.primaryAddress.properties.ZipCodeLabelText =
      this.updateMemberProfileSitecoreTemplate.UpdateMeberProfileZipCodeLabel;

    this.primaryAddress.properties.AddressLineMaxNumberOfCharactersAllowed =
      this.maxNumberOfCharacters;

    this.primaryAddress.properties.CityMaxNumberOfCharacterAllowed =
      this.cityMaxNumberOfCharacters;

    this.primaryAddress.properties.UpdateButtonText =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfileSubmitButtonText;

    this.primaryAddress.properties.CancelButtonText =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfileClearButtonText;
    this.primaryAddress.properties.Disabled = false;

    this.primaryAddress.SSAConsent = true;

    this.primaryAddress.properties.ConsentNoLabelText =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfileAddressConsentNoLabel;

    this.primaryAddress.properties.ConsentYesLabelText =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfileAddressConsentYesLabel;

    this.primaryAddress.properties.ConsentLabelText =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfileAddressConsentLabel;

    // Validation messages
    this.primaryAddress.properties.ValidationMessages =
      new AddressValidationMessages();

    this.primaryAddress.properties.ValidationMessages.AddressLine1ExceededMaxCharacter =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfilePrimaryAddressLine1ExceededNumbeOfCharactersAllowedError;

    this.primaryAddress.properties.ValidationMessages.CityExceededMaxCharacter =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfileAddressCityMaxCharacters;

    this.primaryAddress.properties.ValidationMessages.AddressLine1Required =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfilePrimaryAddressIsRequiredError;

    this.primaryAddress.properties.ValidationMessages.AddressLine2ExceededMaxCharacter =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfilePrimaryAddressLine2ExceededNumbeOfCharactersAllowedError;

    this.primaryAddress.properties.ValidationMessages.CityRequired =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfilePrimaryAddressCityRequiredError;

    this.primaryAddress.properties.ValidationMessages.StateRequired =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfilePrimaryAddressStateRequiredError;

    this.primaryAddress.properties.ValidationMessages.ZipCodeRequired =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfilePrimaryAddressZipCodeRequiredError;

    this.primaryAddress.properties.ValidationMessages.ZipCodeInvalid =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfilePrimaryAddressInavlidZipCodeError;

    this.primaryAddress.properties.ValidationMessages.CityInvalid =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfileAddressCityInvalid;
  }

  loadMailingAddresData() {
    this.mailingAddress.address = new Address();
    this.mailingAddress.address.Address1 =
      this.memberProfile.MailingAddress.Address1;
    this.mailingAddress.address.Address2 =
      this.memberProfile.MailingAddress.Address2;
    this.mailingAddress.address.City = this.memberProfile.MailingAddress.City;
    this.mailingAddress.address.State = this.memberProfile.MailingAddress.State;
    this.mailingAddress.address.ZipCode =
      this.memberProfile.MailingAddress.ZipCode;
    this.mailingAddress.properties.AddressLine1CharacterCount =
      this.memberProfile.MailingAddress.Address1.length;
    this.mailingAddress.properties.AddressLine2CharacterCount =
      this.memberProfile.MailingAddress.Address2.length;
    this.mailingAddress.properties.CityCharacterCount =
      this.memberProfile.MailingAddress.City.length;
    this.mailingAddress.initialAddress = this.mailingAddress.address;
  }

  loadMailingAddressView() {
    this.mailingAddress.properties = new AddressViewProperties();
    this.mailingAddress.properties.HeaderText =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfileMailingAddressPanelTitle;

    this.mailingAddress.properties.AddressLine1LabelText =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfileMailingAddressLine1Label;

    this.mailingAddress.properties.AddressLine2LabelText =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfileMailingAddressLine2Label;

    this.mailingAddress.properties.CityLabelText =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfileMailingAddressCityLabel;

    this.mailingAddress.properties.StateLabelText =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfileMailingAddressStateLabel;

    this.mailingAddress.properties.ZipCodeLabelText =
      this.updateMemberProfileSitecoreTemplate.UpdateMeberProfileZipCodeLabel;

    this.mailingAddress.properties.AddressLineMaxNumberOfCharactersAllowed =
      this.maxNumberOfCharacters;

    this.mailingAddress.properties.CityMaxNumberOfCharacterAllowed =
      this.cityMaxNumberOfCharacters;

    this.mailingAddress.properties.UpdateButtonText =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfileSubmitButtonText;

    this.mailingAddress.properties.CancelButtonText =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfileClearButtonText;

    this.mailingAddress.properties.Disabled = false;

    // Validation messages
    this.mailingAddress.properties.ValidationMessages =
      new AddressValidationMessages();

    this.mailingAddress.properties.ValidationMessages.AddressLine1ExceededMaxCharacter =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfileMailingAddressLine1ExceededNumbeOfCharactersAllowedError;

    this.mailingAddress.properties.ValidationMessages.AddressLine1Required =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfileMailingAddressIsRequiredError;

    this.mailingAddress.properties.ValidationMessages.AddressLine2ExceededMaxCharacter =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfileMailingAddressLine2ExceededNumbeOfCharactersAllowedError;

    this.mailingAddress.properties.ValidationMessages.CityExceededMaxCharacter =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfileAddressCityMaxCharacters;

    this.mailingAddress.properties.ValidationMessages.CityRequired =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfileMailingAddressCityRequiredError;

    this.mailingAddress.properties.ValidationMessages.StateRequired =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfileMailingAddressStateRequiredError;

    this.mailingAddress.properties.ValidationMessages.ZipCodeRequired =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfileMailingAddressZipCodeRequiredError;

    this.mailingAddress.properties.ValidationMessages.ZipCodeInvalid =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfileMailingAddressInavlidZipCodeError;

    this.mailingAddress.properties.ValidationMessages.CityInvalid =
      this.updateMemberProfileSitecoreTemplate.UpdateMemberProfileAddressCityInvalid;
  }

  updateMemberPrimaryAddress() {
    const memberProfile = new MemberProfile();
    memberProfile.HomeAddress = this.primaryAddress.address;
    memberProfile.HasAcceptedSSAForPrimaryAddressConsent =
      this.memberProfile.HasAcceptedSSAForPrimaryAddressConsent;

    this.updateMemberProfile(
      memberProfile,
      MemberProfileSection.PrimaryAddress
    );
  }

  updateMemberMailingAddress() {
    const memberProfile = new MemberProfile();
    memberProfile.MailingAddress = this.mailingAddress.address;

    this.updateMemberProfile(
      memberProfile,
      MemberProfileSection.MailingAddress
    );
  }

  getMemberLanguages() {
    this.memberProfileService.getMemberLanguages().subscribe(
      (response) => {
        this.memberLanguagesList = response.filter(
          (l) => l.IsAlternateLanguage === false
        );

        this.alternateMemberLanguagesList = response.filter(
          (l) => l.IsAlternateLanguage === true
        );
      },
      (error) => {
        this.showErrorMessage(error.message);
      }
    );
  }

  updateEmail(newEmailAddress: string) {
    const memberProfile = new MemberProfile();
    memberProfile.Email = newEmailAddress;
    this.updateMemberProfile(memberProfile, MemberProfileSection.Email);
  }

  updateHomePhone(newPhoneNumber: string) {
    const memberProfile = new MemberProfile();
    memberProfile.HomePhoneNumber = newPhoneNumber;
    memberProfile.HasGivenSSAConsentForPrimaryPhone =
      this.HasGivenSSAConsentForPrimaryPhone;
    this.memberProfile.HasGivenSSAConsentForPrimaryPhone =
      this.HasGivenSSAConsentForPrimaryPhone;

    this.updateMemberProfile(memberProfile, MemberProfileSection.Phone);
  }

  updateHomePhoneSSAConsent(sSAConsent: boolean) {
    this.HasGivenSSAConsentForPrimaryPhone = sSAConsent;
  }

  updateMobilePhone(newPhoneNumber: string) {
    const memberProfile = new MemberProfile();
    memberProfile.MobilePhoneNumber = newPhoneNumber;
    this.updateMemberProfile(memberProfile, MemberProfileSection.MobilePhone);
  }

  updateMemberLanguages() {
    const memberProfile = new MemberProfile();
    memberProfile.PreferredWrittenLanguage = new MemberLanguages();
    memberProfile.PreferredSpokenLanguage = new MemberLanguages();

    if (this.preferredWrittenLanguage.Code) {
      memberProfile.PreferredWrittenLanguage = this.preferredWrittenLanguage;
    }
    if (this.preferredSpokenLanguage.Code) {
      memberProfile.PreferredSpokenLanguage = this.preferredSpokenLanguage;
    }
    this.updateMemberProfile(memberProfile, MemberProfileSection.Language);
  }

  updateTCPA() {
    this.resetVariablesBeforeSubmit();
    const request = new TCPUpdate();
    request.UserWarningMessageCode = this.TCPAOptIn ? 133 : 135;

    this.memberProfileService.TCPAUpdate(request).subscribe(
      (response) => {
        this.customerServiceResponse = response;
        if (this.customerServiceResponse.RequestId != null) {
          this.orgTCPAOptIn = this.TCPAOptIn;
          const successMessage =
            this.updateMemberProfileSitecoreTemplate.UpdateMemberProfileSuccessMessage.replace(
              this.configuration.FXIRequestIDToken,
              this.customerServiceResponse.RequestId
            );
          this.showPostSuccessMessage(
            successMessage,
            MemberProfileSection.TCPAOption
          );
          this.readOnlyTCPA = true;
        } else {
          this.showTCPASubmissionLimitMessage = true;
          this.TCPAOptIn = this.orgTCPAOptIn;
          this.readOnlyTCPA = false;
        }
      },
      (error) => {
        this.showErrorMessage(error.message);
      }
    );
    window.scrollTo(0, 0);
  }

  updateMemberProfile(
    memberProfile: MemberProfile,
    updatedSection: MemberProfileSection
  ) {
    const updateMemberProfileRequest = new UpdateMemberProfileRequest();
    updateMemberProfileRequest.member = memberProfile;

    updateMemberProfileRequest.updateSections = [updatedSection];

    this.resetVariablesBeforeSubmit();

    this.memberProfileService
      .updateMemberProfile(updateMemberProfileRequest)
      .subscribe(
        (response) => {
          if (response && response.length > 0 && response[0].RequestId) {
            this.customerServiceResponse = response[0];

            const successMessage =
              this.updateMemberProfileSitecoreTemplate.UpdateMemberProfileSuccessMessage.replace(
                this.configuration.FXIRequestIDToken,
                this.customerServiceResponse.RequestId
              );

            this.showPostSuccessMessage(successMessage, updatedSection);
          } else {
            // show some error when didn't get a good response from bridge
          }
        },
        (error) => {
          this.showErrorMessage(error.message);
        }
      );

    window.scrollTo(0, 0);
  }

  showPostSuccessMessage(
    successMessage: string,
    updatedSection: MemberProfileSection
  ) {
    this.successMessage = successMessage;
    switch (updatedSection) {
      case MemberProfileSection.PrimaryAddress: {
        this.checkLobAndShowPrimaryAddressMessage();
        this.checkAidCodeAndShowPrimaryAddressMessage();
        break;
      }
      case MemberProfileSection.MailingAddress: {
        this.checkLobAndShowMailingAddressMessage();
        break;
      }
      case MemberProfileSection.Phone: {
        this.checkLobAndCodesThenShowPhoneMessage();
        break;
      }
      case MemberProfileSection.TCPAOption: {
        this.showSuccessMessage = true;
        // add logic here in the future for TCPAOption if there are new rules on when to show the success message
        break;
      }

      default: {
        break;
      }
    }
  }

  setPrimaryAddressInputSwitch() {
    if (
      this.memberProfile.LOB.LOBCode === this.configuration.MediCalLOBCode &&
      (this.memberProfile.HomeAddress.IsSafeAtHomeAddress ||
        this.checkIfHasAidCode() ||
        this.checkIfIsBCCTP()) &&
      this.memberProfile.LOB.LOBCode !==
        this.configuration.OneCareConnectLOBCode &&
      this.memberProfile.LOB.LOBCode !== this.configuration.OneCareLOBCode
    ) {
      this.memberProfile.HasAcceptedSSAForPrimaryAddressConsent = false;
      this.primaryAddress.properties.ShowConsentInputSwitch = false;
    } else {
      this.memberProfile.HasAcceptedSSAForPrimaryAddressConsent = true;
      this.primaryAddress.properties.ShowConsentInputSwitch = true;
    }
  }

  checkIfHasAidCode() {
    if (
      this.memberProfile?.LOB.LOBCode === this.configuration.MediCalLOBCode &&
      this.memberProfile?.HasRestrictedAidCode
    ) {
      return true;
    } else {
      return false;
    }
  }

  checkIfIsBCCTP() {
    if (
      this.memberProfile?.LOB.LOBCode === this.configuration.MediCalLOBCode &&
      this.memberProfile?.HasBCCTPAidCode
    ) {
      return true;
    } else {
      return false;
    }
  }

  checkAidCodeAndShowPrimaryAddressMessage() {
    if (this.checkIfHasAidCode()) {
      this.showPrimaryAddressForwardMessage = false;
      this.showAgedBlindDisabledPrimaryAddressMessage = true;
    }

    if (this.checkIfIsBCCTP()) {
      this.showPrimaryAddressForwardMessage = false;
      this.showBCCTPPrimaryAddressMessage = true;
    }
  }

  showErrorMessage(errorMessage: string) {
    this.errorMessage = errorMessage;
  }

  resetVariablesBeforeSubmit() {
    this.customerServiceResponse = null;
    this.showPrimaryAddressForwardMessage = false;
    this.showPrimaryAddressOneCareInCountyMessage = false;
    this.showPrimaryAddressOneCareConnectInCountyMessage = false;
    this.showPrimaryAddressOneCareOutOfCountyMessage = false;
    this.showPrimaryAddressOneCareConnectOutOfCountyMessage = false;
    this.showMailingAddressOneCareInCountyMessage = false;
    this.showMailingAddressOneCareConnectInCountyMessage = false;
    this.showPhoneNumberAidCodeMessage = false;
    this.showPhoneNumberBCCTPMessage = false;
    this.showPrimaryAddressMediCalOutOfCountyMessage = false;
    this.showBCCTPPrimaryAddressMessage = false;
    this.showAgedBlindDisabledPrimaryAddressMessage = false;
    this.showPrimaryPhoneNumberMessage = false;
    this.showSuccessMessage = true;
    this.successMessage = null;
    this.errorMessage = null;
    this.isLanguagePreferenceChanged = false;
    this.showTCPASubmissionLimitMessage = false;
  }

  resetLanguagePreferences() {
    this.preferredSpokenLanguage = {
      ...this.memberProfile.PreferredSpokenLanguage,
    };
    this.preferredWrittenLanguage = {
      ...this.memberProfile.PreferredWrittenLanguage,
    };
    this.isLanguagePreferenceChanged = false;
  }

  checkLobAndShowPrimaryAddressMessage() {
    if (this.memberProfile?.HasAcceptedSSAForPrimaryAddressConsent) {
      this.showPrimaryAddressForwardMessage = true;
    } else {
      this.showPrimaryAddressForwardMessage = false;
    }

    if (
      this.customerServiceResponse.IsAddressUpdateRequestOutsideOrangeCounty
    ) {
      this.showSuccessMessage = true;
      // Member has requested out of county address update
      if (
        this.memberProfile.LOB.LOBCode === this.configuration.MediCalLOBCode
      ) {
        this.showPrimaryAddressMediCalOutOfCountyMessage = true;
      } else if (
        this.memberProfile.LOB.LOBCode === this.configuration.OneCareLOBCode
      ) {
        this.showPrimaryAddressOneCareOutOfCountyMessage = true;
      } else if (
        this.memberProfile.LOB.LOBCode ===
        this.configuration.OneCareConnectLOBCode
      ) {
        this.showPrimaryAddressOneCareConnectOutOfCountyMessage = true;
      }
    } else {
      // In County Address Update
      if (
        this.memberProfile?.LOB.LOBCode === this.configuration.OneCareLOBCode
      ) {
        this.showPrimaryAddressOneCareInCountyMessage = true;
      } else if (
        this.memberProfile?.LOB.LOBCode ===
        this.configuration.OneCareConnectLOBCode
      ) {
        this.showPrimaryAddressOneCareConnectInCountyMessage = true;
      }
    }
  }

  checkLobAndShowMailingAddressMessage() {
    if (
      !this.customerServiceResponse.IsAddressUpdateRequestOutsideOrangeCounty
    ) {
      // In County Address Update
      if (
        this.memberProfile.LOB.LOBCode === this.configuration.OneCareLOBCode
      ) {
        this.showMailingAddressOneCareInCountyMessage = true;
      } else if (
        this.memberProfile.LOB.LOBCode ===
        this.configuration.OneCareConnectLOBCode
      ) {
        this.showMailingAddressOneCareConnectInCountyMessage = true;
      }
    }
  }
  onPreferredWrittenLanguageChange(changedLanguage: MemberLanguages) {
    if (
      this.preferredWrittenLanguage.Code &&
      this.memberProfile.PreferredWrittenLanguage.Code !=
        this.preferredWrittenLanguage.Code
    ) {
      this.isLanguagePreferenceChanged = true;
    } else {
      this.isLanguagePreferenceChanged = false;
    }
  }
  onPreferredSpokenLanguageChange(changedLanguage: MemberLanguages) {
    if (
      this.preferredSpokenLanguage.Code &&
      this.memberProfile.PreferredSpokenLanguage.Code !=
        this.preferredSpokenLanguage.Code
    ) {
      this.isLanguagePreferenceChanged = true;
    } else {
      this.isLanguagePreferenceChanged = false;
    }
  }

  clearPrimaryAddressForm() {
    this.loadPrimaryAddressData();
  }

  compareLanguage(l1: MemberLanguages, l2: MemberLanguages) {
    return l1.Code === l2.Code;
  }

  clearMailingAddressForm() {
    this.loadMailingAddresData();
  }

  sSAConsentChanged(sSAConsent: boolean) {
    this.memberProfile.HasAcceptedSSAForPrimaryAddressConsent = sSAConsent;
  }
}
