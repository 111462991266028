import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BHVirtualVisitResponse } from '../bhvirtual-visit-reponse';
import { BHVirtualVisitSitecoreTemplate } from '../bhvirtual-visits-sitecore';
import {
  Observable,
  catchError,
  publishReplay,
  refCount
} from "rxjs";
import { Configuration } from "../../../app.constants";
import { HttpResponseHelper } from "../../../core/shared/helpers/http-response-helper";
import { LanguageService } from "../../../core/shared/services/language.service";

@Injectable({
  providedIn: 'root'
})
export class VirtualVisitsService {
  private virtualVisitsUrl: string;

  constructor(
    private http: HttpClient,
    private configuration: Configuration,
    private languageService: LanguageService) {  
      this.virtualVisitsUrl =
      configuration.BaseMemberServiceUrl +
      configuration.BehaviralHealthVirtualVisitsUrl;
    }

  public getVirtualVisitConfig(): Observable<BHVirtualVisitResponse> {
    return this.http
      .get<BHVirtualVisitResponse>(this.virtualVisitsUrl)
      .pipe(
        catchError((error) =>
          HttpResponseHelper.handleError(this.configuration, error)
      )
    );
  }

  public getBHVirtualVisitsSitecoreContent(): Observable<BHVirtualVisitSitecoreTemplate> {
    return this.http.get<BHVirtualVisitSitecoreTemplate>(this.languageService.getContentServiceUrlWithParameter(this.configuration.SitcoreBHVirtualVisitsParameter))
        .pipe(publishReplay(1)
        ,refCount()
        ,catchError(error => HttpResponseHelper.handleError(this.configuration, error)));
  }

  public checkIsActiveUrl(): Observable<any> {
  
    let url: string = this.configuration.BaseMemberServiceUrl + "checkbhvirtualvisitsavailable";
    return this.http.get(url);
  }
}
