import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { AuthorizationsService } from '../shared/services/authorizations.service';
import { AuthorizationDetailsSitecoreTemplate } from '../shared/authorization-details-sitecore-template';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LanguageService, INotifyChange } from '../../core/shared/services/language.service';
import { Referral } from '../shared/referral';

@Component({
  selector: 'app-authorization-detail',
  templateUrl: './authorization-detail.component.html',
  styleUrls: ['./authorization-detail.component.scss'],
})
export class AuthorizationDetailComponent implements OnInit, OnDestroy {
  authorizationDetailsTemplate = new AuthorizationDetailsSitecoreTemplate();
  message: string;
  // authorization = new Authorization();
  referralDetail = new Referral();
  private subscription: Subscription;

  constructor(
    private authService: AuthorizationsService,
    private router: Router,
    private languageService: LanguageService
  ) {}
  ngOnInit() {
    try {
      this.subscription = this.languageService.notifyObservable$.subscribe((change: INotifyChange) => {
        if(change.propName === 'languageCode'){
          this.onLoad();
        }
      });
    }
    catch (ex) {
      throw ex;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onLoad() {
    //get auth detail passing from authorization-summary. authDetail(auth: Authorization)
    if(history.state.data)
    {
      this.referralDetail = history.state.data;
        this.getSitcoreContent();
        // this.getReferralDetails(this.authorization.ReferralId);
      } else {
        this.router.navigate(['authorizations']);
      }
  }

  getSitcoreContent() {
    this.authService.getAuthorizationDetailsSitecoreContent().subscribe(
      response => {
        this.authorizationDetailsTemplate = response;
      },
      error => (this.message = <any>error)
    );
  }

  getReferralDetails(referralId: string)
  {
    this.authService.getReferralDetails(referralId).subscribe(
      response => {
        this.referralDetail = response;
      },
      error => (this.message = <any>error)
    );
  }

}
