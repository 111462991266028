<!-- TCPA Message Section-->
<p-dialog [(visible)]="showTCPAModalMessage"  [modal]="true" [baseZIndex]="10000" styleClass="modal-dialog-md"
 [draggable]="false" [resizable]="false" [focusOnShow]="true" [closable]="false">
 <p-header>
     {{dashboard2.TCPAPreference?.TCPAHeader}}
 </p-header>
 <div style="margin-left: 15px;margin-right: 15px;" class="text-justify">
    <p> {{dashboard2.TCPAPreference?.TCPAMessage || "from time to time, CalOptima will need to contact you with important news about your health care benefits. We are requesting your permission to contact you on your cell phone."}}</p>

      <label class="radio-container">
        <input type="radio" [(ngModel)]="TCPAOptIn" name="TCPAUpdate" id="rdoTCPAOptIn" [value]="true" />
        <span>{{dashboard2.TCPAPreference?.TCPAOptInSelection || "i grant permission for CalOptima to contact my cell phone number for automated calls and text messages.*"}}</span>
        <span class="checkmark"></span>
    </label>

    <label class="radio-container">
      <input type="radio" [(ngModel)]="TCPAOptIn" name="TCPAUpdate" id="rdoTCPAOptOut" [value]="false" />
      <span>{{dashboard2.TCPAPreference?.TCPAOptOutSelection || "i do not grant CalOptima permission to contact my cell phone number."}}</span>
      <span class="checkmark"></span>
    </label>
    
    <p class="font-italic small pt-3 pb-0 m-0">{{dashboard2.TCPAPreference?.TCPANote || "*please note that based on your mobile service plan, message and data rates may be applied by your mobile provider."}}</p>
 </div>
 <p-footer>
     <button ID="TCPASubmitBtn" (click)="submitCommunication()" class="btn btn-primary" >
     {{dashboard2.TCPAPreference?.TCPASubmitBtn || "submit"}}
     </button>
 </p-footer>
</p-dialog>

<div class="container-fluid">
  <wait-cursor [show]="isLoading"></wait-cursor>
  <error-summary></error-summary>
</div>

<div class="alert-das alert-warning" role="alert" *ngFor="let alert of generalAlerts">
  <i class="fa-duotone fa-solid fa-triangle-exclamation fa-xl"></i>
  <div class="alert-message">{{alert.Message}}</div>
</div>

<!-- Carousel and rotator -->
<div class="carousel-container">
  <div id="caloptima-carousel" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-indicators">
      <button *ngFor="let x of dashboard2.CarouselItems; let i = index"
        type="button" data-bs-target="#caloptima-carousel" [attr.data-bs-slide-to]="i" 
        [class.active]="i == 0" [attr.aria-current]="i == 0 ? true : false"></button>
    </div>
    <div class="carousel-inner">
      <div *ngFor="let carouselItem of dashboard2.CarouselItems; let k = index" [ngClass]="k === 0 ? 'active' : ''" class="carousel-item {{carouselItem?.BackgroundCss}}" data-bs-interval="10000">
        <img src="{{carouselItem?.ImageUrl}}" class="d-block" alt="{{carouselItem?.ImageAltText}}" />
        <div class="carousel-caption d-none d-md-block">
          <div class="carousel-title">{{carouselItem?.CarouselTitle}}</div>
          <div class="carousel-text">{{carouselItem?.CarouselText}}</div>
          <a href="{{carouselItem?.TargetUrl}}" target="{{setLinkTarget(carouselItem?.TargetUrl)}}">
            <button class="carousel-button">{{carouselItem?.ButtonLabel}}</button>
          </a>
        </div>
      </div>
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#caloptima-carousel" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button #carNextBtn class="carousel-control-next" type="button" data-bs-target="#caloptima-carousel" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</div>

<!-- Card items -->
<div class="card-wrapper">
<div class="row row-cols-1 row-cols-md-3 g-4 text-center">
  <div class="col" *ngFor="let cardItem of dashboard2.CardItems; let k = index">
    <div class="card card-paddit">
      <div class="card-body">
        <span class="cta-icon-wrapper">
          <i class="fa-duotone fa-2xl fa-lob {{cardItem?.CardIconCss}}"></i>
        </span>
        <div class="card-pad20">
          <h5 class="card-title">{{cardItem?.CardTitle}}</h5>
          <div class="card-text">{{cardItem?.CardText}}</div>
          <button class="btn card-btn" routerLink="{{cardItem?.TargetUrl}}">{{cardItem?.ButtonLabel}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
</div>

<!-- CTA item -->
<div class="cta-wrapper">
  <div class="row row-cols-1 row-cols-md-2 g-4 text-center">
    <div class="col" *ngFor="let ctaItem of dashboard2.CtaItems; let k = index">
      <div class="card {{ctaItem?.CardCss}}">
        <div class="row g-0">
          <div class="card-body">
            <div class="cta-mini-wrap">
              <div class="cta-mini-left">
                <img src="{{ctaItem?.ImageUrl}}" alt="" class="cta-mini-image">
              </div>
              <div class="cta-mini-right">
                <h5 class="card-title text-primary">{{ctaItem?.CtaTitle}}</h5>
                <p class="card-text">{{ctaItem?.CtaText}}</p>
                <a href="{{ctaItem?.TargetUrl}}" target="{{setLinkTarget(ctaItem?.TargetUrl)}}">
                  <button class="btn card-btn">{{ctaItem?.ButtonLabel}}</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Member Card -->
<div class="msg-wrapper" id="member-messages">
  <div class="row row-cols-1 row-cols-md-3 g-4 text-center row-height-100">
    <div class="col col-height-100">
        <div class="card card-cta-body card-min">
            <div class="card-header">
                {{ dashboard2.MemberInfo?.MemberInfoTitle || "member info" }}
            </div>
            <div class="card-body">
              <div class="member-wrapper">
                  <div class="member-fa-wrapper">
                      <i class="fa-duotone fa-circle-user fa-2xl fa-lob"></i>
                  </div>
                  <div class="member-name-wrapper">
                      <div class="member-name text-capitalize">
                        {{memberProfile.FirstName.toLocaleLowerCase()}} {{memberProfile.LastName.toLocaleLowerCase()}}
                      </div>
                      <div class="member-cin">
                        {{ dashboard2.MemberInfo?.MemberIDLabel || "member id:"}} {{ memberProfile.CIN }}
                      </div>
                      <div class="member-profile-link">
                        <a href="#/profile" style="text-decoration: none;">{{ dashboard2.MemberInfo?.MemberProfileLinkLabel || "view member profile"}}</a>
                      </div>
                  </div>
                  <div class="member-separator g-0"></div>
                  <div class="member-profile-details">
                      <div>
                  <b>{{ dashboard2.MemberInfo?.MemberProgramLabel || "program"}}</b>
                      </div>
                      <div class="member-profile-data">
                        {{memberProfile.LOB.LobDisplayText}}
                      </div>
                      <div>
                  <b>{{ dashboard2.MemberInfo?.HealthNetworkLabel || "health network"}}</b>
                      </div>
                      <div class="member-profile-data">
                        {{memberProfile.HealthNetworkPlan.HealthNetworkPlanDisplayText}}
                      </div>
                      <div>
                  <b>{{ dashboard2.MemberInfo?.EligibilityLabel || "eligibility"}}</b>
                      </div>
                      <div class="member-profile-data">
                        {{memberProfile.EligibilityStatus}}
                      </div>
                      <div>
                  <b>{{ dashboard2.MemberInfo?.YourDoctorLabel || "your doctor"}}</b>
                      </div> 
                      <div class="member-profile-data">
                        {{memberProfile.PrimaryCareProviderInfo.ProviderName}}
                      </div>
                      <div>
                          <a style="text-decoration: none;" href="#/changepcpchangehealthnetwork">
                            {{ dashboard2.MemberInfo?.ChangeHealthNetworkLabel || "change health network or pcp"}}
                          </a>
                      </div>
                  </div>
              </div>
            </div>
        </div>
    </div>

    <!-- Message Center //-->
    <app-member-message class="col flex-fill" *ngIf="memberProfile && memberProfile.IsEligible && !disablePersonalRepresentative"></app-member-message>     
  </div>
</div>