
import { ImportantLinkSitecoreTemplate} from './important-link-sitecore-template';
export class ImportantDocumentsSiteCoreTemplate {

    public Instructions: string;
    public LinksPageTitle: string;
    public YourBenefitsImage: string;
    public YourBenefitsPargraph: string;
    public YourBenfitsTitle: string;
    public MediCalBenefitsLink: string;
    public OCBenefitsLink: string;
    public OCCBenefitsLink: string;
    public MediCalHandbookLink: string;
    public OCHandbookLink: string;
    public OCCHandbookLink: string;
    public ViewBenefitsText: string;
    public ViewHandbookText: string;
    public ListOfDocuments: Array<ImportantLinkSitecoreTemplate>;
}
