export class InterpretiveServiceSitecoreTemplate {

    public InterpretiveServiceTitle: string = "";
    public InstructionsLabel1: string = "";
    public InstructionsLabel2: string = "";
    public InstructionsLabel3: string = "";
    public InstructionsLabel4: string = "";
    public UpdateMemberProfileLink: string = "";
    public MemberContactPhoneLabel: string = "";
    public UpdateMemberProfileText: string = "";
    public MobilePhoneRequiredMessage: string = "";
    public MobilePhoneInvalidInputMessage: string = "";

    public InterpreterLanguageLabel: string = "";    
    public LTCorHospitalLabel: string = "";
    public GenderLabel: string = "";
    public Yes: string = "";
    public No: string = "";
    public AppointmentLabel: string = "";
    public CancelLabel: string = "";
    public FinishLabel: string = "";
    public AddAppointmentLabel: string = "";

    public AppointmentTypeLabel: string = "";
    public CancelAppointmentLabel: string = "";
    public AppointmentDateLabel: string = "";
    public AppointmentTimeLabel: string = "";
    public AppointmentDurationLabel: string = "";
    
    public ProviderNameLabel: string = "";
    public EventPhoneLabel: string = "";
    public ContactPersonLabel: string = "";
    public EventAddressLabel: string = "";
    public EventCityLabel: string = "";
    public EventZipcodeLabel: string = "";
    public SuccessMessage: string = "";
    public AdditionalAppointmentMessage: string = "";

    public InterpreterLanguageRequiredError: string = "";
    public AppointmentTypeRequiredError: string = "";
    public AppointmentProviderNameRequiredError: string = "";
    public AppointmentDateError: string = "";
    public AppointmentDateRangeError: string = "";
    public AppointmentTimeError: string = "";
    public AppointmentDurationError: string = "";    
    public ContactPersonRequiredError: string = "";
    public EventAddressRequiredError: string = "";
    public EventCityRequiredError: string = "";
    public EventZipcodeRequiredError: string = "";
    public EventZipcodeError: string = "";
    public ValidationErrorMessage: string = "";
    public ErrorMessage: string = "";
    public NoPreference: string = "";
    public Female: string = "";
    public Male: string = "";
    public Ok: string = "";
    public ConfirmAppointmentCancelHeader: string = "";
    public ConfirmAppointmentCancelMessage: string = "";
    public ConfirmFormResetHeader: string = "";
    public ConfirmFormResetMessage: string = "";
    public Select: string = "";
    public NonMedicalVisit: string = "";
    public DoctorAppointment: string = "";
    public PharmacyVisit: string = "";
    public Other: string = "";
}     