import { Injectable } from '@angular/core';
import { HttpClient, } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Configuration } from '../../../app.constants';
import { HttpResponseHelper } from '../../../core/shared/helpers/http-response-helper';
import { catchError, publishReplay, refCount} from 'rxjs/operators';
import { LanguageService } from '../../../core/shared/services/language.service';
import { MessageResponse } from '../message-response';
import { MessageRequest } from '../message-request';
import { MessageSitecoreTemplate } from '../message-sitecore-template';

@Injectable()
export class MessageService {
    constructor(private http: HttpClient
        , private configuration: Configuration
        , private languageService: LanguageService
        ) {
    }

    getMemberMessages(): Observable<MessageResponse[]> {
        return this.http.get<MessageResponse[]>(this.configuration.MemberMessagesUrl + this.languageService.languageCode)
            .pipe(catchError(error => HttpResponseHelper.handleError(this.configuration, error)));
    }

    updateMemberMessage(messageRequest: MessageRequest): Observable<number> {
        return this.http.post<number>(this.configuration.MemberMessageUpdateUrl, messageRequest)
            .pipe(catchError(error => HttpResponseHelper.handleError(this.configuration, error)));
    }

    getMemberMessageSitecoreContent(): Observable<MessageSitecoreTemplate> {
        return this.http.get<MessageSitecoreTemplate>(this.languageService.getContentServiceUrlWithParameter(this.configuration.SitecoreServiceMemberMessageParameter))
            .pipe(publishReplay(1)
                ,refCount()
                ,catchError(error => HttpResponseHelper.handleError(this.configuration, error)));
    }
}
