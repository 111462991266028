<div class="card card-cta-body">
  <div class="card-header">
    {{messageTemplate?.MessagesHeader || 'messages'}}
  </div>
  <div class="card-body card-accordion-wrapper">

    <div class="accordion accordion-flush" id="accordionFlushExample">

      <!-- Message items -->
      <div class="accordion-item" *ngFor="let msg of memberMessages; let i = index">
        <div class="accordion-header" >
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" (click)="onMessageExpand(i)"
            [attr.data-bs-target]="'#flush-collapse-'+i" aria-expanded="false" [attr.aria-controls]="'flush-collapse-'+i">
            <!-- FLAG //-->
            <div [ngClass]="getType(msg.MessageModel.MessageType)"></div>
            <!-- UNREAD DOT //-->
            <div class="accordion-dot"><span *ngIf="!msg.MessageStatus?.HasRead" class="dot-unread"></span></div>
            <div class="accordion-content-wrapper" >
              <div [ngClass]="msg.MessageStatus?.HasRead ? 'accordion-subject-read' : 'accordion-subject-unread'"
                [innerHTML]="msg.MessageModel?.Title"></div>
              <div class="accordion-content-body"></div>
            </div>
            <!-- DATE //-->
            <div class="flex-fill accordion-date">{{msg.MessageStatus?.EffectiveDate | date}}</div>
          </button>
        </div>
        <div id="flush-collapse-{{i}}" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
          <div class="accordion-body" [innerHTML]="msg.MessageModel?.MessageBody"></div>
          <div class="accordion-delete">
            <a (click)="onMessageDelete(i)">
              <i class="fa-duotone fa-trash-can fa-lob" alt="Delete Message" title="Delete Message"></i>
              <span>&nbsp;Delete</span>
            </a>
          </div>
        </div>
      </div>
      <!-- Messge items end -->
    </div>
  </div>

  <div class="card-footer">
    <small class="text-body-secondary">&nbsp;</small>
  </div>

</div>