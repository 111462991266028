import { ProviderAddress } from "./provider-address" 
import { ProviderHealthNetwork } from "./provider-health-network"

export class ProviderInfo {
    public Selected: Boolean = false;
    public ProviderId: string;
    public ProviderName: string;
    public FirstName: string;
    public MiddleInitial: string;
    public LastName: string;
    public Gender: string;
    public TaxId: string;
    public NPI: string;
    public Title: string;
    public SpecialtyCode: string;
    public Languages: string;
    public IsCertified : boolean;
    public PrimaryCertification: string;
    public SecondaryCertification: string;
    public ProviderNetworksCount:number = 0;
    public ProviderNetworks: Array<ProviderHealthNetwork> =  new Array<ProviderHealthNetwork>();
    public  ProviderAddressesCount: number = 0;
    public ProviderAddresses: Array<ProviderAddress> = new Array<ProviderAddress>();
}
