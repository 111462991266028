import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';

import { Observable } from 'rxjs';
import {publishReplay, catchError, refCount} from 'rxjs/operators';
import { Configuration } from '../../../app.constants';
import { HttpResponseHelper } from '../../../core/shared/helpers/http-response-helper';
import { AuthorizationSitecoreTemplate } from '../authorizations-sitecore-template';
import { ClaimList } from '../claim-list';
import { LanguageService } from '../../../core/shared/services/language.service';

@Injectable()

export class ClaimsService {

    private claimsServiceUrl: string;
    private claimsAll = 'claims/all';

    constructor(private http: HttpClient, private configuration: Configuration, private languageService: LanguageService) {
        this.claimsServiceUrl = configuration.BaseMemberServiceUrl + this.claimsAll;
    }
    getClaims() {
        return this.http.get<ClaimList>(this.claimsServiceUrl)
          .pipe(publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(this.configuration, error)));
    }

    getClaimsSitecoreContent(): Observable<AuthorizationSitecoreTemplate> {
      return this.http.get<AuthorizationSitecoreTemplate>(this.languageService.getContentServiceUrlWithParameter(this.configuration.SitecoreServiceAuthorizationParameter))
            .pipe(publishReplay(1),
            refCount(),
            catchError(error => HttpResponseHelper.handleError(this.configuration, error)));
    }
  }

