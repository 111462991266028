<div class="container">
    <div class="centered-content">
      <form #requestCardForm="ngForm">
        <div class="row">
          <div class="col-md-12 info-main-content-section">
            <h1>{{requestIdCardContent.MainHeading || "request a member id card"}}</h1>
            <br />
            <p class="info-main-content-body">
              {{requestIdCardContent.MainMessage}}
            </p>
            <p class="info-main-content-body">
              {{requestIdCardContent.SecondaryMessageText}}
            </p>
              <error-summary></error-summary>
            <div class="info-main-content-section-top">
              <div class="alert alert-info" *ngIf="showKaiserMessage">
                <i class="fa-duotone fa-info-circle fa-lg" aria-hidden="true"></i> &nbsp; {{requestIdCardContent.KaiserDisclaimerMessage}}
              </div>
            </div>
            <div *ngIf="errorMessage" class="alert alert-danger info-main-content-section-top">
              <i class="fa-duotone fa-ban fa-lg" aria-hidden="true"></i> &nbsp; {{errorMessage || "An error has occured"}}
            </div>
  
            <div *ngIf="successMessage && showSuccessMessage" class="alert alert-success info-main-content-section-top">
              <i class="fa-duotone fa-check fa-lg" aria-hidden="true"></i> &nbsp; {{successMessage}}
            </div>
          </div>
          <div class="col-md-12 info-main-content-section">
  
            <h2>{{requestIdCardContent.MemberHeading || "the replacement Member ID card is for:"}}</h2>
  
            <div class="row">
              <div class="col-md-4 contact-info-address-box">
                <label class="control-label">{{requestIdCardContent.MemberName || "name"}}</label>
                <div>{{memberProfile.FirstName}} {{memberProfile.LastName}}</div>
              </div>
            </div>
            <div class="row">
                <div class="col-md-4 contact-info-address-box">
                  <label class="control-label">{{requestIdCardContent.MemberMailingAddress || "address"}}</label>
                  <app-address 
                    [properties]="mailingAddress.properties" 
                    [address]="mailingAddress.address">
                  </app-address>
                  
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 contact-info-address-box">
                <button 
                    *ngIf="memberProfile.IsEligible && memberProfile.LOB.LOBCode != this.configuration.PaceLOBCode" 
                    class="btn btn-primary" 
                    routerLink="/updatememberprofile"
                   >
                      {{requestIdCardContent.UpdateProfileLink1 || "Modify"}}
                    </button>
              </div>
            </div>
            <br />
          
            <!-- Temporary Address-->
            <br />
            <input #IfTemporaryAddress type="checkbox" name="IfTemporaryAddress" [(ngModel)]="requestIDCard.IfTemporaryAddress" (change)="onChange()"
              [checked]="IfMailToTempAddr" />&nbsp;&nbsp;{{requestIdCardContent.IfTemporaryMailingAddressLabel || "deliver my id card to a temporary mailing
            address"}}
            <br />
            <br />
            <div *ngIf="IfMailToTempAddr">
              <div>
                <span>
                  {{requestIdCardContent.TemporaryMailingAddressMessage || "enter an address for one-time use."}}
                </span>
  
                <span *ngIf="memberProfile.IsEligible && memberProfile.LOB.LOBCode != this.configuration.PaceLOBCode">
                  {{requestIdCardContent.TemporaryMailingAddressMessage2Text || "to update your mailing address permanently, "}}
                  <a href="" routerLink="/updatememberprofile">{{requestIdCardContent.UpdateProfileLink2 || "change your personal information"}}</a>
                </span>
              </div>
              <br />
              <div>
                <div class="row">
                  <div class="form-group col-md-12">
                    <label class="control-label">{{requestIdCardContent.TemporaryMailingCareOfLabel || "c/o"}}</label>
                    <input [(ngModel)]="requestIDCard.MemberCareOf" type="text" class="form-control" name="memberCareOf" id="memberCareOf" [disabled]="(memberProfile.HomeAddress.IsSafeAtHomeAddress == true || memberProfile.MailingAddress.IsSafeAtHomeAddress == true) && this.memberProfile.LOB.LOBCode.toUpperCase() === this.configuration.MediCalLOBCode.toUpperCase()"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-md-12">
                    <label class="control-label">{{requestIdCardContent.TemporaryMailingAddress1 || "address line 1:"}}</label>
                    <input [(ngModel)]="requestIDCard.MemberAddressLine1" type="text" class="form-control" name="memberAddressLine1" id="memberAddressLine1"
                      [disabled]="(memberProfile.HomeAddress.IsSafeAtHomeAddress == true || memberProfile.MailingAddress.IsSafeAtHomeAddress == true) && this.memberProfile.LOB.LOBCode.toUpperCase() === this.configuration.MediCalLOBCode.toUpperCase()"
                    />
                  </div>
                </div>
  
                <div class="row">
                  <div class="form-group col-md-12">
                    <label class="control-label">{{requestIdCardContent.TemporaryMailingAddress2 || "address line 2:"}}</label>
                    <input [(ngModel)]="requestIDCard.MemberAddressLine2" type="text" class="form-control" name="memberAddressLine2" id="memberAddressLine2"
                      [disabled]="(memberProfile.HomeAddress.IsSafeAtHomeAddress == true || memberProfile.MailingAddress.IsSafeAtHomeAddress == true) && this.memberProfile.LOB.LOBCode.toUpperCase() === this.configuration.MediCalLOBCode.toUpperCase()"
                    />
                  </div>
                </div>
  
                <div class="row">
                  <div class="form-group col-md-4">
                    <label class="control-label">{{requestIdCardContent.TemporaryMailingCity || "city:"}}</label>
                    <input [(ngModel)]="requestIDCard.MemberCity" type="text" class="form-control" name="MemberCity" id="MemberCity" [disabled]="(memberProfile.HomeAddress.IsSafeAtHomeAddress == true || memberProfile.MailingAddress.IsSafeAtHomeAddress == true) && this.memberProfile.LOB.LOBCode.toUpperCase() === this.configuration.MediCalLOBCode.toUpperCase()"
                    />
                  </div>
  
                  <div class="form-group col-md-2">
                    <label class="control-label">{{requestIdCardContent.TemporaryMailingState || "state:"}}</label>
                    <select [(ngModel)]="requestIDCard.MemberState" class="form-control" name="MemberState" id="MemberState" [disabled]="(memberProfile.HomeAddress.IsSafeAtHomeAddress == true || memberProfile.MailingAddress.IsSafeAtHomeAddress == true) && this.memberProfile.LOB.LOBCode.toUpperCase() === this.configuration.MediCalLOBCode.toUpperCase()">
                        <option>---Select---</option>
                        <option *ngFor="let state of states"
                                [ngValue]="state.abbreviation">
                            {{state.abbreviation}}
                        </option>
                    </select>
                  </div>
  
                  <div class="form-group col-md-4">
                    <label class="control-label">{{requestIdCardContent.TemporaryMailingZipCode || "zipcode:"}}</label>
                    <input [(ngModel)]="requestIDCard.MemberZipCode" type="text" class="form-control" name="MemberZipCode" id="MemberZipCode"
                      [disabled]="(memberProfile.HomeAddress.IsSafeAtHomeAddress == true || memberProfile.MailingAddress.IsSafeAtHomeAddress == true) && this.memberProfile.LOB.LOBCode.toUpperCase() === this.configuration.MediCalLOBCode.toUpperCase()"
                    />
                  </div>
                </div>
              </div>
            </div>
            <br />
            <label class="control-label">{{requestIdCardContent.IfPrintAlternateFormatLabel || "print format"}}</label>
  
            <div class="row">
  
              <div class="col-md-6">
                <select class="form-select" name="alternateFormat" [(ngModel)]="requestIDCard.AlternateFormat">
                  <option *ngFor="let item of printFormats | keyvalue" [ngValue]="item.key">{{item.value}}</option>
                </select>
              </div>
            </div>
  
            <div class="row">
              <div class="col-md-12 text-right">
                <button class="btn btn-link" (click)="requestCardForm.form.reset();requestCardForm.form.markAsPristine();resetVariables(); ">{{requestIdCardContent.CancelButton || "cancel"}}</button>
                <button class="btn btn-primary" (click)="onSubmit()">{{requestIdCardContent.SubmitButton || "submit"}}</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
</div>
  