<p-confirmDialog #cd  [baseZIndex]="10002" [closable]="false">
    <p-footer>
        <button class="btn btn-primary" (click)="cd.reject()">{{sitecoreTemplate?.No || 'no'}}</button>
        <button class="btn btn-primary" (click)="cd.accept()">{{sitecoreTemplate?.Yes || 'yes'}}</button>
    </p-footer>
</p-confirmDialog>

<form (ngSubmit)="onSubmit(f);" #f="ngForm" novalidate>
    
    <div class="centered-content container">
        <div class="row">
            <div class="col-md-12">
 
                <div class="h1" id="interpretiveServiceTitle">
                    {{sitecoreTemplate?.InterpretiveServiceTitle||'interpretive Services'}}
                </div>
                <div *ngIf="successMessage" class="alert alert-success">
                    <i class="fa-duotone fa-check fa-lg" aria-hidden="true"></i> &nbsp;
                    {{successMessage}}
                </div>
                <div *ngIf="errorMessage" class="alert alert-error">
                    <i class="fa-duotone fa-ban fa-lg" aria-hidden="true"></i> &nbsp;
                      {{errorMessage}}
                </div>
                <div *ngIf="isValidating && f.invalid" class="alert alert-error">
                    <i class="fa-duotone fa-ban fa-lg" aria-hidden="true"></i> &nbsp;
                      {{sitecoreTemplate?.ValidationErrorMessage ||
                    'please enter all required fields.'}}
                </div>

                <p>{{sitecoreTemplate?.InstructionsLabel1 || 
                'we believe in providing services that meet the needs of our members. You can get interpreter services 24 hours a day, 7 days a week for:'}}
                </p>
                <p><i class="fa-duotone fa-check-circle fa-lg font-icon" aria-hidden="true"></i> &nbsp;
                    {{sitecoreTemplate?.InstructionsLabel2 || 
                    'medical Services such as doctor visits, after-hours services, urgent care services, Pharmacy services
                and health education classes.'}}</p>
                <p><i class="fa-duotone fa-check-circle fa-lg font-icon" aria-hidden="true"></i> &nbsp;
                    {{sitecoreTemplate?.InstructionsLabel3 || 
                    'non-medical services such as customer services, member complaints and member orientation meetings.'}}
                </p>
                <p>{{sitecoreTemplate?.InstructionsLabel4 || 
                'all you need to do is call your health network or submit the form via the Get Started button below. For
                scheduled appointments, make sure to ask for an interpreter at least 5 working days before your
                appointment.'}}</p>

                <error-summary></error-summary>
                <div class="alert alert-info">
                    <i class="fa-duotone fa-info-circle fa-lg" aria-hidden="true"></i> &nbsp;
                    <span  [innerHTML] = "sitecoreTemplate?.UpdateMemberProfileLink || 
                    'if you want to make a permanent change to your phone number, click here to update your member profile.'"> </span>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">

                <div class="form-group">
                    <label for="contactPhoneNumber" class="col-form-label">
                      {{sitecoreTemplate?.MemberContactPhoneLabel || "contact number"}}*
                    </label>
                    <a class="float-right pt-2" href="/#/updatememberprofile">{{sitecoreTemplate?.UpdateMemberProfileText || "update Personal Information"}}</a>
                   

                    <input 
                      id="contactPhoneNumber" 
                      name="contactPhoneNumber" 
                      placeholder="___ - ___ - ____" 
                      [(ngModel)]="contactPhoneNumber"
                      class="form-control" 
                      maxlength="12" 
                      #contactPhone="ngModel"
                      pattern="^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$" 
                      [ngClass]="{'is-invalid': contactPhone.invalid && (contactPhone.touched || isValidating)}"
                      phone 
                      required/>                                      

                      <label *ngIf="contactPhone.hasError('required') && (contactPhone.touched || isValidating)"
                             class="invalid-feedback">
                        {{sitecoreTemplate?.MobilePhoneRequiredMessage || "phone number is required."}}
                      </label>
                      <label *ngIf="contactPhone.hasError('pattern') && (contactPhone.touched || isValidating)"
                             class="invalid-feedback">
                        {{sitecoreTemplate?.MobilePhoneInvalidInputMessage || 'phone number is invalid.'}}
                      </label>

                </div>



                <div class="form-group">
                    <p class="font-weight-bold col-form-label">{{sitecoreTemplate?.LTCorHospitalLabel || 'is the member in a LTC or hospital?'}}</p>

                    <div class="float-left mr-5">
                        <label class="radio-container">
                            <input type="radio" [(ngModel)]="in_LTC_Hospital" name="in_LTC_Hospital" [value]="true" />
                            <span class="checkmark"></span>
                            <span>{{sitecoreTemplate?.Yes || 'yes'}}</span>

                        </label>
                    </div>
                    <div class="float-left mr-5">
                        <label class="radio-container">
                            <input type="radio" [(ngModel)]="in_LTC_Hospital" name="in_LTC_Hospital" [value]="false" />
                            <span>{{sitecoreTemplate?.No || 'no'}}</span>
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>

            </div>

            <div class="col-md-6">
                <div class="form-group">
                    <label class="col-form-label">{{sitecoreTemplate?.InterpreterLanguageLabel|| 
                'interpreter needed for this language'}}*</label>
                    <select [compareWith]="compareLanguage" [(ngModel)]="preferredSpokenLanguage"
                        class="form-select" id="preferredSpokenLanguageDrp" name="preferredSpokenLanguageDrp" #preferredSpokenLanguageDrp
                        [ngClass]="{'is-invalid': (preferredSpokenLanguageDrp.touched || isValidating) && preferredSpokenLanguageDrp.invalid}" required>
                        <option *ngFor="let language of memberLanguagesList" [ngValue]="language">
                            {{language.Description}}
                        </option>
                    </select>
                    <label class="invalid-feedback"
                    *ngIf="(preferredSpokenLanguageDrp.touched || isValidating) && preferredSpokenLanguageDrp.invalid">
                    {{sitecoreTemplate?.InterpreterLanguageRequiredError || "language is required."}}
                    </label>  
                </div>

                <div class="form-group">
                    <label class="col-form-label">{{sitecoreTemplate?.GenderLabel || 'was a specific gender requested?'}}</label>
                    <select [(ngModel)]="genderRequested" class="form-select" id="genderRequested" name="genderRequested">
                        <option value="No Preference">{{sitecoreTemplate?.NoPreference || 'no Preference'}}</option>
                        <option value="Female">{{sitecoreTemplate?.Female || 'female'}}</option>
                        <option value="Male">{{sitecoreTemplate?.Male || 'male'}}</option>
                    </select>
                </div>
            </div>
        </div>

        <!-- APPOINTMENT START -->

        <div *ngFor="let apt of appointments; let i = index">
            <div class="d-flex">
                <div>
                    <h4>{{(sitecoreTemplate?.AppointmentLabel || "appointment") + " #" + (i + 1) }}</h4>
                </div>

                <div class="ml-auto" *ngIf="appointments.length > 1">
                    <button class="btn btn-outline-primary" type="button" (click)="confirmAppointmentCancel(i)">
                        {{sitecoreTemplate?.CancelAppointmentLabel || "remove"}}</button>
                </div>
            </div>
            <hr />

            <div class="row">

                <!-- Type -->
                <div class="col-md-6">
                    <div class="form-group">
                        
                        <label class="col-form-label">{{sitecoreTemplate?.AppointmentTypeLabel|| 
                            'type'}}*</label>
                        <div class="d-flex flex-wrap">
                            <select [(ngModel)]="appointments[i].Type" class="form-select" name="appointmentsType{{appointments[i].Id}}" id="appointmentsType{{appointments[i].Id}}"
                            #appointmentsType="ngModel" [ngClass]="{'is-invalid': (appointmentsType.touched || isValidating)  && appointmentsType.invalid}" required>
                                <option value="">{{sitecoreTemplate?.Select || 'select'}}</option>
                                <option value="Non-Medical Visit">{{sitecoreTemplate?.NonMedicalVisit || 'non-Medical Visit'}}</option>
                                <option value="Doctor's Appointment">{{sitecoreTemplate?.DoctorAppointment || "doctor's Appointment"}}</option>
                                <option value="Pharmacy Visit">{{sitecoreTemplate?.PharmacyVisit || 'pharmacy Visit'}}</option>
                                <option value="Other">{{sitecoreTemplate?.Other || 'other'}}</option>
                            </select>
                            <label class="invalid-feedback"
                            *ngIf="(appointmentsType.touched || isValidating) && appointmentsType.invalid">
                            {{sitecoreTemplate?.AppointmentTypeRequiredError || "type is required."}}
                            </label>
                        </div>
                        
                    </div>


                    <div class="form-group">
                        <label class="col-form-label">
                            {{sitecoreTemplate?.ProviderNameLabel || "provider Name"}}*
                        </label>
                        <div class="d-flex flex-wrap">
                            <input type="text" class="form-control" name="providerName{{appointments[i].Id}}" id="providerName{{appointments[i].Id}}"
                                [(ngModel)]="appointments[i].ProviderName" 
                                #appointmentsProviderName="ngModel" [ngClass]="{'is-invalid': (appointmentsProviderName.touched || isValidating)  && appointmentsProviderName.invalid}" required>
                            <label class="invalid-feedback"
                                *ngIf="(appointmentsProviderName.touched || isValidating) && appointmentsProviderName.invalid">
                                {{sitecoreTemplate?.AppointmentProviderNameRequiredError || "provider name is required."}}
                            </label>
                         </div>
                    </div>




                    <div class="form-group">
                        <label class="col-form-label">
                            {{sitecoreTemplate?.EventAddressLabel || "address of event"}}*
                        </label>
                        <div class="d-flex flex-wrap">
                            <input type="text" class="form-control" name="EventAddress{{appointments[i].Id}}" id="EventAddress{{appointments[i].Id}}"
                                [(ngModel)]="appointments[i].EventAddress" 
                                #appointmentsEventAddress="ngModel" [ngClass]="{'is-invalid': (appointmentsEventAddress.touched || isValidating)  && appointmentsEventAddress.invalid}" required>
                            <label class="invalid-feedback"
                                *ngIf="(appointmentsEventAddress.touched || isValidating) && appointmentsEventAddress.invalid">
                                {{sitecoreTemplate?.EventAddressRequiredError || "address of event is required."}}
                            </label>
                            
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="col-form-label">
                            {{sitecoreTemplate?.EventCityLabel || "event City, State"}}*
                        </label>
                        <div class="d-flex">  
                            <div class="w-100">
                                <input type="text" class="form-control" name="EventCity{{appointments[i].Id}}" id="EventCity{{appointments[i].Id}}"
                                    [(ngModel)]="appointments[i].EventCity" 
                                    #appointmentsEventCity="ngModel" [ngClass]="{'is-invalid': (appointmentsEventCity.touched || isValidating)  && appointmentsEventCity.invalid}" required>
                                    <label class="invalid-feedback"
                                    *ngIf="(appointmentsEventCity.touched || isValidating) && appointmentsEventCity.invalid">
                                    {{sitecoreTemplate?.EventCityRequiredError || "event city is required."}}
                                    </label>
                                </div> 

                                <div class="bottom-align">,</div>
                                <div class="date-part">
                                    <input type="text" class="form-control" value="CA" disabled />
                                </div>
                        </div>
                        
                    </div>

                    <!-- Zipcode -->
                    <div class="form-group">
                        <label class="col-form-label">
                            {{sitecoreTemplate?.EventZipcodeLabel || "event Zip Code"}}*
                        </label>
                        <div class="d-flex flex-wrap">
                            <input 
                                type="text" 
                                name="EventZipCode{{appointments[i].Id}}" id="EventZipCode{{appointments[i].Id}}"
                                [(ngModel)]="appointments[i].EventZipCode" 
                                #appointmentsZipCode="ngModel" 
                                class="form-control" 
                                required 
                                pattern="^[0-9]{5}(?:-[0-9]{4})?$" 
                                placeholder="_____ -____ " 
                                maxlength="10"
                                [ngClass]="{'is-invalid': (appointmentsZipCode.touched || isValidating)  && appointmentsZipCode.invalid}"
                                zipcode
                                 />
                                 <label *ngIf="appointmentsZipCode.hasError('required') && (appointmentsZipCode.touched || isValidating)" 
                                    class="invalid-feedback" >
                                    {{sitecoreTemplate?.EventZipcodeRequiredError||'zipcode is required.'}}
                                </label>
                                <label *ngIf="appointmentsZipCode.hasError('pattern') && (appointmentsZipCode.touched || isValidating)" 
                                    class="invalid-feedback">
                                    {{sitecoreTemplate?.EventZipcodeError||'zipcode is invalid.'}}
                                </label>
                        </div>
                    </div>
                    <!-- End Zipcode -->

                </div>
                

                <div class="col-md-6">
                  <!-- Date -->
                  <app-date-picker name="scheduledDate{{appointments[i].Id}}" id="scheduledDate{{appointments[i].Id}}"
                                   [datePickerProperties]="datePickerProperties"
                                   [(ngModel)]="appointments[i].ScheduledDate">

                  </app-date-picker>
                  <!-- Date -->
                 
                  <!-- Time -->

                  <div class="form-group">
                    <label class="col-form-label">
                      {{sitecoreTemplate?.AppointmentTimeLabel||
                        'time'
                      }}
                    </label>
                    <div class="d-flex">

                      <!-- Hour -->
                      <div class="date-part mr-4">
                        <select [(ngModel)]="appointments[i].Hour" class="form-select" name="Hour{{appointments[i].Id}}" id="Hour{{appointments[i].Id}}">
                          <option [value]="hh" *ngFor="let hh of hours">
                            {{hh}}
                          </option>
                        </select>
                      </div>

                      <!-- Minute -->
                      <div class="date-part mr-4">
                        <select [(ngModel)]="appointments[i].Minute" class="form-select" name="Minute{{appointments[i].Id}}" id="Minute{{appointments[i].Id}}">
                          <option value="00">00</option>
                          <option value="15">15</option>
                          <option value="30">30</option>
                          <option value="45">45</option>
                        </select>
                      </div>

                      <!-- AM/PM -->
                      <div class="date-part">
                        <select [(ngModel)]="appointments[i].AMPM" class="form-select" name="AMPM{{appointments[i].Id}}" id="AMPM{{appointments[i].Id}}">
                          <option value="PM">PM</option>
                          <option value="AM">AM</option>
                        </select>
                      </div>

                    </div>
                  </div>

                  <div class="form-group">
                    <label class="col-form-label">
                      {{sitecoreTemplate?.AppointmentDurationLabel||
                        'duration (hh:mm)'
                      }}
                    </label>
                    <div class="d-flex">

                      <!-- Hour -->
                      <div class="date-part mr-4">
                        <select [(ngModel)]="appointments[i].Duration_hh" class="form-select" name="Duration_hh{{appointments[i].Id}}" id="Duration_hh{{appointments[i].Id}}">
                          <option value="01">01</option>
                          <option value="02">02</option>
                          <option value="03">03</option>
                          <option value="04">04</option>
                        </select>
                      </div>

                      <!-- Minute -->
                      <div class="date-part mr-4">
                        <select [(ngModel)]="appointments[i].Duration_mm" class="form-select" name="Duration_mm{{appointments[i].Id}}" id="Duration_mm{{appointments[i].Id}}">
                          <option value="00">00</option>
                          <option value="15">15</option>
                          <option value="30">30</option>
                          <option value="45">45</option>
                        </select>
                      </div>

                    </div>
                  </div>


                  <div class="form-group">
                    <label for="phone" class="col-form-label">
                      {{sitecoreTemplate?.EventPhoneLabel || "office or event phone number"}}*
                    </label>

                    <div class="d-flex flex-wrap">
                      <input id="appointmentsPhone{{appointments[i].Id}}"
                             name="appointmentsPhone{{appointments[i].Id}}"
                             placeholder="___ - ___ - ____"
                             [(ngModel)]="appointments[i].EventPhoneNumber"
                             required
                             class="form-control"
                             maxlength="12"
                             #appointmentsPhone="ngModel"
                             pattern="^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$"
                             [ngClass]="{'is-invalid': appointmentsPhone.invalid && (appointmentsPhone.touched || isValidating)}"
                             phone />
                      <label *ngIf="appointmentsPhone.hasError('required') && (appointmentsPhone.touched || isValidating)"
                             class="invalid-feedback">
                        {{sitecoreTemplate?.MobilePhoneRequiredMessage || "phone number is required."}}
                      </label>
                      <label *ngIf="appointmentsPhone.hasError('pattern') && (appointmentsPhone.touched || isValidating)"
                             class="invalid-feedback">
                        {{sitecoreTemplate?.MobilePhoneInvalidInputMessage || 'phone number is invalid.'}}
                      </label>
                    </div>
                  </div>

                  <div class="form-group">
                    <label class="col-form-label" for="contactPerson">
                      {{sitecoreTemplate?.ContactPersonLabel || "contact person at office or event"}}*
                    </label>
                    <div class="d-flex flex-wrap">
                      <input type="text" class="form-control" name="ContactPerson{{appointments[i].Id}}" id="ContactPerson{{appointments[i].Id}}"
                             [ngClass]="{'is-invalid': appointmentsContactPerson.invalid && (appointmentsContactPerson.touched || isValidating)}"
                             [(ngModel)]="appointments[i].ContactPerson" #appointmentsContactPerson="ngModel" required />
                      <label *ngIf="appointmentsContactPerson.invalid && (appointmentsContactPerson.touched || isValidating)"
                             class="invalid-feedback">
                        {{sitecoreTemplate?.ContactPersonRequiredError || 'contact person is required.'}}
                      </label>
                    </div>
                  </div>

                </div>

            </div>
        </div>
         <!-- APPOINTMENT END -->
        <div *ngIf="appointments.length <= 1" class="alert alert-info">
            <i class="fa-duotone fa-info-circle fa-lg" aria-hidden="true"></i> &nbsp;
            {{sitecoreTemplate?.AdditionalAppointmentMessage || 
            'is interpreter needed for additional appointments?  You can schedule up to 5 appointments.'}}
        </div>

        <!-- submit -->
            <div class="row no-glutters justify-content-end mt-3 mb-3">
                <button class="btn btn-outline-primary col-md-3 m-2" type="button" id="btnCancel"
                    (click)="confirmFormReset(f)">
                    {{sitecoreTemplate?.CancelLabel || "cancel"}}</button>
                <button *ngIf="appointments.length < 5" class="btn btn-success col-md-3 m-2" type="button"
                    id="btnAddAppointment" (click)="btnAddAppointment_Click(f)">
                    {{sitecoreTemplate?.AddAppointmentLabel || "add Appointment"}}</button>
                <button class="btn btn-primary col-md-3 m-2 " type="submit"
                    id="btnFinish">{{sitecoreTemplate?.FinishLabel || "finish"}}</button>
            </div>

    </div>
</form>
